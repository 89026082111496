import React from "react";
import Space from "../workspace/Space";
// import MobileSpace from "../mobile/space/MobileSpace";
// import useIsMobile from "../../hooks/isMobile";
import { observer } from "mobx-react";
import { useStore } from "../../hooks/use-store";

const SpaceContainer = () => {
  const {
    // dataStore: { appStore },
    uiStore: { authStore },
  } = useStore();

  const spaceId = authStore.currentUser?.spaceId;
  // const isMobile = useIsMobile();

  // React.useEffect(() => {
  //   console.log("isMobile: ", isMobile);
  // }, [isMobile]);

  return <Space spaceId={spaceId!} />;

  // if (isMobile) {
  //   return <MobileSpace />;
  // } else {
  //   return <Space />;
  // }
};

export default observer(SpaceContainer);
