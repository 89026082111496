import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Participant } from "livekit-client";
import { RoomState } from "@livekit/react-components";
import HuddleParticipant from "./huddleParticipant/HuddleParticipant";
import StageControls from "../stageControls/StageControls";
import styles from "./livekitRoom.module.scss";
import MeetingParticipant from "./meetingParticipant/MeetingParticipant";
import { useStore } from "../../../hooks/use-store";
import ReactDOM from "react-dom";
import { Layout } from "antd";
import { ROLE } from "../../../types";
import InviteHuddleParticipant from "./huddleParticipant/InviteHuddleParticipant";
import InviteMeetingParticipant from "./meetingParticipant/InviteMeetingParticipant";
const { Sider } = Layout;

const StageRenderer = (props: { roomState: RoomState }) => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  console.log("rerender");

  const slidesMode =
    spaceStore.currentRoom?.focusModeEnabled && !spaceStore.currentRoom?.plotId;

  const { room, participants, isConnecting, error } = props.roomState;

  useEffect(() => {
    if (room) {
      spaceStore.setLiveKitRoom(room);
    }
  }, [room, spaceStore]);

  const isCollaborator =
    spaceStore.role === ROLE.ADMIN || spaceStore.role === ROLE.COLLABORATOR;

  const screenshareEnabled = !!participants.find(
    (itm) => itm.isScreenShareEnabled
  );
  const showInviteParticipant =
    participants.length < 2 && isCollaborator && !screenshareEnabled;
  const participantsCount =
    participants.length + (showInviteParticipant ? 1 : 0);

  const isMeetingMode =
    spaceStore.currentRoom?.focusModeEnabled && !spaceStore.currentRoom?.plotId;

  const getMeetingClassName = () => {
    let className = styles.big2;
    if (participantsCount >= 4) {
      className = styles.big4;
    } else if (participantsCount > 2) {
      className = styles.big3;
    }
    if (screenshareEnabled) {
      className = styles.small;
    }
    return className;
  };

  const renderRoom = () => {
    if (isConnecting) {
      return <div className={styles.status}>Connecting...</div>;
    }
    if (error) {
      return <div className={styles.status}>Error: {error.message}</div>;
    }
    if (!room) {
      return (
        <div className={styles.status}>
          Unable to connect, please reload cake
        </div>
      );
    }

    return (
      <>
        <div
          className={styles.fullscreenShareContainer}
          id={"screenshare-fullscreen-container"}
        />
        {participants.map((participant: Participant) => {
          if (isMeetingMode) {
            return (
              <div
                className={`${
                  styles.largeParticipant
                } ${getMeetingClassName()}`}
                key={participant.identity}
              >
                <MeetingParticipant
                  identity={participant.identity}
                  participant={participant}
                />
              </div>
            );
          } else {
            return (
              <div className={styles.participant} key={participant.identity}>
                <HuddleParticipant
                  identity={participant.identity}
                  participant={participant}
                />
              </div>
            );
          }
        })}
        {showInviteParticipant &&
          (isMeetingMode ? (
            <div
              className={`${styles.largeParticipant} ${getMeetingClassName()}`}
              key={"inviteParticipant"}
            >
              <InviteMeetingParticipant
                link={`https://app.cake.work/meeting/${spaceStore.currentRoom?.roomId}`}
                password={spaceStore.currentRoom?.password}
              />
            </div>
          ) : (
            <div className={styles.participant} key={"inviteParticipant"}>
              <InviteHuddleParticipant
                link={`https://app.cake.work/meeting/${spaceStore.currentRoom?.roomId}`}
                password={spaceStore.currentRoom?.password}
              />
            </div>
          ))}
        <StageControls room={room} />
      </>
    );
  };
  if (slidesMode) {
    return (
      <div
        className={styles.fullscreenContainer}
        style={{
          backgroundImage: `url(${spaceStore.currentRoom?.background})`,
        }}
      >
        {renderRoom()}
      </div>
    );
  } else {
    return ReactDOM.createPortal(
      <Sider collapsedWidth={0} width={198}>
        <div className={styles.defaultContainer} style={{}}>
          {renderRoom()}
        </div>
      </Sider>,

      // @ts-ignore
      document.getElementById("slide-video-room")
    );
  }
};

export default observer(StageRenderer);
