export const themeColors = {
  BLACK: {
    "--slides-background": "#000000",
    "--slides-elements-border": "#111111",
    "--slides-elements-hover": "#0a0a0a",
    "--slides-elements-background": "#0d0d0d",
    "--slides-text-color-primary": "white",
    "--slides-text-color-secondary": "#959595",
    "--slides-preview-background": "#0d0d0d",
  },
  BLUE: {
    "--slides-background": "#4543e7",
    "--slides-elements-border": "#5452e9",
    "--slides-elements-hover": "#4e48ee",
    "--slides-elements-background": "#584ef4",
    "--slides-text-color-primary": "#ffd69f",
    "--slides-text-color-secondary": "#bcbbe1",
    "--slides-preview-background": "#584ef4",
  },
  LIGHT: {
    "--slides-background": "#ffffff",
    "--slides-elements-border": "#ebebeb",
    "--slides-elements-hover": "#fafafa",
    "--slides-elements-background": "#f5f5f5",
    "--slides-text-color-primary": "#000000",
    "--slides-text-color-secondary": "#6e6f6f",
    "--slides-preview-background": "#f5f5f5",
  },
  CREMA: {
    "--slides-background": "#efebe5",
    "--slides-elements-border": "#dcd8d5",
    "--slides-elements-hover": "#e8e6de",
    "--slides-elements-background": "#e6e2dc",
    "--slides-text-color-primary": "#484240",
    "--slides-text-color-secondary": "#8f8d8a",
    "--slides-preview-background": "#e6e2dc",
  },
  MARINE: {
    "--slides-background": "#c8d6e7",
    "--slides-elements-border": "#b3c3d6",
    "--slides-elements-hover": "#cfdaea",
    "--slides-elements-background": "#bbcce2",
    "--slides-text-color-primary": "#222021",
    "--slides-text-color-secondary": "#787476",
    "--slides-preview-background": "#bbcce2",
  },
  NIGHT: {
    "--slides-background": "#151515",
    "--slides-elements-border": "#272828",
    "--slides-elements-hover": "#1a1a1a",
    "--slides-elements-background": "#1e1e1e",
    "--slides-text-color-primary": "#ffffff",
    "--slides-text-color-secondary": "#dcadff",
    "--slides-preview-background": "#1e1e1e",
  },
  PAPER: {
    "--slides-background": "#fffefc",
    "--slides-elements-border": "#efece5",
    "--slides-elements-hover": "#f7f6f3",
    "--slides-elements-background": "#f7f6f3",
    "--slides-text-color-primary": "#010000",
    "--slides-text-color-secondary": "#666665",
    "--slides-preview-background": "#f7f6f3",
  },
  YELLOW: {
    "--slides-background": "#fffb81",
    "--slides-elements-border": "#ebe778",
    "--slides-elements-hover": "#f4f07a",
    "--slides-elements-background": "#f5f17c",
    "--slides-text-color-primary": "#35352f",
    "--slides-text-color-secondary": "#858661",
    "--slides-preview-background": "#f5f17c",
  },
  GREEN: {
    "--slides-background": "#06311d",
    "--slides-elements-border": "#1d4130",
    "--slides-elements-hover": "#0d3621",
    "--slides-elements-background": "#123927",
    "--slides-text-color-primary": "#d9e8b7",
    "--slides-text-color-secondary": "#8b9983",
    "--slides-preview-background": "#123927",
  },
};
