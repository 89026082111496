import React from "react";
import styles from "./forbiddenPage.module.scss";
import Logo from "../../../components/Logo";

const ForbiddenPage = () => {
  return (
    <div className={styles.visible}>
      <div className={styles.block}>
        <span>Access Forbidden</span>
        <br />
        <Logo />
      </div>
    </div>
  );
};

export default ForbiddenPage;
