import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Drawer, Form, Col, Row, Space, Popconfirm, Image, Avatar } from "antd";
import { useStore } from "../../../hooks/use-store";
import styles from "./editRoom.module.scss";
import Button from "../../button/Button";
import FormInput from "../../form/input";
import FormButton from "../../form/button";
import { Room } from "../../../stores/data/space-store/space-store";
import FormSwitch from "../../form/switch";
import { Widget } from "@uploadcare/react-widget";
import { useTranslation } from "react-i18next";
import DatePicker from "../../form/datepicker";
import FormTextarea from "../../form/textarea";

import dayjs from "dayjs";
import PexelsImageModal from "../../../features/pexels/PexelsImageModal";

interface Props {
  onClose: () => void;
  room: Room | null;
  visible: boolean;
}

interface FormProps {
  name: string;
  isCustomBackground: boolean;

  password?: string;
  welcomePageEnabled?: boolean;
  welcomePageName?: string;
  welcomePageDescription?: string;
  welcomePageDate?: string;
  privat: boolean;
}

const EditRoom = (props: Props) => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const { t } = useTranslation();
  const [background, setBackground] = useState<string | null>(null);
  const [pexelsModalOpen, setPexelsModalOpen] = useState(false);

  const [welcomePageBackground, setWelcomePageBackground] = useState<
    string | null
  >(null);
  const {
    control,
    getValues,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      name: "",
      isCustomBackground: false,
      welcomePageEnabled: false,
      privat: false,
    });
    setBackground(null);
  }, [reset]);

  useEffect(() => {
    setValue("name", props.room?.name!);
    setValue("isCustomBackground", props.room?.isCustomBackground!);
    if (props.room?.isCustomBackground) {
      setBackground(props.room?.background);
    }

    if (props.room?.welcomePageBackground) {
      setWelcomePageBackground(props.room?.welcomePageBackground);
    } else {
      setWelcomePageBackground("");
    }
    setValue("password", props.room?.password || "");
    setValue("welcomePageName", props.room?.welcomePageName || "");

    if (props.room?.password) {
      setValue("privat", true);
    }
    setValue(
      "welcomePageDescription",
      props.room?.welcomePageDescription || ""
    );
    setValue("name", props.room?.name || "");
    if (props.room?.welcomePageDate) {
      // @ts-ignore
      setValue("welcomePageDate", dayjs(props.room?.welcomePageDate));
    } else {
      setValue("welcomePageDate", undefined);
    }
  }, [
    props.room?.name,
    props.room?.isCustomBackground,
    props.room?.background,
    setValue,
    props.room?.password,
    props.room?.welcomePageName,
    props.room?.welcomePageDescription,
    props.room?.welcomePageDate,
    props.room?.welcomePageBackground,
  ]);

  const updateBackground = async (bg: string) => {
    updateRoom({
      name: getValues("name"),
      background: bg,
      isCustomBackground: getValues("isCustomBackground"),
      password: getValues("password") || "",
      welcomePageEnabled: getValues("welcomePageEnabled") || false,
      welcomePageName: getValues("welcomePageName") || "",
      welcomePageDescription: getValues("welcomePageDescription") || "",
      welcomePageDate: getValues("welcomePageEnabled")
        ? getValues("welcomePageDate")!
        : "",
      welcomePageBackground: welcomePageBackground,
    });
  };
  const updateRoom = async (model: {
    name: string;
    background: string;
    isCustomBackground: boolean;
    password: string | null;
    welcomePageEnabled: boolean;
    welcomePageName: string;
    welcomePageDescription: string;
    welcomePageDate: string;
    welcomePageBackground: string | null;
  }) => {
    await spaceStore.updateRoom({
      roomId: props.room!.roomId,
      name: model.name,
      background: model.background,
      isCustomBackground: model.isCustomBackground,
      focusModeEnabled: props.room!.focusModeEnabled,
      plotId: props.room!.plotId,

      password: model.password,
      welcomePageEnabled: model.welcomePageEnabled,
      welcomePageName: model.welcomePageName,
      welcomePageDescription: model.welcomePageDescription,
      welcomePageDate: model.welcomePageDate,
      welcomePageBackground: model.welcomePageBackground,
    });
    props.onClose();
  };

  const submitFn = async (data: FormProps) => {
    const welcomePage = {
      password: data.privat ? data.password || "" : null,
      welcomePageEnabled: data.welcomePageEnabled || false,
      welcomePageName: data.welcomePageName || "",
      welcomePageDescription: data.welcomePageDescription || "",
      welcomePageDate: data.welcomePageDate || "",
      welcomePageBackground: welcomePageBackground || null,
    };
    if (data.isCustomBackground && background) {
      await updateRoom({
        name: data.name,
        background,
        isCustomBackground: data.isCustomBackground,
        ...welcomePage,
      });
    } else {
      await updateRoom({
        name: data.name,
        background: props.room?.background!,
        isCustomBackground: data.isCustomBackground,
        ...welcomePage,
      });
    }
  };

  const deleteFn = async () => {
    await spaceStore.deleteRoom({
      roomId: props.room!.roomId,
    });
    props.onClose();
  };

  const isCustomBackground = useWatch({
    control,
    name: "isCustomBackground",
  });

  const privat = useWatch({
    control,
    name: "privat",
  });

  const welcomePageEnabled = useWatch({
    control,
    name: "welcomePageEnabled",
  });

  return (
    <Drawer
      title="Settings"
      width={620}
      onClose={props.onClose}
      open={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        props.room?.receptionRoom ? null : (
          <Space>
            <Popconfirm
              title={"This action cannot be undone"}
              onConfirm={deleteFn}
              okText="Yes"
              cancelText="No"
            >
              <Button>Delete Room</Button>
            </Popconfirm>
          </Space>
        )
      }
    >
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <FormInput
              type="text"
              rules={{
                required: true,
                minLength: 1,
              }}
              error={errors.name}
              control={control}
              label={"Room name"}
              name={"name"}
            />

            <div className={styles.switch}>
              <span>
                {t("", {
                  defaultValue: "Make your room public, or private",
                })}
              </span>
              <FormSwitch
                rules={{
                  required: false,
                }}
                control={control}
                name={"privat"}
                error={errors.privat}
                checkedChildren={t("", {
                  defaultValue: "Private room",
                })}
                unCheckedChildren={t("", {
                  defaultValue: "Public room",
                })}
              />
            </div>
            {privat ? (
              <>
                <FormInput
                  type="password"
                  rules={{
                    required: true,
                    minLength: 1,
                  }}
                  error={errors.password}
                  control={control}
                  label={"Password"}
                  name={"password"}
                />
                <div>
                  <span>
                    {t("", {
                      defaultValue: "Protect your room by setting up password.",
                    })}
                  </span>
                </div>
              </>
            ) : null}

            {/* <div className={styles.note}>
              <span>
                {t("", {
                  defaultValue:
                    "Create and customize cake-hosted welcome page. Set start and end time and define description of your room",
                })}
              </span>
            </div> */}

            <div className={styles.switch}>
              <span>
                {t("", {
                  defaultValue:
                    "Create and customize cake-hosted welcome page. Set start and end time and define description of your room",
                })}
              </span>
              <FormSwitch
                rules={{
                  required: false,
                }}
                control={control}
                name={"welcomePageEnabled"}
                error={errors.welcomePageEnabled}
                checkedChildren={t("", {
                  defaultValue: "Enabled",
                })}
                unCheckedChildren={t("", {
                  defaultValue: "Disabled",
                })}
              />
            </div>
            {welcomePageEnabled ? (
              <div>
                <FormInput
                  type="text"
                  rules={{
                    required: true,
                    minLength: 1,
                  }}
                  error={errors.welcomePageName}
                  control={control}
                  placeholder={t("edit.event.onboarding", {
                    defaultValue: "Onboarding session",
                  })}
                  label={t("", {
                    defaultValue: "Room landing page title",
                  })}
                  name={"welcomePageName"}
                />

                <FormTextarea
                  type="text"
                  rules={{
                    required: true,
                    minLength: 1,
                  }}
                  error={errors.welcomePageDescription}
                  placeholder={t("edit.event.agenda", {
                    defaultValue: "Agenda: ...",
                  })}
                  control={control}
                  label={t("", {
                    defaultValue: "Room landing page agenda",
                  })}
                  name={"welcomePageDescription"}
                />

                <div className={styles.background}>
                  <Avatar
                    style={{
                      backgroundColor: "#a8a8a8",
                      borderRadius: "6px",
                      width: "300px",
                    }}
                    size={120}
                    src={welcomePageBackground}
                  />
                  <div>
                    <Widget
                      localeTranslations={{
                        buttons: {
                          choose: {
                            files: {
                              one: t("", {
                                defaultValue: "Upload landing page background",
                              }),
                            },
                          },
                        },
                      }}
                      clearable={true}
                      // @ts-ignore
                      onChange={(info: { cdnUrl: string }) => {
                        setWelcomePageBackground(info.cdnUrl);
                      }}
                      tabs={"file camera url"}
                      publicKey="f4d9f840695d97e9cbb7"
                    />
                    {welcomePageBackground ? (
                      <>
                        &nbsp;
                        <Button
                          type="primary"
                          onClick={() => {
                            setWelcomePageBackground("");
                          }}
                        >
                          {t("edit.event.clear.background", {
                            defaultValue: "Clear background",
                          })}
                        </Button>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className={styles.date}>
                  <DatePicker
                    showTime={true}
                    picker={"date"}
                    type="text"
                    rules={{
                      required: true,
                    }}
                    error={errors.welcomePageDate}
                    control={control}
                    label={t("edit.event.open.time", {
                      defaultValue: "Room open time",
                    })}
                    placeholder={"2022-07-18"}
                    name={"welcomePageDate"}
                  />
                </div>
              </div>
            ) : null}

            <FormButton loading={isSubmitting} onClick={handleSubmit(submitFn)}>
              Save
            </FormButton>
          </Col>
        </Row>
        <div className={styles.switch}>
          <span>Customize your room by uploading personal background</span>
          <FormSwitch
            rules={{
              default: true,
              required: false,
            }}
            control={control}
            name={"isCustomBackground"}
            error={errors.isCustomBackground}
            checkedChildren="Custom background"
            unCheckedChildren="Default background"
          />
        </div>
        {isCustomBackground ? (
          <div className={styles.background}>
            <Avatar
              style={{
                backgroundColor: "#a8a8a8",
                borderRadius: "6px",
                width: "380px",
                height: "200px",
              }}
              size={120}
              src={background}
            />
            <div>
              <Widget
                localeTranslations={{
                  buttons: {
                    choose: {
                      files: {
                        one: "Upload room background",
                      },
                    },
                  },
                }}
                clearable={true}
                // @ts-ignore
                onChange={(info: { cdnUrl: string }) => {
                  setBackground(info.cdnUrl);
                }}
                tabs={"file camera url"}
                publicKey="f4d9f840695d97e9cbb7"
              />
              {background ? (
                <>
                  &nbsp;
                  <Button
                    type="primary"
                    onClick={() => {
                      setBackground("");
                    }}
                  >
                    Clear background
                  </Button>
                </>
              ) : null}
              <br />
              {background ? (
                <div className={styles.center}>
                  <span>Press save to apply changes</span>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <Row gutter={16}>
              <Col
                span={12}
                className={styles.gridItem}
                onClick={async () => {
                  await updateBackground("/backgrounds/default/bg4.jpg");
                }}
              >
                <Image
                  preview={false}
                  height={"180px"}
                  width={"100%"}
                  className={styles.previewImage}
                  src={"/backgrounds/default/bg4.jpg"}
                  alt={"a"}
                />
              </Col>
              <Col
                span={12}
                className={styles.gridItem}
                onClick={async () => {
                  await updateBackground("/backgrounds/default/bg3.jpg");
                }}
              >
                <Image
                  preview={false}
                  height={"180px"}
                  width={"100%"}
                  className={styles.previewImage}
                  src={"/backgrounds/default/bg3.jpg"}
                  alt={"a"}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                span={12}
                className={styles.gridItem}
                onClick={async () => {
                  await updateBackground("/backgrounds/default/bg2.jpg");
                }}
              >
                <Image
                  preview={false}
                  height={"180px"}
                  width={"100%"}
                  className={styles.previewImage}
                  src={"/backgrounds/default/bg2.jpg"}
                  alt={"a"}
                />
              </Col>
              <Col
                span={12}
                className={styles.gridItem}
                onClick={async () => {
                  await updateBackground("/backgrounds/default/bg1.jpg");
                }}
              >
                <Image
                  preview={false}
                  height={"180px"}
                  width={"100%"}
                  className={styles.previewImage}
                  src={"/backgrounds/default/bg1.jpg"}
                  alt={"a"}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                span={12}
                className={styles.gridItem}
                onClick={async () => {
                  await updateBackground("/backgrounds/default/bg5.svg");
                }}
              >
                <Image
                  preview={false}
                  height={"180px"}
                  width={"100%"}
                  className={styles.previewImage}
                  src={"/backgrounds/default/bg5.svg"}
                  alt={"a"}
                />
              </Col>
              <Col
                span={12}
                className={styles.gridItem}
                onClick={async () => {
                  await updateBackground("/backgrounds/default/bg6.svg");
                }}
              >
                <Image
                  preview={false}
                  height={"180px"}
                  width={"100%"}
                  className={styles.previewImage}
                  src={"/backgrounds/default/bg6.svg"}
                  alt={"a"}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                span={12}
                className={styles.gridItem}
                onClick={async () => {
                  await updateBackground("/backgrounds/default/bg7.svg");
                }}
              >
                <Image
                  preview={false}
                  height={"180px"}
                  width={"100%"}
                  className={styles.previewImage}
                  src={"/backgrounds/default/bg7.svg"}
                  alt={"a"}
                />
              </Col>
              <Col
                span={12}
                className={styles.gridItem}
                onClick={async () => {
                  await updateBackground("/backgrounds/default/bg8.svg");
                }}
              >
                <Image
                  preview={false}
                  height={"180px"}
                  width={"100%"}
                  className={styles.previewImage}
                  src={"/backgrounds/default/bg8.svg"}
                  alt={"a"}
                />
              </Col>
            </Row>
            <div className={styles.pexels}>
              <span>or choose from </span>
              &nbsp; &nbsp;
              <Button
                type="primary"
                onClick={() => {
                  setPexelsModalOpen(true);
                }}
              >
                Pexels
              </Button>
            </div>
          </div>
        )}
      </Form>
      {pexelsModalOpen ? (
        <PexelsImageModal
          visible={pexelsModalOpen}
          handleAdd={async (image: string) => {
            await updateBackground(image);
            setPexelsModalOpen(false);
          }}
          handleCancel={() => setPexelsModalOpen(false)}
        />
      ) : null}
    </Drawer>
  );
};

export default EditRoom;
