import { getPlanMaxSpaces } from "../../../helpers/helper";

export default class User {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  email: string | null;
  avatar: string | null;
  id: string;
  identityId: string;
  plan: string;
  seats: number;
  spaces: number;
  spaceId: string;

  constructor(data: any) {
    Object.assign(this, data);
    this.spaces = getPlanMaxSpaces(data.plan);
  }
}
