import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { FilesUpload, Widget } from "@uploadcare/react-widget";
import FormButton from "../../../components/form/button";
import { Image } from "../../../types";

interface Props {
  visible: boolean;
  handleAdd: (images: Image[]) => void;
  handleCancel: () => void;
}

const AddElement = ({ visible, handleAdd, handleCancel }: Props) => {
  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    setImages([]);
  }, [visible]);

  return (
    <Modal
      title="Upload image or GIF"
      open={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Widget
        localeTranslations={{
          buttons: {
            choose: {
              files: {
                one: "Upload gallery",
              },
            },
          },
        }}
        clearable={true}
        // @ts-ignore
        onFileSelect={async (res) => {
          const imgs: any[] = [];
          if (res) {
            const files = (res as FilesUpload).files();
            for (const file of files) {
              // @ts-ignore
              const img = await file.promise();
              imgs.push({
                cdnUrl: img.cdnUrl,
                height: img.originalImageInfo.height,
                width: img.originalImageInfo.width,
              });
            }
          }

          setImages((itms) => [...itms, ...imgs]);
        }}
        imagesOnly={true}
        multiple={true}
        publicKey="f4d9f840695d97e9cbb7"
      />
      <br />
      <br />
      <FormButton onClick={() => handleAdd(images)}>Add</FormButton>
    </Modal>
  );
};

export default AddElement;
