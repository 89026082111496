import React, { useEffect, useState } from "react";
import styles from "./editAccount.module.scss";
import { Drawer, Form, Col, Row, Space, Avatar } from "antd";
import Button from "../../button/Button";
import FormInput from "../../form/input";
import { useForm } from "react-hook-form";
import { Widget } from "@uploadcare/react-widget";
import { getInitials } from "../../../helpers/helper";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_SERVICE } from "../../notifications/NotificationService";
import { useStore } from "../../../hooks/use-store";

interface Props {
  onClose: () => void;
  visible: boolean;
}

interface FormProps {
  firstName: string;
  lastName: string;
  email: string;
}

const EditAccount = (props: Props) => {
  const { t } = useTranslation();
  const {
    uiStore: { authStore },
    dataStore: { spaceStore },
  } = useStore();

  const [avatar, setAvatar] = useState<string | null>(null);
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      firstName: "",
      lastName: "",
    });
  }, [reset]);

  useEffect(() => {
    if (authStore.currentUser) {
      setValue("firstName", authStore.currentUser.firstName);
      setValue("lastName", authStore.currentUser.lastName);
      setAvatar(authStore.currentUser.avatar);
    }
  }, [
    authStore.currentUser?.firstName,
    authStore.currentUser?.lastName,
    authStore.currentUser?.avatar,
    authStore.currentUser,
    setValue,
    setAvatar,
  ]);

  const submit = async (data: FormProps) => {
    await spaceStore.updateParticipant({
      identityId: spaceStore.identity,
      avatar: avatar!,
      firstName: data.firstName,
      lastName: data.lastName,
    });

    await authStore.update({
      avatar: avatar,
      firstName: data.firstName,
      lastName: data.lastName,
    });

    NOTIFICATION_SERVICE.successNotification("Account updated");

    props.onClose();
  };

  return (
    <Drawer
      title={t("profile.edit", {
        defaultValue: "Edit profile",
      })}
      width={480}
      onClose={props.onClose}
      open={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={props.onClose}>
            {t("common.cancel", {
              defaultValue: "Cancel",
            })}
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <div className={styles.avatar}>
              <Avatar
                //shape="square"
                style={{ border: "none" }}
                size={98}
                src={avatar}
              >
                {getInitials(authStore.currentUser?.firstName!)}
              </Avatar>
              <Widget
                localeTranslations={{
                  buttons: {
                    choose: {
                      files: {
                        one: "Upload your avatar",
                      },
                    },
                  },
                }}
                clearable={true}
                // @ts-ignore
                onChange={(info: { cdnUrl: string }) => {
                  setAvatar(info.cdnUrl);
                }}
                tabs={"file camera url"}
                publicKey="f4d9f840695d97e9cbb7"
              />
            </div>

            <FormInput
              type="text"
              rules={{
                required: true,
                minLength: 1,
              }}
              error={errors.firstName}
              control={control}
              label={"First name"}
              name={"firstName"}
            />
            <FormInput
              type="text"
              rules={{
                required: true,
                minLength: 1,
              }}
              error={errors.lastName}
              control={control}
              label={"Last name"}
              name={"lastName"}
            />

            <Button
              loading={isSubmitting}
              className={styles.button}
              type="primary"
              onClick={handleSubmit(submit)}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditAccount;
