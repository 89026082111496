import React, { useEffect } from "react";
import { Modal } from "antd";
import FormButton from "../../../../components/form/button";
import FormInput from "../../../../components/form/input";
import { useForm } from "react-hook-form";

interface Props {
  credits: number;
  visible: boolean;
  loading: boolean;
  handleAdd: (url: string) => void;
  handleCancel: () => void;
}

interface FormProps {
  name: string;
}

const SpaceSlidesGPTModal = ({
  visible,
  credits,
  handleAdd,
  handleCancel,
  loading,
}: Props) => {
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      name: "",
    });
  }, [reset, visible]);

  const submit = async (data: FormProps) => {
    handleAdd(data.name);
  };

  if (credits < 5) {
    return (
      <Modal
        // title={"Slide name"}
        open={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <div>Not enough credits to perform this operation.</div>
        <div>
          Please{" "}
          <a
            href="https://309sov53iwj.typeform.com/to/Ti5MBxu8"
            target={"_blank"}
            rel="noreferrer"
          >
            contact us
          </a>{" "}
          and we will provide you a personal quote
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      // title={"Slide name"}
      open={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        You spend 5 per each slides generation 🍰. Now you have {credits}{" "}
        credits
      </div>
      <br />
      <div>
        💡 To prompt Cake properly, provide a clear and specific question or
        statement that conveys the information you are seeking or the
        conversation you wish to have.
      </div>
      <br />
      <FormInput
        type="text"
        rules={{
          required: true,
        }}
        error={errors.name}
        control={control}
        placeholder={"Example: Explain quantum computing in simple terms"}
        label={""}
        name={"name"}
      />
      <br />
      <FormButton
        loading={isSubmitting || loading}
        onClick={handleSubmit(submit)}
      >
        Generate
      </FormButton>
    </Modal>
  );
};

export default SpaceSlidesGPTModal;
