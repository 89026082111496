import React, { useEffect, useState } from "react";
import { Layout } from "antd";

import Sidebar from "../../components/sidebar/sidebar";
import RoomHeader from "../../components/roomHeader/roomHeader";

import { observer } from "mobx-react";
import { useStore } from "../../hooks/use-store";
import styles from "./space.module.scss";
import Room from "./livekitRoom/LivekitRoom";
// import Workspace from "../workspace/Workspace";
// import Reception from "../workspace/reception/Reception";
import FullscreenLoader from "../space/fullscreenLoader/Loader";
import ReconnectIndicator from "../space/reconnectIndicator/ReconnectIndicator";
import { RTC_WD } from "../../helpers/enums";
import Plot from "../../pages/public/plot/Plot";
// import ExitKeynoteButton from "./spaceMenu/ExitKeynoteButton";
import Chat from "../../components/chat/Chat";
import SpaceSlides from "./spaceSlides/SpaceSlides";
import Workspace from "./Workspace";
import { Helmet } from "react-helmet";
// import DyteRoom from "../../pages/experiment/DyteRoom";

// const Room3d = React.lazy(() => import("../../components/experiments/3d"));

const { Content, Sider } = Layout;

const Space = (model: { spaceId: string }) => {
  // const { spaceId } = useParams<{ spaceId: string }>();
  const {
    uiStore: { authStore },
    dataStore: { spaceStore, elementsStore, chatStore, plotStore },
  } = useStore();

  useEffect(() => {
    plotStore.setReadonlyMode(false);
  }, [plotStore]);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    spaceStore.connect(
      authStore.currentUser!.identityId,
      model.spaceId,
      `${authStore.currentUser!.firstName} ${authStore.currentUser!.lastName}`,
      authStore.currentUser!.avatar,
      null
    );

    return () => {
      (async () => await spaceStore.disconnect())();
      (async () => await elementsStore.disconnect())();
      (async () => await chatStore.disconnect())();
    };
  }, [model.spaceId, spaceStore, authStore, elementsStore, chatStore]);

  useEffect(() => {
    if (spaceStore.identity) {
      if (spaceStore.currentRoom?.roomId) {
        if (!initialized) {
          elementsStore.connect(
            spaceStore.currentRoom.roomId,
            spaceStore.identity
          );
          chatStore.connect(
            spaceStore.currentRoom.roomId,
            authStore.currentUser!.identityId
          );
          setInitialized(true);
        } else {
          if (elementsStore.currentRoomId !== spaceStore.currentRoom.roomId) {
            elementsStore.changeRoom(spaceStore.currentRoom.roomId);
            chatStore.changeRoom(spaceStore.currentRoom.roomId);
          }
        }
      }
    }
  }, [
    elementsStore,
    chatStore,
    authStore.currentUser,
    spaceStore.currentRoom?.roomId,
    spaceStore.identity,
    initialized,
    setInitialized,
  ]);

  return (
    <Layout>
      <Helmet>
        <script type="text/javascript">
          {`
        var eyelet_wdgt = { 'id':'6343dc5b2b521fe3cffcf086'};
        (function(d) {
          var n = d.getElementsByTagName('script')[0],
          s = d.createElement('script');
          s.async = true;
          s.src = '//widgetapp.eyelet.io/code.js';
          n.parentNode.insertBefore(s, n);
        })(document);
        `}
        </script>
      </Helmet>
      <FullscreenLoader />
      <ReconnectIndicator />
      <Content>
        <Layout className="site-layout-background">
          <Sider
            collapsedWidth={22}
            collapsed={spaceStore.spaceSidebarCollapsed}
            width={320}
          >
            <Sidebar />
          </Sider>

          <RoomHeader />
          <Content
            style={{ minHeight: 280, height: "100vh", position: "relative" }}
          >
            {spaceStore.ready ? (
              spaceStore.currentRoom?.receptionRoom ? (
                <SpaceSlides />
              ) : (
                <>
                  {spaceStore.streamToken ? (
                    <Room url={RTC_WD} token={spaceStore.streamToken} />
                  ) : null}

                  {chatStore.chatVisible ? (
                    <div className={styles.chat}>
                      <Chat />
                    </div>
                  ) : null}
                  <div id={"rtc-controls"} />
                  {/* {spaceStore.experimental3dRoom ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Room3d images={[]} click={() => { }} />
                    </Suspense>
                  )  */}
                  {spaceStore.currentRoom?.plotId ? (
                    <>
                      <Plot
                        noConnect={false}
                        username={`${authStore.currentUser!.firstName} ${
                          authStore.currentUser!.lastName
                        }`}
                        avatar={authStore.currentUser!.avatar}
                        identityId={authStore.currentUser!.identityId}
                        plotId={spaceStore.currentRoom?.plotId}
                      />
                      <div
                        className={styles.shareScreenContainer}
                        id={"screenshare-slides-container"}
                      />
                    </>
                  ) : spaceStore.currentRoom?.focusModeEnabled ? null : (
                    <Workspace />
                  )}
                </>
              )
            ) : null}
          </Content>
          <div id="slide-video-room"></div>
        </Layout>
      </Content>
    </Layout>
  );
};

export default observer(Space);
