import { observer } from "mobx-react";
import styles from "./imageCell.module.scss";
import { Widget } from "@uploadcare/react-widget";
import ImageControls from "./ImageControls";
import { OBJECT_FIT } from "../../../../types";

interface Props {
  cdnUrl: string;
  objectFit?: OBJECT_FIT;
  background?: string;
  readonly: boolean;
  isActive: boolean;
  onChangeCb: (model: {
    cdnUrl: string;
    objectFit: OBJECT_FIT;
    background: string;
  }) => void;
}

const ImageCell = ({
  cdnUrl,
  objectFit = OBJECT_FIT.CONTAIN,
  background = "theme",
  onChangeCb,
  readonly,
  isActive,
}: Props) => {
  return (
    <>
      {isActive && !readonly ? (
        <ImageControls
          cdnUrl={cdnUrl}
          objectFit={objectFit}
          background={background}
          onChangeCb={(value: OBJECT_FIT) => {
            onChangeCb({ cdnUrl, objectFit: value, background });
          }}
          onChangeBackground={(color) => {
            onChangeCb({ cdnUrl, objectFit, background: color });
          }}
          removeImage={() => {
            onChangeCb({ cdnUrl: "", objectFit, background });
          }}
        />
      ) : null}
      {cdnUrl ? (
        <div
          className={`${styles.image} ${
            background === "theme" ? styles.imageThemeBackground : ""
          }`}
          style={{
            background:
              background === "transparent" ? "transparent" : undefined,
          }}
        >
          <img
            // @ts-ignore
            src={cdnUrl}
            style={{
              objectFit: objectFit === OBJECT_FIT.CONTAIN ? "contain" : "cover",
            }}
            alt=""
          />
        </div>
      ) : (
        <div className={styles.addImageWrapper}>
          {!readonly ? (
            <Widget
              localeTranslations={{
                buttons: {
                  choose: {
                    files: {
                      one: "Drop image/Select from disk",
                    },
                  },
                },
              }}
              clearable={true}
              // @ts-ignore
              onChange={(info: { cdnUrl: string }) => {
                // setImage(info.cdnUrl);
                // set
                onChangeCb({
                  cdnUrl: info.cdnUrl,
                  objectFit: OBJECT_FIT.CONTAIN,
                  background: "theme",
                });
              }}
              tabs={"file camera url"}
              publicKey="f4d9f840695d97e9cbb7"
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default observer(ImageCell);
