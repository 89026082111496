import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import createStore, { Environment } from "./helpers/create-store";
import { StoreProvider } from "./helpers/store-provider";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
dayjs.extend(relativeTime);

const tagManagerArgs = {
  gtmId: "G-CF73X4L487",
};
let envConfig: Environment;
const nodeEnv = process.env.NODE_ENV;

try {
  envConfig = require(`./env/${nodeEnv}.json`);
} catch {
  envConfig = require("./env/development.json");
}

const { rootStore, env } = createStore({ envConfig });

if (nodeEnv === "development") {
  // for debugging
  console.log(rootStore);
} else {
  TagManager.initialize(tagManagerArgs);

  Sentry.init({
    dsn: "https://17bd5cac2d314d3786c87119474e1af6@o1266660.ingest.sentry.io/6451935",
    integrations: [new BrowserTracing()],
    release: process.env.COMMIT_REF,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const sendToGoogleAnalytics = ({ name, delta, id }: any) => {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/gtagjs
  // @ts-ignore
  window.gtag &&
    // @ts-ignore
    window.gtag("event", name, {
      event_category: "Web Vitals",
      // The `id` value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      event_label: id,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      value: Math.round(name === "CLS" ? delta * 1000 : delta),
      // Use a non-interaction event to avoid affecting bounce rate.
      non_interaction: true,

      // OPTIONAL: any additional params or debug info here.
      // See: https://web.dev/debug-web-vitals-in-the-field/
      // metric_rating: 'good' | 'ni' | 'poor',
      // debug_info: '...',
      // ...
    });
};

const initApp = async () => {
  // run Mocks on development
  // if (nodeEnv === "development") {
  //   const { worker } = require("./mocks/browser");
  //   await worker.start();
  // }

  await rootStore.uiStore.authStore.loginIfTokenExists();
  await env.translationService.init();

  ReactDOM.render(
    <StoreProvider value={rootStore}>
      <App />
    </StoreProvider>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

  reportWebVitals(sendToGoogleAnalytics);
};

initApp();
