import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks/use-store";
import styles from "./slidesControlBackground.module.scss";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import { useState } from "react";
import { Col, Drawer, Form, Row, Slider, Avatar, Tooltip } from "antd";
import { Widget } from "@uploadcare/react-widget";
import Button from "../../../../../components/button/Button";
import PexelsImageModal from "../../../../pexels/PexelsImageModal";
import { TARIFFS } from "../../../../../helpers/enums";
const Background = () => {
  const {
    dataStore: { plotStore, spaceStore },
  } = useStore();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [pexelsModalOpen, setPexelsModalOpen] = useState(false);

  const formatter = (value: number | undefined) =>
    `${(value ? value * 100 : 0).toFixed(0)}%`;

  if (!spaceStore.plan) {
    return null;
  }
  if (spaceStore.plan === TARIFFS.FREE) {
    return (
      <Tooltip
        title="Slides customization is not available on your plan. Please upgrade"
        placement="left"
      >
        <div className={styles.trigger}>
          <svg>
            <use xlinkHref={`${remixiconUrl}#ri-settings-3-fill`} />
          </svg>
        </div>
      </Tooltip>
    );
  }
  return (
    <>
      <div
        className={styles.trigger}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <svg className={plotStore.displayGrid ? styles.selected : ""}>
          <use xlinkHref={`${remixiconUrl}#ri-settings-3-fill`} />
        </svg>
      </div>
      <Drawer
        title={"Slides settings"}
        width={580}
        onClose={() => {
          console.log("trigger");
          setDrawerOpen(false);
          console.log(drawerOpen);
        }}
        open={drawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={16}>
            <Col span={24}>
              <div>Choose logo of your slides:</div>
              <div className={styles.background}>
                <Avatar
                  style={{
                    backgroundColor: "#a8a8a8",
                    borderRadius: "6px",
                    height: "140px",
                    width: "140px",
                  }}
                  size={120}
                  src={plotStore.logo}
                />

                <div>
                  <Widget
                    localeTranslations={{
                      buttons: {
                        choose: {
                          files: {
                            one: "Upload slides logo",
                          },
                        },
                      },
                    }}
                    clearable={true}
                    // @ts-ignore
                    onChange={(info: { cdnUrl: string }) => {
                      plotStore.changePlot({
                        logo: info.cdnUrl,
                      });
                    }}
                    tabs={"file camera url"}
                    publicKey="f4d9f840695d97e9cbb7"
                  />
                  {plotStore.logo ? (
                    <>
                      &nbsp;
                      <Button
                        type="primary"
                        onClick={() => {
                          plotStore.changePlot({
                            logo: null,
                          });
                        }}
                      >
                        Delete logo
                      </Button>
                    </>
                  ) : null}
                </div>
              </div>

              <div>Choose background of your slides:</div>
              <div className={styles.background}>
                <Avatar
                  style={{
                    backgroundColor: "#a8a8a8",
                    borderRadius: "6px",
                    width: "300px",
                  }}
                  size={120}
                  src={plotStore.background}
                />
                <div>
                  <Widget
                    localeTranslations={{
                      buttons: {
                        choose: {
                          files: {
                            one: "Upload slides background",
                          },
                        },
                      },
                    }}
                    clearable={true}
                    // @ts-ignore
                    onChange={(info: { cdnUrl: string }) => {
                      plotStore.changePlot({
                        background: info.cdnUrl,
                      });
                    }}
                    tabs={"file camera url"}
                    publicKey="f4d9f840695d97e9cbb7"
                  />
                  {plotStore.background ? (
                    <>
                      &nbsp;
                      <Button
                        type="primary"
                        onClick={() => {
                          plotStore.changePlot({
                            background: null,
                          });
                        }}
                      >
                        Delete background
                      </Button>
                    </>
                  ) : null}
                  &nbsp;
                  <Button
                    type="primary"
                    onClick={() => {
                      setPexelsModalOpen(true);
                    }}
                  >
                    Choose from Pexels
                  </Button>
                </div>
              </div>
              <div>
                <div>Adjust background opacity:</div>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  tooltip={{ formatter }}
                  onAfterChange={(val) => {
                    plotStore.changePlot({
                      backgroundOpacity: val.toString(),
                    });
                  }}
                  defaultValue={
                    plotStore.backgroundOpacity
                      ? +plotStore.backgroundOpacity
                      : 1
                  }
                />
              </div>
            </Col>
          </Row>
        </Form>
        {pexelsModalOpen ? (
          <PexelsImageModal
            visible={pexelsModalOpen}
            handleAdd={(image: string) => {
              plotStore.changePlot({
                background: image,
              });
              setPexelsModalOpen(false);
            }}
            handleCancel={() => setPexelsModalOpen(false)}
          />
        ) : null}
      </Drawer>
    </>
  );
};

export default observer(Background);
