import React from "react";
import { ConfigProvider, Layout } from "antd";
import { observer } from "mobx-react";
import { useStore } from "./hooks/use-store";
import LoggedIn from "./pages/routers/logged-in";
import LoggedOut from "./pages/routers/logged-out";
import { AuthState } from "./stores/ui/auth-store/auth-store";
// import Header from "./components/header/header";

// order important so we can override antd
import "antd/dist/reset.css";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Invite from "./features/invite";
import PlotWrapper from "./pages/public/plot/PlotWrapper";
import KickedPage from "./pages/public/kicked/KickedPage";
import ForbiddenPage from "./pages/public/forbidden/ForbiddenPage";
import MeetingRouter from "./features/meeting/MeetingRouter";
import SessionOver from "./pages/public/sessionOver/SessionOver";

const { Content } = Layout;

function App() {
  const rootStore = useStore();
  const {
    uiStore: { authStore },
  } = rootStore;

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `Outfit, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto","Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif`,
          // colorPrimary: '#00b96b',
        },
      }}
      direction={"ltr"}
    >
      <Layout className="App">
        <Content className="site-layout">
          <Router>
            <Switch>
              {/* <Route path="/space/:spaceId">
                <SpaceRouter />
              </Route> */}
              <Route path="/meeting/:roomId">
                <MeetingRouter />
              </Route>
              <Route path="/p/:plotId">
                <PlotWrapper />
              </Route>
              {/* <Route path="/invite/">
                <Invite />
              </Route> */}
              <Route path="/kicked/">
                <KickedPage />
              </Route>
              <Route path="/forbidden/">
                <ForbiddenPage />
              </Route>
              <Route path="/end/">
                <SessionOver />
              </Route>
              {authStore.authState === AuthState.LoggedIn ? (
                <LoggedIn />
              ) : (
                <LoggedOut />
              )}
            </Switch>
          </Router>
        </Content>
      </Layout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"dark"}
      />
    </ConfigProvider>
  );
}

export default observer(App);
