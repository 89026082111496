import CrudService from "../core/crud-service";
import { ROLE } from "../../types";

export default class SpacesService extends CrudService {
  static route = "api/v1/spaces";

  async list() {
    return await this.httpService.get<any>(`${this.path}/list`);
  }

  async getSpacePublicAuth(spaceId: string) {
    return await this.httpService.get<any>(
      `${this.path}/${spaceId}/public/auth`
    );
  }
  async listMembersBySpace(spaceId: string) {
    return await this.httpService.get<any>(
      `${this.path}/${spaceId}/list/members`
    );
  }

  async listInvitesBySpace(spaceId: string) {
    return await this.httpService.get<any>(
      `${this.path}/${spaceId}/list/invites`
    );
  }

  async listRoomsBySpaceId(spaceId: string) {
    return await this.httpService.get<any>(`${this.path}/${spaceId}/rooms`);
  }

  async getSpacePublicNotAuth(spaceId: string) {
    return await this.httpService.get<any>(`${this.path}/${spaceId}/public/`);
  }

  async updateSpace(model: {
    id: number;
    name: string;
    password?: string | null;
    welcomePageEnabled?: boolean;
    welcomePageName?: string;
    welcomePageDescription?: string;
    welcomePageDate?: string;
    welcomePageBackground?: string | null;
    logo: string | null;
  }) {
    return await this.httpService.post<any, any>(
      `${this.path}/${model.id}`,
      model
    );
  }

  async validatePassword(model: { spaceId: string; password: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/${model.spaceId}/validate-password`,
      {
        password: model.password,
      }
    );
  }

  async createSpace(model: {
    name: string;
    password?: string | null;
    welcomePageEnabled?: boolean;
    welcomePageName?: string;
    welcomePageDescription?: string;
    welcomePageDate?: string;
    welcomePageBackground?: string | null;
    logo: string | null;
  }) {
    return await this.httpService.post<any, any>(`${this.path}/`, model);
  }

  async setRole(model: { role: ROLE; spaceId: string; rooms?: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/role/set`,
      model
    );
  }

  async deleteSpace(model: { id: number }) {
    return await this.httpService.delete<any>(`${this.path}/${model.id}`);
  }

  async deleteMember(id: number) {
    return await this.httpService.delete<any>(`${this.path}/${id}/member`);
  }

  async deleteInvite(id: number) {
    return await this.httpService.delete<any>(`${this.path}/${id}/invite`);
  }
}
