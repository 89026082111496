import { action, makeObservable, observable } from "mobx";
import { io, Socket } from "socket.io-client";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import PrezaElement from "./prezaElement";
import { ELEMENT_TYPES } from "../../../helpers/enums";
import {
  IframeElementData,
  ImageElementData,
  OBJECT_FIT,
  PLOT_MODE,
  PLOT_THEME,
  SLIDE_TEMPLATES,
  TextElementData,
  TEXT_ALIGN,
} from "../../../types";
import { nanoid } from "nanoid";
import PlotParticipant from "./plotParticipant";

import isEqual from "lodash/isEqual";
import { SLIDES_COLUMNS } from "../../../helpers/constants";
import { arrayMove } from "../../../helpers/helper";
import { themeColors } from "./theme";

interface UpdateModel {
  plotUpdated?: {
    backgroundOpacity?: string;
    background?: string;
    logo?: string;
    theme?: PLOT_THEME;
    name?: string;
    password?: string;
    mode?: PLOT_MODE;
    pagesOrder?: string[];
  };
  pageAdded?: {
    pageId: string;
    data: PlotLayout[];
  };
  pageUpdated?: {
    pageId: string;
    data: PlotLayout[];
  };
  elementUpdated?: {
    data: PrezaElement;
  };
  elementAdded?: {
    elements: PrezaElement[];
  };
  elementDeleted?: {
    elementId: string;
  };
  pageDeleted?: {
    pageId: string;
  };
}

interface PlotLayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export class PlotStore {
  ready: boolean = false;
  socket: Socket | undefined;
  identityId: string;

  @observable elementsMap: Map<string, PrezaElement> = new Map();
  @observable participantMap: Map<string, PlotParticipant> = new Map();
  @observable pages: Map<string, PlotLayout[]> = new Map();
  @observable pagesOrder: string[] = [];
  @observable theme: string = "";
  @observable name: string = "";
  @observable password: string = "";
  @observable mode: PLOT_MODE;
  @observable activePageId: string = "";
  @observable backgroundOpacity?: string = "";
  @observable background?: string = "";
  @observable logo?: string = "";

  @observable plotId: string;
  @observable readonly: boolean = false;
  @observable displayGrid: boolean = false;

  constructor() {
    makeObservable(this);
  }

  private getSocket = () => {
    if (this.socket) {
      return this.socket;
    } else {
      return {
        emit: () => {},
        disconnect: () => {},
      };
    }
  };

  async disconnect() {
    if (this.ready) {
      this.getSocket().disconnect();
      this.ready = false;
      this.elementsMap = new Map();
      this.participantMap = new Map();
      this.pages = new Map();
      this.pagesOrder = [];
      this.theme = "";
      this.name = "";
      this.password = "";
      this.activePageId = "";
      this.backgroundOpacity = "";
      this.background = "";
      this.logo = "";
      this.plotId = "";
      this.readonly = false;
      this.displayGrid = false;
    }
  }

  async connect(model: {
    plotId: string;
    identityId: string;
    username: string;
    avatar: string | null;
  }) {
    this.elementsMap = new Map();
    this.participantMap = new Map();
    this.pages = new Map();
    this.pagesOrder = [];
    this.theme = "";
    this.name = "";
    this.password = "";
    this.activePageId = "";
    this.backgroundOpacity = "";
    this.background = "";
    this.logo = "";
    this.plotId = "";
    this.readonly = false;
    this.displayGrid = false;

    this.ready = false;
    this.plotId = model.plotId;
    this.identityId = model.identityId;

    this.socket = io(getEnv().envConfig.api_end_point, {
      path: "/plot/socket.io",
      transports: ["websocket"],
    });
    this.socket.on("connect", () => {
      // this.ready = true;

      this.getSocket().emit(
        "join",
        {
          plotId: model.plotId,
          identityId: model.identityId,
          username: model.username,
          avatar: model.avatar,
        },
        (res: {
          elements: { [key: string]: PrezaElement };
          pages: { [key: string]: PlotLayout[] };
          pagesOrder: string[];
          theme: string;
          name: string;
          password: string;
          mode: PLOT_MODE;
          backgroundOpacity?: string;
          background?: string;
          logo?: string;
          participants: {
            avatar: string;
            identityId: string;
            username: string;
            activePageId: string;
            activeElementId: string;
          }[];
        }) => {
          this.ready = true;
          this.password = res.password;
          this.mode = res.mode;

          this.setSlidesUpdateState(res);
          for (const participant of res.participants) {
            this.participantMap.set(
              participant.identityId,
              new PlotParticipant({
                avatar: participant.avatar,
                username: participant.username,
                identityId: participant.identityId,
                activeElementId: participant.activeElementId,
                activePageId: participant.activePageId,
              })
            );
          }
        }
      );
    });

    this.socket.on("update", (data: UpdateModel) => {
      if (data.pageAdded) {
        this.pages.set(data.pageAdded.pageId, data.pageAdded.data);
      }
      if (data.pageUpdated) {
        this.pages.set(data.pageUpdated.pageId, data.pageUpdated.data);
      }
      if (data.pageDeleted) {
        this.pages.delete(data.pageDeleted.pageId);
        this.pagesOrder = this.pagesOrder.filter(
          (itm) => itm !== data.pageDeleted?.pageId
        );
        if (data.pageDeleted.pageId === this.activePageId) {
          const first = this.pagesOrder[0];
          if (first) {
            this.changeActivePageId(first);
          }
        }
      }
      if (data.elementAdded) {
        for (const el of data.elementAdded.elements) {
          this.elementsMap.set(el.id, el);
        }
      }
      if (data.elementUpdated) {
        this.elementsMap.set(
          data.elementUpdated.data.id,
          data.elementUpdated.data
        );
      }
      if (data.elementDeleted) {
        this.elementsMap.delete(data.elementDeleted.elementId);
      }
      if (data.plotUpdated) {
        if (data.plotUpdated.name) {
          this.name = data.plotUpdated.name;
        }
        if (data.plotUpdated.theme) {
          this.theme = data.plotUpdated.theme;
          this.updateTheme();
        }
        if (data.plotUpdated.mode) {
          this.mode = data.plotUpdated.mode;
        }
        if (data.plotUpdated.background !== undefined) {
          this.background = data.plotUpdated.background;
        }
        if (data.plotUpdated.backgroundOpacity) {
          this.backgroundOpacity = data.plotUpdated.backgroundOpacity;
        }
        if (data.plotUpdated.logo !== undefined) {
          this.logo = data.plotUpdated.logo;
        }
        if (data.plotUpdated.password) {
          this.password = data.plotUpdated.password;
        }
        if (data.plotUpdated.pagesOrder) {
          this.pagesOrder = data.plotUpdated.pagesOrder;
        }
      }
    });

    // this.socket.on("pageAdded", (data: { pageId: string; data: PlotLayout[] }) => {
    //   this.pages.set(data.pageId, data.data);
    // });
    // this.socket.on(
    //   "pageUpdated",
    //   (data: { pageId: string; data: PlotLayout[] }) => {
    //     this.pages.set(data.pageId, data.data);
    //   }
    // );
    // this.socket.on("pageDeleted", (data: { pageId: string }) => {
    //   this.pages.delete(data.pageId);
    //   this.pagesOrder = this.pagesOrder.filter((itm) => itm !== data.pageId);
    //   if (data.pageId === this.activePageId) {
    //     const iterator = this.pages.entries();
    //     const firstIteration = iterator.next(); // {value: ['a', 1], done: false}
    //     const first = firstIteration.value;

    //     if (first) {
    //       this.changeActivePageId(first[0]);
    //     }
    //   }
    // });

    // this.socket.on("elementAdded", (data: { element: PrezaElement }) => {
    //   this.elementsMap.set(data.element.id, data.element);
    // });
    // this.socket.on(
    //   "elementUpdated",
    //   (data: { data: PrezaElement }) => {
    //     this.elementsMap.set(data.data.id, data.data);
    //   }
    // );
    // this.socket.on("elementDeleted", (data: { elementId: string }) => {
    //   this.elementsMap.delete(data.elementId);
    // });
    this.socket.on(
      "participantUpdated",
      (data: {
        identityId: string;
        activePageId: string;
        activeElementId: string | null;
      }) => {
        const participant = this.participantMap.get(data.identityId);
        if (participant) {
          this.participantMap.set(
            data.identityId,
            new PlotParticipant({
              avatar: participant.avatar,
              username: participant.username,
              identityId: data.identityId,
              activeElementId: data.activeElementId,
              activePageId: data.activePageId,
            })
          );
        }
      }
    );
    this.socket.on(
      "participantAdded",
      (data: {
        avatar: string | null;
        username: string;
        identityId: string;
        activePageId: string;
        activeElementId: string | null;
      }) => {
        this.participantMap.set(
          data.identityId,
          new PlotParticipant({
            avatar: data.avatar,
            username: data.username,
            identityId: data.identityId,
            activeElementId: data.activeElementId,
            activePageId: data.activePageId,
          })
        );
      }
    );
    this.socket.on(
      "participantAdded",
      (data: {
        avatar: string | null;
        username: string;
        identityId: string;
        activePageId: string;
        activeElementId: string | null;
      }) => {
        this.participantMap.set(
          data.identityId,
          new PlotParticipant({
            avatar: data.avatar,
            username: data.username,
            identityId: data.identityId,
            activeElementId: data.activeElementId,
            activePageId: data.activePageId,
          })
        );
      }
    );
    this.socket.on(
      "participantDisconnected",
      (data: { identityId: string }) => {
        this.participantMap.delete(data.identityId);
      }
    );

    // this.socket.on(
    //   "pageAdd",
    //   (data: {
    //     elements: { [key: string]: PrezaElement };
    //     pages: { [key: string]: GridLayout.Layout[] };
    //     theme: string;
    //   }) => {
    //     this.setSlidesUpdateState(data);
    //   }
    // );
  }

  @action
  addNewBlockToPrezaPage = (model: { type: ELEMENT_TYPES; data?: any }) => {
    const activePage = this.pages.get(this.activePageId);
    const id = nanoid();

    if (activePage) {
      let element = null;

      if (model.type === ELEMENT_TYPES.TEXT) {
        element = {
          type: ELEMENT_TYPES.TEXT,
          id: id,
          data: {
            content: null,
            timestamp: Date.now(),
          },
        };
      } else if (model.type === ELEMENT_TYPES.IMAGE) {
        element = {
          type: ELEMENT_TYPES.IMAGE,
          id: id,
          data: {
            cdnUrl: model.data?.cdnUrl || "",
            objectFit: model.data?.objectFit || OBJECT_FIT.CONTAIN,
            background: model.data?.background || "theme",
            height: model.data?.height || 938,
            width: model.data?.width || 1393,
          },
        };
      } else if (model.type === ELEMENT_TYPES.IFRAME) {
        element = {
          type: ELEMENT_TYPES.IFRAME,
          id: id,
          data: {
            url: model.data?.url || "",
          },
        };
      } else if (model.type === ELEMENT_TYPES.SCREENSHARE) {
        element = {
          type: ELEMENT_TYPES.SCREENSHARE,
          id: id,
          data: {},
        };
      }

      this.getSocket().emit(
        "elementAdd",
        {
          plotId: this.plotId,
          element,
        },
        () => {}
      );

      const lastYIndex = activePage.reduce((sum, el) => {
        if (el.y + el.h > sum) {
          return el.y + el.h;
        } else {
          return sum;
        }
      }, 0);

      this.getSocket().emit(
        "pageUpdate",
        {
          plotId: this.plotId,
          pageId: this.activePageId,
          data: [
            ...activePage,
            { i: id, x: 0, y: lastYIndex + 1, w: SLIDES_COLUMNS, h: 6 },
          ],
        },
        () => {}
      );
    }

    //   this.pages.set(this.activePageId, [
    //     ...activePage,
    //     { i: id, x: 1, y: 1, w: 4, h: 2 },
    //   ]);
    // }
  };

  @action
  updateLayout = (layout: PlotLayout[]) => {
    // this.pages.set(this.activePageId, layout);

    if (!isEqual(layout, this.pages.get(this.activePageId))) {
      this.getSocket().emit(
        "pageUpdate",
        {
          plotId: this.plotId,
          pageId: this.activePageId,
          data: layout,
        },
        () => {}
      );
    }
  };

  @action
  updatePrezaElementData = (
    id: string,
    data: ImageElementData | TextElementData | IframeElementData
  ) => {
    const element = this.elementsMap.get(id);
    // if (element) {
    //   this.elementsMap.set(
    //     id,
    //     new PrezaElement({
    //       ...element,
    //       data,
    //     })
    //   );
    // }

    this.getSocket().emit(
      "elementUpdate",
      {
        plotId: this.plotId,
        elementId: id,
        data: {
          ...element,
          data,
        },
      },
      () => {}
    );
  };

  @action
  createNewPage = (type: SLIDE_TEMPLATES) => {
    let data: any[] = [];
    let elements: any[] = [];

    if (type === SLIDE_TEMPLATES.START_PAGE) {
      const textId = nanoid();

      data.push({ i: textId, x: 0, y: 0, w: SLIDES_COLUMNS, h: 18 });
      elements = [
        {
          type: ELEMENT_TYPES.TEXT,
          id: textId,
          data: {
            content: {
              type: "doc",
              content: [
                {
                  type: "heading",
                  attrs: { textAlign: "center", level: 1 },
                  content: [{ type: "text", text: "📚 Presentation Title" }],
                },
                { type: "heading", attrs: { textAlign: "center", level: 4 } },
                {
                  type: "heading",
                  attrs: { textAlign: "center", level: 4 },
                  content: [{ type: "text", text: "2023 Cake 🍰" }],
                },
              ],
            },
            timestamp: Date.now(),
            align: TEXT_ALIGN.CENTER,
          },
        },
      ];
    } else if (type === SLIDE_TEMPLATES.PLAIN_TEXT) {
      const textId = nanoid();

      data.push({ i: textId, x: 0, y: 0, w: SLIDES_COLUMNS, h: 12 });

      elements = [
        {
          type: ELEMENT_TYPES.TEXT,
          id: textId,
          data: {
            content: {
              type: "doc",
              content: [
                {
                  type: "heading",
                  attrs: { textAlign: "left", level: 1 },
                  content: [
                    { type: "text", text: "🍰 Create free slides" },
                    { type: "text", marks: [{ type: "bold" }], text: " " },
                  ],
                },
                { type: "paragraph", attrs: { textAlign: "left" } },
                {
                  type: "heading",
                  attrs: { textAlign: "left", level: 4 },
                  content: [
                    { type: "text", text: "Bye bye boring meetings. " },
                  ],
                },
                {
                  type: "heading",
                  attrs: { textAlign: "left", level: 4 },
                  content: [
                    {
                      type: "text",
                      text: "Cake is a virtual workspace made to avoid unnecessary meetings.",
                    },
                  ],
                },
                { type: "paragraph", attrs: { textAlign: "left" } },
                {
                  type: "paragraph",
                  attrs: { textAlign: "left" },
                  content: [
                    {
                      type: "text",
                      text: "Made for collaboration, sales & learning. ",
                    },
                  ],
                },
              ],
            },
            timestamp: Date.now(),
            align: TEXT_ALIGN.START,
          },
        },
      ];
    } else if (type === SLIDE_TEMPLATES.TEXT_IMAGE) {
      const textId = nanoid();
      const imageId = nanoid();

      data.push({ i: textId, x: 0, y: 0, w: 12, h: 18 });
      data.push({ i: imageId, x: 12, y: 0, w: 12, h: 18 });

      elements = [
        {
          type: ELEMENT_TYPES.TEXT,
          id: textId,
          data: {
            content: {
              type: "doc",
              content: [
                {
                  type: "heading",
                  attrs: { textAlign: "left", level: 1 },
                  content: [
                    { type: "text", text: "🍰 Create free slides" },
                    { type: "text", marks: [{ type: "bold" }], text: " " },
                  ],
                },
                { type: "paragraph", attrs: { textAlign: "left" } },
                {
                  type: "heading",
                  attrs: { textAlign: "left", level: 4 },
                  content: [
                    { type: "text", text: "Bye bye boring meetings. " },
                  ],
                },
                {
                  type: "heading",
                  attrs: { textAlign: "left", level: 4 },
                  content: [
                    {
                      type: "text",
                      text: "Cake is a virtual workspace made to avoid unnecessary meetings.",
                    },
                  ],
                },
                { type: "paragraph", attrs: { textAlign: "left" } },
                {
                  type: "paragraph",
                  attrs: { textAlign: "left" },
                  content: [
                    {
                      type: "text",
                      text: "Made for collaboration, sales & learning. ",
                    },
                  ],
                },
              ],
            },
            timestamp: Date.now(),
            align: TEXT_ALIGN.CENTER,
          },
        },
        {
          type: ELEMENT_TYPES.IMAGE,
          id: imageId,
          data: {
            cdnUrl: null,
            height: 0,
            width: 0,
          },
        },
      ];
    } else if (type === SLIDE_TEMPLATES.IMAGE_2TEXT) {
      const imageId = nanoid();
      const textId1 = nanoid();
      const textId2 = nanoid();

      data.push({ i: imageId, x: 0, y: 0, w: SLIDES_COLUMNS, h: 6 });
      data.push({ i: textId1, x: 0, y: 6, w: 12, h: 8 });
      data.push({ i: textId2, x: 12, y: 6, w: 12, h: 8 });

      elements = [
        {
          type: ELEMENT_TYPES.TEXT,
          id: textId1,
          data: {
            content: {
              type: "doc",
              content: [
                {
                  type: "heading",
                  attrs: { textAlign: "left", level: 2 },
                  content: [{ type: "text", text: "🪄 Magic wand" }],
                },
                { type: "paragraph", attrs: { textAlign: "left" } },
                { type: "paragraph", attrs: { textAlign: "left" } },
                {
                  type: "paragraph",
                  attrs: { textAlign: "left" },
                  content: [{ type: "text", text: "just simple emoji" }],
                },
              ],
            },
            timestamp: Date.now(),
            align: TEXT_ALIGN.START,
          },
        },
        {
          type: ELEMENT_TYPES.TEXT,
          id: textId2,
          data: {
            content: {
              type: "doc",
              content: [
                {
                  type: "heading",
                  attrs: { textAlign: "left", level: 2 },
                  content: [{ type: "text", text: "🎉 Party Popper" }],
                },
                { type: "paragraph", attrs: { textAlign: "left" } },
                { type: "paragraph", attrs: { textAlign: "left" } },
                {
                  type: "paragraph",
                  attrs: { textAlign: "left" },
                  content: [{ type: "text", text: "just simple emoji" }],
                },
              ],
            },
            timestamp: Date.now(),
          },
          timestamp: Date.now(),
          align: TEXT_ALIGN.START,
        },
        {
          type: ELEMENT_TYPES.IMAGE,
          id: imageId,
          data: {
            cdnUrl: null,
            height: 938,
            width: 1393,
          },
        },
      ];
    }
    this.getSocket().emit(
      "pageAdd",
      {
        plotId: this.plotId,
        data: data,
        elements: elements,
      },
      () => {}
    );
  };

  @action
  deletePrezaElement = (model: { id: string }) => {
    const activePage = this.pages.get(this.activePageId);

    if (activePage) {
      this.getSocket().emit(
        "pageUpdate",
        {
          plotId: this.plotId,
          pageId: this.activePageId,
          data: activePage.filter((itm) => itm.i !== model.id),
        },
        () => {}
      );

      this.getSocket().emit(
        "elementDelete",
        {
          plotId: this.plotId,
          elementId: model.id,
        },
        () => {}
      );
    }
  };

  @action
  deletePrezaPage = (id: string) => {
    this.getSocket().emit(
      "pageDelete",
      {
        plotId: this.plotId,
        pageId: id,
      },
      () => {}
    );

    this.changeSlidesUpdateState();
  };

  @action
  setTestData = (model: {
    state: any;
    theme: string;
    name: string;
    backgroundOpacity?: string;
    background?: string;
    logo?: string;
  }) => {
    this.ready = true;
    this.setSlidesUpdateState({
      elements: model.state.elements,
      pages: model.state.pages,
      pagesOrder: model.state.pagesOrder,
      theme: model.theme,
      name: model.name,
      backgroundOpacity: model.backgroundOpacity,
      background: model.background,
      logo: model.logo,
    });
  };

  @action
  changePlot = (model: {
    name?: string;
    theme?: string;
    password?: string;
    pagesOrder?: string[];
    mode?: PLOT_MODE;
    backgroundOpacity?: string;
    background?: string | null;
    logo?: string | null;
  }) => {
    this.updatePlot(model);
  };

  @action
  setReadonlyMode = (readonly: boolean) => {
    this.readonly = readonly;
  };

  @action
  setGridVisibility = (displayGrid: boolean) => {
    this.displayGrid = displayGrid;
  };

  private updatePlot = (model: {
    plotId?: string;
    name?: string;
    theme?: string;
    password?: string;
    pagesOrder?: string[];
    mode?: PLOT_MODE;
    backgroundOpacity?: string;
    background?: string | null;
    logo?: string | null;
  }) => {
    return this.getSocket().emit(
      "plotUpdate",
      {
        plotId: this.plotId,
        name: this.name,
        theme: this.theme,
        password: this.password,
        pagesOrder: this.pagesOrder,
        mode: this.mode,
        backgroundOpacity: this.backgroundOpacity,
        background: this.background,
        logo: this.logo,
        ...model,
      },
      () => {}
    );
  };

  changeSlidesUpdateState = () => {
    const pages: any = {};

    this.pages.forEach((itm, key) => {
      pages[key] = itm;
    });
    const elements: any = {};
    this.elementsMap.forEach((itm, key) => {
      elements[key] = itm;
    });
    this.getSocket().emit(
      "slideUpdate",
      {
        plotId: this.plotId,
        state: {
          elementsMap: elements,
          pages: pages,
          theme: this.theme,
          backgroundOpacity: this.backgroundOpacity,
          background: this.background,
          logo: this.logo,
        },
      },
      () => {}
    );
    return;
  };

  @action
  changeActivePageId = (pageId: string) => {
    this.activePageId = pageId;

    this.getSocket().emit(
      "participantUpdate",
      {
        plotId: this.plotId,
        identityId: this.identityId,
        username: "this.username",
        avatar: "this.avatar",
        activePageId: this.activePageId,
        activeElementId: null,
      },
      () => {}
    );
  };

  @action
  enterElement = (elementId: string | null) => {
    this.getSocket().emit(
      "participantUpdate",
      {
        plotId: this.plotId,
        identityId: this.identityId,
        activePageId: this.activePageId,
        activeElementId: elementId,
      },
      () => {}
    );
  };

  @action
  moveDown = (pageId: string) => {
    console.log(this.pagesOrder.indexOf(pageId), this.pagesOrder.length);
    if (this.pagesOrder.indexOf(pageId) === this.pagesOrder.length - 1) {
    } else {
      const arr = arrayMove(
        this.pagesOrder,
        this.pagesOrder.indexOf(pageId),
        this.pagesOrder.indexOf(pageId) + 1
      );
      this.changePlot({ pagesOrder: arr });
      this.pagesOrder = arr;
    }
  };

  @action
  moveUp = (pageId: string) => {
    if (this.pagesOrder.indexOf(pageId) === 0) {
    } else {
      const arr = arrayMove(
        this.pagesOrder,
        this.pagesOrder.indexOf(pageId),
        this.pagesOrder.indexOf(pageId) - 1
      );
      this.changePlot({ pagesOrder: arr });
      this.pagesOrder = arr;
    }
  };

  @action
  changeLayer = (id: string, back: boolean) => {
    const activePage = this.pages.get(this.activePageId);

    if (activePage) {
      const index = activePage.findIndex((el) => el.i === id);

      const lastIndex = activePage.length - 1;
      console.log(index, back ? 0 : lastIndex);
      // console.log(this.array_move(activePage, index, back ? 0 : lastIndex))
      if (index) {
        this.getSocket().emit(
          "pageUpdate",
          {
            plotId: this.plotId,
            pageId: this.activePageId,
            data: arrayMove(activePage, index, back ? 0 : lastIndex),
          },
          () => {}
        );
      }
    }
  };

  @action
  setSlidesUpdateState = (model: {
    elements: { [key: string]: PrezaElement };
    pages: { [key: string]: PlotLayout[] };
    pagesOrder: string[];
    theme: string;
    name: string;
    backgroundOpacity?: string;
    background?: string;
    logo?: string;
  }) => {
    const elements = new Map();
    Object.keys(model.elements).map((itm) => {
      elements.set(itm, model?.elements[itm]);
      return itm;
    });
    this.elementsMap = elements;

    const pages = new Map();

    Object.keys(model.pages).map((itm) => {
      pages.set(itm, model?.pages[itm]);
      return itm;
    });
    this.pages = pages;
    this.pagesOrder = model.pagesOrder;
    this.theme = model.theme;
    this.name = model.name;
    this.backgroundOpacity = model.backgroundOpacity;
    this.background = model.background;
    this.logo = model.logo;

    if (!this.activePageId) {
      const first = this.pagesOrder[0];
      if (first) {
        this.changeActivePageId(first);
      }
    }

    this.updateTheme();
  };

  updateTheme = () => {
    // @ts-ignore
    const color = themeColors[this.theme];

    if (color) {
      for (const property in color) {
        document.documentElement.style.setProperty(property, color[property]);
      }
    }
  };
}
