import React, { useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import styles from "./spaceSlides.module.scss";
import { Popconfirm, Tooltip } from "antd";
import dayjs from "dayjs";
import { DeleteOutlined, CopyOutlined } from "@ant-design/icons";
// import SideHelper from "../../sideHelper/SideHelper";
import Plot from "../../../pages/public/plot/Plot";
import SpaceSlidesCreateNewModal from "./spaceSlidesCreateNewModal/SpaceSlidesCreateNewModal";
import { themeColors } from "../../../stores/data/space-store/theme";
import { useRequest } from "ahooks";
import SpaceSlidesGPTModal from "./spaceSlidesGPTModal/SpaceSlidesGPTModal";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";

const SpaceSlides = () => {
  const {
    dataStore: { spaceStore },
    uiStore: { authStore },
  } = useStore();

  const { loading: createSlideLoading, run: createSlide } = useRequest(
    async (name: string) => spaceStore.createSlide({ name }),
    {
      loadingDelay: 300,
      manual: true,
      onSuccess: (slideId: string) => {
        setCreateNewSlideModalOpen(false);
        setActiveSlideId(slideId);
      },
    }
  );

  const { loading: createGPTSlideLoading, run: createGPTSlide } = useRequest(
    async (name: string) => spaceStore.createGPTSlide({ name }),
    {
      loadingDelay: 300,
      manual: true,
      onSuccess: (model) => {
        if (model.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
          setGptModalOpen(false);
        } else {
          setGptModalOpen(false);
          setActiveSlideId(model.id);
        }
      },
    }
  );

  const { run: dublicateSlide } = useRequest(
    async (plotId: string) =>
      await spaceStore.deleteSlide({
        plotId,
      }),
    {
      loadingDelay: 300,
      manual: true,
    }
  );

  const [createNewSlideModalOpen, setCreateNewSlideModalOpen] = useState(false);
  const [gptModalOpen, setGptModalOpen] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState<null | string>(null);
  // useEffect(() => {
  //   if (window.localStorage) {
  //     if (!window.localStorage.getItem("isOnboardingCompleted")) {
  //       setOpen(true);
  //     }
  //   }
  // }, []);

  // const close = () => {
  //   setOpen(false);
  //   if (window.localStorage) {
  //     window.localStorage.setItem("isOnboardingCompleted", "true");
  //   }
  // };

  if (activeSlideId) {
    return (
      <Plot
        noConnect={false}
        onCloseButtonClicked={() => {
          setActiveSlideId(null);
        }}
        username={`${authStore.currentUser!.firstName} ${
          authStore.currentUser!.lastName
        }`}
        avatar={authStore.currentUser!.avatar}
        identityId={authStore.currentUser!.identityId}
        plotId={activeSlideId}
      />
    );
  }
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${spaceStore.currentRoom?.background})`,
      }}
    >
      <div className={styles.wrapper}>
        <div>
          <div className={styles.title}>
            {spaceStore.slides.length > 0
              ? "👋 Your Cake Slides"
              : "👋 Create your first cake slide."}
          </div>
          <div className={styles.subTitle}>
            Here you can make and edit your presentations. Share the
            presentation from the creation page, or collaborate and discuss it
            in your rooms.
          </div>

          <div className={styles.slidesCardWrapper}>
            {spaceStore.slides.length > 0
              ? spaceStore.slides
                  .slice()
                  .sort(
                    (f, s) =>
                      new Date(s.updatedAt).getTime() -
                      new Date(f.updatedAt).getTime()
                  )
                  .map(
                    (itm: {
                      name: string;
                      plotId: string;
                      updatedAt: string;
                      theme: string;
                    }) => {
                      let background = "";
                      let textColor = "";
                      let bodyColor = "";

                      // @ts-ignore
                      if (themeColors[itm.theme]) {
                        // @ts-ignore
                        background = `linear-gradient(135deg, ${
                          themeColors[itm.theme]["--slides-background"]
                        } 0%, ${
                          themeColors[itm.theme]["--slides-elements-hover"]
                        } 100%)`;
                        // @ts-ignore
                        textColor =
                          themeColors[itm.theme]["--slides-text-color-primary"];
                        // @ts-ignore
                        bodyColor =
                          themeColors[itm.theme][
                            "--slides-text-color-secondary"
                          ];
                      }
                      return (
                        <div className={styles.slide} key={itm.plotId}>
                          <div
                            style={{ background }}
                            onClick={() => {
                              setActiveSlideId(itm.plotId);
                            }}
                          >
                            <div
                              className={styles.slideTitle}
                              style={{ color: textColor }}
                            >
                              {itm.name}
                            </div>

                            <div style={{ flexGrow: "1" }}></div>

                            <div className={styles.slideActions}>
                              <span
                                className={styles.slideDate}
                                style={{ color: bodyColor }}
                              >{`updated ${dayjs(itm.updatedAt).fromNow(
                                true
                              )} ago`}</span>

                              <div style={{ flexGrow: "1" }}></div>

                              <Tooltip title="Duplicate Slide">
                                <CopyOutlined
                                  style={{ color: bodyColor }}
                                  key="ellipsis"
                                  onClick={async (e) => {
                                    e?.stopPropagation();
                                    await spaceStore.duplicateSlide({
                                      plotId: itm.plotId,
                                    });
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title="Delete Slide">
                                <Popconfirm
                                  title={"Are you sure?"}
                                  onConfirm={async (e) => {
                                    e?.stopPropagation();
                                    dublicateSlide(itm.plotId);
                                  }}
                                  onCancel={(e) => {
                                    e?.stopPropagation();
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteOutlined
                                    style={{ color: bodyColor }}
                                    key="ellipsis"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                </Popconfirm>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
              : null}
            <div className={styles.slide}>
              <div
                onClick={async () => {
                  setCreateNewSlideModalOpen(true);
                }}
                style={{
                  background: themeColors["BLACK"]["--slides-background"],
                }}
              >
                <div
                  className={styles.slideTitle}
                  style={{
                    color: themeColors["BLACK"]["--slides-text-color-primary"],
                  }}
                >
                  📔 Add new slide
                </div>
              </div>
            </div>

            <div className={styles.slide}>
              <div
                onClick={async () => {
                  setGptModalOpen(true);
                }}
                style={{
                  background: themeColors["NIGHT"]["--slides-background"],
                }}
              >
                <div
                  className={styles.slideTitle}
                  style={{
                    color: themeColors["NIGHT"]["--slides-text-color-primary"],
                  }}
                >
                  🪄 Make with AI
                </div>
              </div>
            </div>
          </div>

          <SpaceSlidesCreateNewModal
            loading={createSlideLoading}
            visible={createNewSlideModalOpen}
            handleCancel={() => {
              setCreateNewSlideModalOpen(false);
            }}
            handleAdd={async (name) => createSlide(name)}
          />

          <SpaceSlidesGPTModal
            credits={spaceStore.credits}
            loading={createGPTSlideLoading}
            visible={gptModalOpen}
            handleCancel={() => {
              setGptModalOpen(false);
            }}
            handleAdd={async (name) => createGPTSlide(name)}
          />
          {/* <SpaceAccountEdit />
          <ShareBanner /> */}
        </div>
      </div>
      {/* {open ? <SideHelper close={() => close()} /> : null} */}
    </div>
  );
};

export default observer(SpaceSlides);
