import { observer } from "mobx-react";
import { useStore } from "../../../../hooks/use-store";
import styles from "./newSlideModal.module.scss";
import { Modal } from "antd";
import { useState } from "react";
import { SLIDE_TEMPLATES } from "../../../../types";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";

import image_2text from "./image_2text.png";
import start from "./start.png";
import text_image from "./text_image.png";
import text from "./text.png";
const NewSlideModal = () => {
  const {
    dataStore: { plotStore },
  } = useStore();

  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.createNewPage}>
      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        <svg className="remix">
          <use xlinkHref={`${remixiconUrl}#ri-add-line`} />
        </svg>
      </div>

      <Modal
        title={"Choose slide template"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <div className={styles.grid}>
          <div
            className={styles.item}
            onClick={() => {
              plotStore.createNewPage(SLIDE_TEMPLATES.START_PAGE);
              setVisible(false);
            }}
          >
            <img src={start} alt=""></img>
            <span>Start page</span>
          </div>
          <div
            className={styles.item}
            onClick={() => {
              plotStore.createNewPage(SLIDE_TEMPLATES.PLAIN_TEXT);
              setVisible(false);
            }}
          >
            <img src={text} alt=""></img>
            <span>Plain text</span>
          </div>
          <div
            className={styles.item}
            onClick={() => {
              plotStore.createNewPage(SLIDE_TEMPLATES.TEXT_IMAGE);
              setVisible(false);
            }}
          >
            <img src={text_image} alt=""></img>
            <span>Text with image</span>
          </div>

          <div
            className={styles.item}
            onClick={() => {
              plotStore.createNewPage(SLIDE_TEMPLATES.IMAGE_2TEXT);
              setVisible(false);
            }}
          >
            <img src={image_2text} alt=""></img>
            <span>Image with 2 texts</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(NewSlideModal);
