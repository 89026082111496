import CrudService from "../core/crud-service";

export default class PlotService extends CrudService {
  static route = "api/v1/plots";

  async getByUser() {
    return await this.httpService.get<any>(`${this.path}/byUser`);
  }

  async createPlot(model: { name: string }) {
    return await this.httpService.post<any, any>(`${this.path}/`, {
      name: model.name,
    });
  }
  async getKeynotePermission(model: {
    plotId: string;
    email: string | null;
    password?: string;
  }) {
    return await this.httpService.post<any, any>(
      `${this.path}/permissions`,
      model
    );
  }
}
