import React, { useState } from "react";
import { observer } from "mobx-react";
import { Popover } from "antd";

import { ELEMENT_TYPES } from "../../../helpers/enums";
import { useStore } from "../../../hooks/use-store";
import styles from "./addElement.module.scss";
import AddImageElementModal from "./AddImageElementModal";
import AddIframeElementModal from "./AddIframeElementModal";
import AddGiphyElementModal from "./AddGiphyElementModal";
import { nanoid } from "nanoid";
import AddElementItem from "./AddElementItem";
import Giphy from "./Giphy";
import Airtable from "./Airtable";
import Figma from "./Figma";
import Miro from "./Miro";
import Invision from "./Invision";
import Google from "./Google";
import Pitch from "./Pitch";
import MiroInstructions from "./MiroInstructions";
import FigmaInstructions from "./FigmaInstructions";

enum TYPE {
  FIGMA,
  MIRO,
  PITCH,
  INVISION,
  GOOGLE,
  AIRTABLE,
  LINK,
}

const AddElement = () => {
  const {
    dataStore: { elementsStore },
  } = useStore();

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [giphyModalOpen, setGiphyModalOpen] = useState(false);

  const [iframeModalOpen, setIframeModalOpen] = useState<null | {
    name: string;
    type: TYPE;
    instructions: any;
  }>(null);

  return (
    <>
      <Popover
        placement="topLeft"
        popupVisible={true}
        // defaultVisible={true}
        content={
          <div className={styles.popover}>
            <AddElementItem
              name={"Add Sticky Note"}
              onClick={() => {
                elementsStore.addElement(ELEMENT_TYPES.TEXT);
              }}
            />
            <AddElementItem
              name={"Upload Image/GIF"}
              onClick={() => {
                setImageModalOpen(true);
              }}
            />

            <AddElementItem
              name={"Add Whiteboard"}
              onClick={() => {
                const id = nanoid();
                elementsStore.addElement(ELEMENT_TYPES.IFRAME, {
                  url: `https://witeboard.com/cake-${id}`,
                });
              }}
            />
            <AddElementItem
              name={"Add Timer"}
              onClick={() => {
                const id = nanoid();
                elementsStore.addElement(ELEMENT_TYPES.IFRAME, {
                  url: `https://multiplayertimer.web.app/cake${id}`,
                });
              }}
            />
            <AddElementItem
              name={"Add Link"}
              onClick={() => {
                setIframeModalOpen({
                  type: TYPE.LINK,
                  name: "Add link",
                  instructions: null,
                });
              }}
            />
            <AddElementItem
              name={"Add Giphy GIF"}
              onClick={() => {
                setGiphyModalOpen(true);
              }}
              svg={<Giphy />}
            />

            <AddElementItem
              name={"Embed Airtable"}
              onClick={() => {
                setIframeModalOpen({
                  type: TYPE.AIRTABLE,
                  name: "Embed Airtable",
                  instructions: null,
                });
              }}
              svg={<Airtable />}
            />
            <AddElementItem
              name={"Embed Figma"}
              onClick={() => {
                setIframeModalOpen({
                  type: TYPE.FIGMA,
                  name: "Embed Figma",
                  instructions: <FigmaInstructions />,
                });
              }}
              svg={<Figma />}
            />
            <AddElementItem
              name={"Embed Miro"}
              onClick={() => {
                setIframeModalOpen({
                  type: TYPE.MIRO,
                  name: "Embed Miro",
                  instructions: <MiroInstructions />,
                });
              }}
              svg={<Miro />}
            />
            <AddElementItem
              name={"Embed Invision"}
              onClick={() => {
                setIframeModalOpen({
                  type: TYPE.INVISION,
                  name: "Embed Invision",
                  instructions: null,
                });
              }}
              svg={<Invision />}
            />
            <AddElementItem
              name={"Embed Pitch"}
              onClick={() => {
                setIframeModalOpen({
                  type: TYPE.PITCH,
                  name: "Embed Pitch",
                  instructions: null,
                });
              }}
              svg={<Pitch />}
            />
            <AddElementItem
              name={"Embed Google"}
              onClick={() => {
                setIframeModalOpen({
                  type: TYPE.GOOGLE,
                  name: "Embed Google Doc",
                  instructions: null,
                });
              }}
              svg={<Google />}
            />
          </div>
        }
      >
        <div className={styles.addElement}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 28C21.732 28 28 21.732 28 14C28 6.268 21.732 0 14 0C6.268 0 0 6.268 0 14C0 21.732 6.268 28 14 28ZM13 9C13 8.73478 13.1054 8.48043 13.2929 8.29289C13.4804 8.10536 13.7348 8 14 8C14.2652 8 14.5196 8.10536 14.7071 8.29289C14.8946 8.48043 15 8.73478 15 9V13H19C19.2652 13 19.5196 13.1054 19.7071 13.2929C19.8946 13.4804 20 13.7348 20 14C20 14.2652 19.8946 14.5196 19.7071 14.7071C19.5196 14.8946 19.2652 15 19 15H15V19C15 19.2652 14.8946 19.5196 14.7071 19.7071C14.5196 19.8946 14.2652 20 14 20C13.7348 20 13.4804 19.8946 13.2929 19.7071C13.1054 19.5196 13 19.2652 13 19V15H9C8.73478 15 8.48043 14.8946 8.29289 14.7071C8.10536 14.5196 8 14.2652 8 14C8 13.7348 8.10536 13.4804 8.29289 13.2929C8.48043 13.1054 8.73478 13 9 13H13V9Z"
              fill="white"
            />
          </svg>
        </div>
      </Popover>
      <AddImageElementModal
        visible={imageModalOpen}
        handleAdd={(images) => {
          for (const image of images) {
            elementsStore.addElement(ELEMENT_TYPES.IMAGE, image);
          }
          setImageModalOpen(false);
        }}
        handleCancel={() => setImageModalOpen(false)}
      />
      <AddGiphyElementModal
        visible={giphyModalOpen}
        handleAdd={(image) => {
          elementsStore.addElement(ELEMENT_TYPES.IMAGE, image);
          setGiphyModalOpen(false);
        }}
        handleCancel={() => setGiphyModalOpen(false)}
      />
      <AddIframeElementModal
        name={iframeModalOpen?.name}
        instructions={iframeModalOpen?.instructions}
        visible={!!iframeModalOpen}
        handleAdd={(url) => {
          let targetUrl = url;
          if (iframeModalOpen?.type === TYPE.MIRO) {
            targetUrl = `https://miro.com/app/live-embed/${url.replace(
              "https://miro.com/app/board/",
              ""
            )}`;
          }

          if (iframeModalOpen?.type === TYPE.FIGMA) {
            // 'https://www.figma.com/file/M3rEDtIcvkZrwZCSRAPyHp/VSBLE-2.0-ver.-3?node-id=0%3A1'
            targetUrl = `https://www.figma.com/embed?embed_host=share&url=${encodeURIComponent(
              url
            )}`;
          }

          if (iframeModalOpen?.type === TYPE.LINK) {
            elementsStore.addElement(ELEMENT_TYPES.LINK, {
              url: targetUrl,
            });
          } else {
            elementsStore.addElement(ELEMENT_TYPES.IFRAME, {
              url: targetUrl,
            });
          }
          setIframeModalOpen(null);
        }}
        handleCancel={() => setIframeModalOpen(null)}
      />
    </>
  );
};

export default observer(AddElement);
