import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import throttle from "lodash/throttle";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import MenuBar from "./Menubar";
import styles from "./text.module.scss";
import { useWindowSize } from "../../../../helpers/helper";
import { useStore } from "../../../../hooks/use-store";
import TextControls from "./controls/TextControls";
import { TEXT_ALIGN } from "../../../../types";

interface Props {
  content: any;
  align?: TEXT_ALIGN;
  timestamp: number;
  isActive: boolean;
  readonly: boolean;
  onChangeText: (json: string, date: number, align: TEXT_ALIGN) => void;
}

const Text = ({
  content,
  align = TEXT_ALIGN.START,
  timestamp,
  onChangeText,
  isActive,
  readonly,
}: Props) => {
  const {
    dataStore: { spaceStore },
    uiStore: { authStore },
  } = useStore();

  const [lastChange, setLastChange] = useState<null | number>(null);
  const debouncedChangeHandler = throttle((...params) => {
    const date = Date.now();
    setLastChange(date + 1);
    onChangeText(params, date, align);
  }, 400);

  const { width } = useWindowSize();

  const editor = useEditor({
    onUpdate({ editor }) {
      console.log(JSON.stringify(editor.getJSON()));
      debouncedChangeHandler(JSON.stringify(editor.getJSON()));
    },

    extensions: [
      StarterKit.configure({
        history: false,
      }),
      Placeholder.configure({
        // Use a placeholder:
        placeholder: "Write something …",
        // Use different placeholders depending on the node type:
      }),
      Link.configure({
        openOnClick: true,
      }),
      Highlight,
      TaskList,
      TaskItem,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    editable: !readonly,
    content: null,
  });

  let spaceSidebarWidth = 0;
  if (spaceStore.ready) {
    spaceSidebarWidth = spaceStore.spaceSidebarCollapsed ? 18 : 420;
  }
  const w = (width || 800) - spaceSidebarWidth;

  let fontSize = 6.4;
  if (w <= 840) {
    fontSize = 6.4;
  } else if (w > 840 && w <= 1096) {
    fontSize = 9.8;
  } else if (w > 1096 && w <= 1400) {
    fontSize = 10.8;
  } else if (w > 1400 && w <= 2400) {
    fontSize = 13.72;
  } else if (w > 2400) {
    fontSize = 17.8;
  }

  useEffect(() => {
    if (content) {
      if (lastChange) {
        if (timestamp < lastChange) {
          return;
        }
      }
      try {
        editor?.commands.setContent(JSON.parse(content));
      } catch (e) {
        editor?.commands.setContent(content);
      }
    }
  }, [content, timestamp, lastChange, editor]);

  if (!editor) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: align.toLocaleLowerCase(),
        height: "100%",
      }}
    >
      {!readonly && isActive ? (
        <TextControls
          align={align}
          onChangeCb={(value) => {
            onChangeText(JSON.stringify(editor.getJSON()), Date.now(), value);
          }}
        />
      ) : null}
      {!readonly && isActive ? (
        <MenuBar
          email={authStore.currentUser?.email}
          credits={spaceStore.credits}
          gptRewriteFunc={spaceStore.gptRewrite}
          editor={editor}
        />
      ) : null}

      <div className={styles.editor} style={{ fontSize: `${fontSize}px` }}>
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

export default observer(Text);
