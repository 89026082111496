import { observer } from "mobx-react";
// import { useStore } from "../../../hooks/use-store";
import styles from "./preza.module.scss";
import GridLayout from "react-grid-layout";
import Cell from "./Cell";
import { SLIDES_COLUMNS } from "../../../helpers/constants";

interface IPreviewPreza {
  layout: GridLayout.Layout[];
  active: boolean;
}
const PreviewPrezaPage = ({ layout, active }: IPreviewPreza) => {
  // const {
  //   dataStore: { spaceStore },
  // } = useStore();

  const keys = layout && layout.length > 0 ? layout.map((itm) => itm.i) : [];

  return (
    <GridLayout
      isDraggable={false}
      isResizable={false}
      className={styles.layout}
      transformScale={0.14}
      layout={layout}
      cols={SLIDES_COLUMNS}
      rowHeight={20}
      width={800}
      compactType={null}
      useCSSTransforms={true}
      allowOverlap={true}
    >
      {keys.map((itm) => (
        <div key={itm} className={`${styles.cell}`}>
          <Cell selectedCell={""} itm={itm} />
        </div>
      ))}
    </GridLayout>
  );
};

export default observer(PreviewPrezaPage);
