import React from "react";
import styles from "./routerLoader.module.scss";
import Logo from "../../../components/Logo";

const RouterLoader = () => {
  return (
    <div className={styles.visible}>
      <div className={styles.block}>
        <span>Hold on a second</span>
        <br />
        <span>We baking your</span>
        <Logo />
      </div>
    </div>
  );
};

export default RouterLoader;
