import { observer } from "mobx-react";
import { Popover } from "antd";

import { ELEMENT_TYPES } from "../../../../../helpers/enums";
import { useStore } from "../../../../../hooks/use-store";
import styles from "./addElement.module.scss";
// import { nanoid } from "nanoid";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import SlidesElementGiphy from "./SlidesElementGiphy";

const AddElement = () => {
  const {
    dataStore: { plotStore },
  } = useStore();

  return (
    <>
      <Popover
        placement="left"
        popupVisible={true}
        showArrow={false}
        // overlayClassName={styles.overlay}
        // overlayInnerStyle={{padding: 0, borderRadius: '8px'}}
        content={
          <div className={styles.popover}>
            <div className={styles.addElementContainer}>
              <div
                onClick={() =>
                  plotStore.addNewBlockToPrezaPage({
                    type: ELEMENT_TYPES.TEXT,
                  })
                }
              >
                <svg
                  viewBox="0 0 52 52"
                  style={{ width: "32px", height: "32px" }}
                >
                  <path d="M6.9715 39C7.80261 39 8.54248 38.4735 8.81484 37.6883L10.6992 32.2557C10.7276 32.174 10.8046 32.1191 10.8911 32.1191H20.758C20.8447 32.1191 20.9218 32.1741 20.9501 32.256L22.8235 37.686C23.0948 38.4723 23.835 39 24.6668 39C26.0173 39 26.9589 37.6605 26.5016 36.3898L18.8518 15.1323C18.3914 13.853 17.178 13 15.8184 13C14.4588 13 13.2453 13.853 12.7849 15.1323L5.13567 36.3883C4.67814 37.6597 5.62027 39 6.9715 39ZM12.1743 28.8184C12.0347 28.8184 11.9367 28.6808 11.9823 28.5489L15.69 17.8186C15.7098 17.7612 15.7639 17.7227 15.8247 17.7227C15.8855 17.7227 15.9396 17.7612 15.9594 17.8186L19.6671 28.5489C19.7127 28.6808 19.6147 28.8184 19.4752 28.8184H12.1743Z"></path>
                  <path d="M36.5657 39.4316C39.7903 39.4316 41.6057 37.7939 42.3293 36.334H42.4817V37.1465C42.4817 38.1702 43.3115 39 44.3352 39C45.3589 39 46.1887 38.1702 46.1887 37.1465V26.0508C46.1887 20.376 41.72 19.2461 38.6223 19.2461C35.7821 19.2461 33.1227 20.1669 31.5309 22.3792C30.8836 23.2788 31.5244 24.4392 32.6051 24.6854C33.4902 24.887 34.3631 24.3759 34.9919 23.7212C35.7675 22.9135 36.9681 22.3184 38.6731 22.3184C41.1233 22.3184 42.3801 23.6006 42.3801 25.8096V25.8984C42.3801 27.2822 40.9582 27.2568 37.4543 27.6631C33.76 28.0947 29.9768 29.0596 29.9768 33.4902C29.9768 37.3242 32.8586 39.4316 36.5657 39.4316ZM37.3909 36.3848C35.2454 36.3848 33.6965 35.4199 33.6965 33.541C33.6965 31.5098 35.4993 30.7861 37.6956 30.4941C38.927 30.3291 41.8469 29.999 42.3928 29.4531V31.9668C42.3928 34.2773 40.552 36.3848 37.3909 36.3848Z"></path>
                </svg>
                <span>Text</span>
              </div>
              <div
                onClick={() =>
                  plotStore.addNewBlockToPrezaPage({
                    type: ELEMENT_TYPES.IMAGE,
                  })
                }
              >
                <svg
                  viewBox="0 0 52 52"
                  style={{ width: "32px", height: "32px" }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 9.75C9.41015 9.75 6.5 12.6601 6.5 16.25V35.75C6.5 39.3399 9.41015 42.25 13 42.25H39C42.5899 42.25 45.5 39.3399 45.5 35.75V16.25C45.5 12.6601 42.5899 9.75 39 9.75H13ZM13 13C11.2051 13 9.75 14.4551 9.75 16.25V35.75L14.7982 30.7018C15.6371 29.8629 16.9582 29.7499 17.9274 30.434L22.8959 33.9412C23.0488 34.0491 23.2555 34.0387 23.3967 33.9159L30.7845 27.4917C31.7507 26.6516 33.2022 26.7022 34.1075 27.6075L42.25 35.75V16.25C42.25 14.4551 40.7949 13 39 13H13ZM17.875 24.375C19.6699 24.375 21.125 22.9199 21.125 21.125C21.125 19.3301 19.6699 17.875 17.875 17.875C16.0801 17.875 14.625 19.3301 14.625 21.125C14.625 22.9199 16.0801 24.375 17.875 24.375Z"
                  ></path>
                </svg>
                <span>Image/GIF</span>
              </div>
              <div
                onClick={() =>
                  plotStore.addNewBlockToPrezaPage({
                    type: ELEMENT_TYPES.IFRAME,
                  })
                }
              >
                <svg
                  viewBox="0 0 52 52"
                  style={{ width: "32px", height: "32px" }}
                >
                  <path d="M15.3697 29.7344C13.4659 31.6382 13.4659 34.7249 15.3697 36.6287C17.2736 38.5325 20.3602 38.5325 22.264 36.6287L27.4348 31.458C29.3386 29.5542 29.3386 26.4675 27.4348 24.5637C26.8002 23.9291 26.8002 22.9002 27.4348 22.2656C28.0694 21.631 29.0983 21.631 29.7329 22.2656C32.9059 25.4386 32.9059 30.5831 29.7329 33.7561L24.5621 38.9268C21.3891 42.0998 16.2447 42.0998 13.0717 38.9268C9.89864 35.7538 9.89864 30.6093 13.0717 27.4363L14.7952 25.7128C15.4298 25.0782 16.4587 25.0782 17.0933 25.7128C17.7279 26.3474 17.7279 27.3763 17.0933 28.0109L15.3697 29.7344Z"></path>
                  <path d="M36.6271 22.2656C38.531 20.3618 38.531 17.2751 36.6271 15.3713C34.7233 13.4675 31.6367 13.4675 29.7329 15.3713L24.5621 20.542C22.6583 22.4459 22.6583 25.5325 24.5621 27.4363C25.1967 28.0709 25.1967 29.0998 24.5621 29.7344C23.9275 30.369 22.8986 30.369 22.264 29.7344C19.091 26.5614 19.091 21.417 22.264 18.2439L27.4348 13.0732C30.6078 9.90022 35.7522 9.90022 38.9252 13.0732C42.0983 16.2462 42.0983 21.3907 38.9252 24.5637L37.2017 26.2873C36.5671 26.9219 35.5382 26.9219 34.9036 26.2873C34.269 25.6527 34.269 24.6238 34.9036 23.9892L36.6271 22.2656Z"></path>
                </svg>
                <span>Iframe</span>
              </div>
              <SlidesElementGiphy />
              {/* <div
                onClick={() =>
                  plotStore.addNewBlockToPrezaPage({
                    type: ELEMENT_TYPES.IFRAME,
                    data: {
                      url: `https://witeboard.com/cake-${nanoid()}`,
                    },
                  })
                }
              >
                <svg
                  viewBox="0 0 52 52"
                  style={{ width: "32px", height: "32px" }}
                >
                  <path d="M15.3697 29.7344C13.4659 31.6382 13.4659 34.7249 15.3697 36.6287C17.2736 38.5325 20.3602 38.5325 22.264 36.6287L27.4348 31.458C29.3386 29.5542 29.3386 26.4675 27.4348 24.5637C26.8002 23.9291 26.8002 22.9002 27.4348 22.2656C28.0694 21.631 29.0983 21.631 29.7329 22.2656C32.9059 25.4386 32.9059 30.5831 29.7329 33.7561L24.5621 38.9268C21.3891 42.0998 16.2447 42.0998 13.0717 38.9268C9.89864 35.7538 9.89864 30.6093 13.0717 27.4363L14.7952 25.7128C15.4298 25.0782 16.4587 25.0782 17.0933 25.7128C17.7279 26.3474 17.7279 27.3763 17.0933 28.0109L15.3697 29.7344Z"></path>
                  <path d="M36.6271 22.2656C38.531 20.3618 38.531 17.2751 36.6271 15.3713C34.7233 13.4675 31.6367 13.4675 29.7329 15.3713L24.5621 20.542C22.6583 22.4459 22.6583 25.5325 24.5621 27.4363C25.1967 28.0709 25.1967 29.0998 24.5621 29.7344C23.9275 30.369 22.8986 30.369 22.264 29.7344C19.091 26.5614 19.091 21.417 22.264 18.2439L27.4348 13.0732C30.6078 9.90022 35.7522 9.90022 38.9252 13.0732C42.0983 16.2462 42.0983 21.3907 38.9252 24.5637L37.2017 26.2873C36.5671 26.9219 35.5382 26.9219 34.9036 26.2873C34.269 25.6527 34.269 24.6238 34.9036 23.9892L36.6271 22.2656Z"></path>
                </svg>
                <span>Whiteboard</span>
              </div> */}
              {/* <div
                onClick={() =>
                  plotStore.addNewBlockToPrezaPage({
                    type: ELEMENT_TYPES.SCREENSHARE,
                  })
                }
              >
                <svg
                  style={{ marginBottom: "6px" }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="desktop"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  height="20"
                >
                  <path
                    fill="currentColor"
                    d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
                  ></path>
                </svg>
                <span>Screenshare</span>
              </div> */}
            </div>
          </div>
        }
      >
        <div className={styles.trigger}>
          <svg className="remix">
            <use xlinkHref={`${remixiconUrl}#ri-add-circle-fill`} />
          </svg>
        </div>
      </Popover>
    </>
  );
};

export default observer(AddElement);
