import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import styles from "./reaction.module.scss";
import { useReward, emoji } from "react-rewards";

const Reaction = () => {
  const {
    dataStore: { elementsStore },
  } = useStore();

  const emojis = Array.from(elementsStore.emojis.values());
  const [currentState, setCurrentState] = useState(0);
  const { reward: confettiReward } = useReward("confettiReward", "confetti");

  useEffect(() => {
    if (emojis.length) {
      console.log(emojis[emojis.length - 1]);
      if (emojis.length > currentState) {
        emoji(
          document.getElementById("emojiReward")!.firstElementChild!,
          () => {},
          {
            emoji: [emojis[emojis.length - 1]],
            elementSize: 50,
            spread: 70,
            decay: 0.96,
          }
        );
        confettiReward();
        setCurrentState(emojis.length);
      }
    }
  }, [confettiReward, currentState, setCurrentState, emojis]);

  useEffect(() => {
    setCurrentState(0);
  }, [elementsStore.currentRoomId, setCurrentState]);

  return (
    <div className={styles.container}>
      <span id="confettiReward" />
      <div id={"emojiReward"}>
        <div />
      </div>
    </div>
  );
};

export default observer(Reaction);
