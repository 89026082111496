import React, { Fragment, useState } from "react";
import { useStore } from "../../hooks/use-store";
import { observer } from "mobx-react";
import {
  Avatar,
  Button,
  Spin,
  Modal,
  Checkbox,
  Tooltip,
  MenuProps,
  Dropdown,
} from "antd";
// @ts-ignore
import Pluralize from "react-pluralize";
// import UpgradeBanner from "./upgradeBanner/UpgradeBanner";
import EditRoomDrawer from "./editRoom/EditRoom";
import { Room } from "../../stores/data/space-store/space-store";
import AddRoom from "./addRoom/AddRoom";
import { getInitials } from "../../helpers/helper";
import SidebarParticipant from "./participant/SidebarParticipant";
import styles from "./sidebar.module.scss";
import NavigationBanner from "./navigation/NavigationBanner";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import EditSpaceDrawer from "./editSpace/EditSpace";
import InviteToRoom from "./inviteToRoom";
import EditAccount from "./editAccount/EditAccount";
import SidebarSpaceMenu from "./sidebarSpaceMenu/SidebarSpaceMenu";
import EditSpaceBillingDrawer from "./editSpaceBilling/EditSpaceBilling";

const Sidebar = () => {
  // const {t} = useTranslation();
  const {
    dataStore: { spaceStore },
    uiStore: { authStore },
  } = useStore();

  const participants = Array.from(spaceStore.participantMap.values());

  const [editRoomDrawerData, setEditRoomDrawerData] = useState<null | Room>(
    null
  );
  const [editAccountDrawerOpen, setEditAccountDrawerOpen] = useState(false);
  const [inviteRoomDrawerData, setInviteRoomDrawerData] = useState<null | Room>(
    null
  );

  const [addRoomDrawerOpen, setAddRoomDrawerOpen] = useState(false);
  // const [showChatTab, setShowChatTab] = useState(false);

  // useEffect(() => {
  //   if (chatStore.chatVisible && chatStore.unreadCounter) {
  //     chatStore.readMessages();
  //   }
  // }, [chatStore, chatStore.unreadCounter, chatStore.chatVisible]);

  const [spaceEditModalOpen, setSpaceEditModalOpen] = useState<null | {
    name?: string;
    logo?: string;
  }>(null);

  const [spaceBillingDrawerOpen, setSpaceBillingDrawerOpen] = useState<null | {
    name?: string;
    logo?: string;
  }>(null);

  const changeRoom = (roomId: string) => {
    if (spaceStore.currentRoom?.receptionRoom) {
      spaceStore.changeRoom(roomId);
    } else {
      const showConfirm = window.localStorage.getItem("confirmOnRoomChange");
      if (!showConfirm) {
        Modal.confirm({
          onOk: () => spaceStore.changeRoom(roomId),
          onCancel: () => {},
          okText: "Onward!",
          cancelText: "Stay here",
          title: "Hey, you are about to leave this room.",
          content: (
            <div>
              <p>
                People in this room will no longer see or hear you, until you
                return.
              </p>
              <Checkbox
                onChange={() => {
                  window.localStorage.setItem("confirmOnRoomChange", "true");
                }}
              >
                Don't ask again
              </Checkbox>
            </div>
          ),
        });
      } else {
        spaceStore.changeRoom(roomId);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.collapse}
        onClick={() => spaceStore.collapseSideBar()}
      >
        {spaceStore.spaceSidebarCollapsed ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            >
              <rect width="16" height="16" x="4" y="4" rx="2" />
              <path d="M15 4v16M9 10l2 2l-2 2" />
            </g>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            >
              <rect width="16" height="16" x="4" y="4" rx="2" />
              <path d="M9 4v16m6-10l-2 2l2 2" />
            </g>
          </svg>
        )}
        {spaceStore.spaceSidebarCollapsed ? null : <span>Collapse</span>}
      </div>

      {spaceStore.spaceSidebarCollapsed ? null : (
        <>
          <SidebarSpaceMenu
            onEditAccountClick={() => {
              setEditAccountDrawerOpen(true);
            }}
            onEditSpaceClick={() => {
              setSpaceEditModalOpen({
                name: spaceStore.spaceName,
                logo: spaceStore.logo ? spaceStore.logo : undefined,
              });
            }}
            onEditSpaceBillingClick={() => {
              setSpaceBillingDrawerOpen({});
            }}
          />

          {/*<div className={styles.flex}>*/}
          {/*    <div className={styles.chatContainer}>*/}
          {/*        <Badge*/}
          {/*            count={chatStore.unreadCounter}*/}
          {/*            showZero={false}*/}
          {/*            offset={[-18, 4]}*/}
          {/*        >*/}
          {/*            <div*/}
          {/*                onClick={() => {*/}
          {/*                    chatStore.chatVisible ? chatStore.hide() : chatStore.show()*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <span>{chatStore.chatVisible ? "Hide" : "Show"} room chat</span>*/}
          {/*                <svg*/}
          {/*                    xmlns="http://www.w3.org/2000/svg"*/}
          {/*                    aria-hidden="true"*/}
          {/*                    role="img"*/}
          {/*                    width="1em"*/}
          {/*                    height="1em"*/}
          {/*                    preserveAspectRatio="xMidYMid meet"*/}
          {/*                    viewBox="0 0 20 20"*/}
          {/*                >*/}
          {/*                    <path*/}
          {/*                        fill="white"*/}
          {/*                        d="M5.8 12.2V6H2C.9 6 0 6.9 0 8v6c0 1.1.9 2 2 2h1v3l3-3h5c1.1 0 2-.9 2-2v-1.82a.943.943 0 0 1-.2.021h-7V12.2zM18 1H9c-1.1 0-2 .9-2 2v8h7l3 3v-3h1c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2z"*/}
          {/*                    />*/}
          {/*                </svg>*/}
          {/*            </div>*/}
          {/*        </Badge>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/* <HeaderNavigation /> */}

          <Spin
            spinning={!spaceStore.canJoinOtherRoom}
            indicator={<div />}
            delay={100}
            tip={
              "Max capacity reached, please contact administrator to update subscription"
            }
            className={styles.error}
            wrapperClassName={
              !spaceStore.canJoinOtherRoom ? styles.errorWrapper : ""
            }
          >
            <div className={styles.slideContainer}>
              <div
                style={
                  spaceStore.currentRoom?.receptionRoom
                    ? { background: "#5D21FF" }
                    : {}
                }
                onClick={() => {
                  spaceStore.canJoinOtherRoom
                    ? changeRoom(spaceStore.getReceptionRoomId())
                    : console.log("noo");
                }}
              >
                <div className={styles.header}>
                  <svg className="remix">
                    <use xlinkHref={`${remixiconUrl}#ri-slideshow-line`} />
                  </svg>
                  Project slides:
                </div>

                <Avatar.Group
                  maxCount={8}
                  maxStyle={{
                    color: "white",
                    backgroundColor: "#100b18",
                    borderColor: "#100b18",
                  }}
                >
                  {participants
                    .filter((p) => p.roomId === spaceStore.getReceptionRoomId())
                    .map((rp) => (
                      <Fragment key={rp.identityId}>
                        <Tooltip title={rp.username} arrowPointAtCenter>
                          <Avatar
                            //shape="square"
                            style={{
                              border: "none",
                              backgroundColor: "#272b31",
                              cursor: "pointer",
                            }}
                            size={30}
                            src={rp.avatar}
                          >
                            {getInitials(rp.username)}
                          </Avatar>
                        </Tooltip>
                      </Fragment>
                    ))}
                </Avatar.Group>
              </div>
            </div>
            <div className={styles.roomsContainer}>
              <div className={styles.roomsHeader}>
                <svg className="remix">
                  <use xlinkHref={`${remixiconUrl}#ri-headphone-line`} />
                </svg>
                Meeting rooms:
              </div>
              {spaceStore.rooms
                .filter((itm) => !itm.hidden)
                .filter((itm) => !itm.receptionRoom)
                .map((itm) => {
                  const roomParticipants = participants.filter(
                    (p) => p.roomId === itm.roomId
                  );

                  let action: MenuProps["onClick"];
                  action = (e) => {
                    e.domEvent.stopPropagation();
                    if (e.key === "1") {
                      setEditRoomDrawerData({ ...itm });
                    } else if (e.key === "2") {
                      setInviteRoomDrawerData({ ...itm });
                    }
                  };
                  const menuProps = {
                    items: [
                      {
                        label: "Room settings",
                        key: "1",
                      },
                      {
                        label: "Public links & Invites",
                        key: "2",
                      },
                    ],
                    onClick: action,
                  };
                  return (
                    <div
                      key={itm.roomId}
                      className={styles.roomItem}
                      onClick={() => {
                        if (itm.roomId !== spaceStore.currentRoom?.roomId) {
                          spaceStore.canJoinOtherRoom
                            ? changeRoom(itm.roomId)
                            : console.log("noo");
                        }
                      }}
                      style={
                        spaceStore.currentRoom?.roomId === itm.roomId
                          ? { background: "#5D21FF" }
                          : {}
                      }
                    >
                      <div className={styles.roomNameContainer}>
                        <div className={styles.roomName}>{itm.name}</div>
                        <div className={styles.roomCounter}>
                          <span>
                            <Pluralize
                              singular={"user"}
                              count={roomParticipants.length}
                              zero={"no users"}
                            />
                          </span>
                        </div>
                      </div>
                      <div className={styles.roomParticipantContainer}>
                        <Avatar.Group
                          maxCount={8}
                          maxStyle={{
                            color: "white",
                            backgroundColor: "#100b18",
                            borderColor: "#100b18",
                          }}
                        >
                          {roomParticipants.map((rp) => (
                            <Fragment key={rp.identityId}>
                              <Tooltip title={rp.username} arrowPointAtCenter>
                                <Avatar
                                  //shape="square"

                                  style={{
                                    border: "none",
                                    backgroundColor: "#272b31",
                                    cursor: "pointer",
                                  }}
                                  size={30}
                                  src={rp.avatar}
                                >
                                  {getInitials(rp.username)}
                                </Avatar>
                              </Tooltip>
                            </Fragment>
                          ))}
                        </Avatar.Group>
                      </div>
                      {spaceStore.isAdmin ? (
                        <Dropdown menu={menuProps} placement="bottom">
                          <Button
                            className={styles.roomEditButton}
                            type="primary"
                          >
                            <span>action</span>
                          </Button>
                        </Dropdown>
                      ) : null}
                    </div>
                  );
                })}

              <div className={styles.addRoomContainer}>
                {spaceStore.isAdmin ? (
                  <Button
                    className={styles.addRoomButton}
                    type="primary"
                    onClick={(e) => {
                      if (spaceStore.canAddMoreRooms) {
                        setAddRoomDrawerOpen(true);
                      }
                      e.stopPropagation();
                    }}
                  >
                    {spaceStore.canAddMoreRooms
                      ? "Add room"
                      : "Upgrade to add more"}
                  </Button>
                ) : null}
              </div>
            </div>
          </Spin>
          <div className={styles.roomsContainer}>
            <div className={styles.roomsHeader}>
              <svg className="remix">
                <use xlinkHref={`${remixiconUrl}#ri-user-star-line`} />
              </svg>
              Online users:
            </div>

            {participants.map((itm) => (
              <Fragment key={itm.identityId}>
                <SidebarParticipant
                  onClick={() => {
                    if (itm.identityId === authStore.currentUser?.identityId) {
                      setEditAccountDrawerOpen(true);
                    }
                  }}
                  avatar={itm.avatar}
                  username={itm.username}
                  identityId={itm.identityId}
                  role={itm.role}
                />
              </Fragment>
            ))}

            <EditAccount
              visible={editAccountDrawerOpen}
              onClose={() => setEditAccountDrawerOpen(false)}
            />
            {/* <InviteToSpaceModal /> */}
          </div>
          <div className={styles.flexGrow} />
          {/*<UpgradeBanner />*/}
          <NavigationBanner />
          {/*{authStore.currentUser?.email ? null : <UpgradeBanner />}*/}
        </>
      )}
      <EditRoomDrawer
        visible={!!editRoomDrawerData}
        onClose={() => setEditRoomDrawerData(null)}
        room={editRoomDrawerData}
      />
      <InviteToRoom
        visible={!!inviteRoomDrawerData}
        onClose={() => setInviteRoomDrawerData(null)}
        room={inviteRoomDrawerData}
      />

      <AddRoom
        visible={addRoomDrawerOpen}
        onClose={() => setAddRoomDrawerOpen(false)}
      />

      <EditSpaceDrawer
        visible={!!spaceEditModalOpen}
        onClose={() => setSpaceEditModalOpen(null)}
        initialData={spaceEditModalOpen!}
        submit={async (model) => {
          await spaceStore.updateSpace(model);
          setSpaceEditModalOpen(null);
        }}
      />
      <EditSpaceBillingDrawer
        visible={!!spaceBillingDrawerOpen}
        onClose={() => setSpaceBillingDrawerOpen(null)}
      />
    </div>
  );
};

export default observer(Sidebar);
