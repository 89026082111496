import { observer } from "mobx-react";
import styles from "./huddleParticipant.module.scss";
import { NOTIFICATION_SERVICE } from "../../../../components/notifications/NotificationService";
import CopyToClipboard from "react-copy-to-clipboard";

const InviteHuddleParticipant = (props: {
  link: string;
  password?: string | null;
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.participant} style={{ background: "#181b1d" }}>
        <div
          style={{
            padding: "14px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <span className={styles.shareLink}>
            Share link to start a meeting
          </span>
          <CopyToClipboard
            text={props.link}
            onCopy={() => {
              NOTIFICATION_SERVICE.successNotification("Copied!");
            }}
          >
            <div className={styles.inviteLink}>
              <svg
                className={styles.icon}
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="m12 1.875c-2.83046 0-5.125 2.29454-5.125 5.125v3h-.875c-2.20914 0-4 1.7909-4 4v6c0 2.2091 1.79086 4 4 4h12c2.2091 0 4-1.7909 4-4v-6c0-2.2091-1.7909-4-4-4h-.875v-3c0-2.83046-2.2945-5.125-5.125-5.125zm2.875 8.125v-3c0-1.58782-1.2872-2.875-2.875-2.875s-2.875 1.28718-2.875 2.875v3zm-3.875 5c0-.5523.4477-1 1-1s1 .4477 1 1v4c0 .5523-.4477 1-1 1s-1-.4477-1-1z"
                ></path>
              </svg>
              <span className={styles.link}>{props.link}</span>
              <span className={styles.button}>Copy</span>
            </div>
          </CopyToClipboard>
          <div className={styles.lockMessage}>
            {props.password ? (
              <>
                <b>This room is locked</b>. You also need to send a password:{" "}
                <b>{props.password}</b>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(InviteHuddleParticipant);
