import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RouterLoader from "./routerLoader/RouterLoader";
import { useStore } from "../../hooks/use-store";
import { observer } from "mobx-react";
import Meeting from "../workspace/Meeting";
import CreateAccountForm from "./createAccountForm/CreateAccountForm";
import SpaceWelcomeScreen from "./spaceWelcomeScreen/SpaceWelcomeScreen";
import SpaceLogin from "./spaceLogin/SpaceLogin";

enum STATES {
  LOADING_SCREEN,
  MEETING_SCREEN,
  MEETING_NOT_FOUND,
  CREATE_ACCOUNT_SCREEN,
  WELCOME_SCREEN,
  PASSWORD_SCREEN,
}

const MeetingRouter = () => {
  const {
    dataStore: { appStore },
    uiStore: { authStore },
  } = useStore();
  const { roomId } = useParams<{ roomId: string }>();

  const [step, setStep] = useState(STATES.LOADING_SCREEN);
  const [meetingState, setMeetingState] = useState<
    | undefined
    | {
        room: any;
        space: any;
      }
  >();

  const prepareAccountAndRedirectToMeeting = useCallback(async () => {
    if (!authStore.isLoggedIn) {
      setStep(STATES.CREATE_ACCOUNT_SCREEN);
    } else {
      setStep(STATES.MEETING_SCREEN);
    }
  }, [authStore.isLoggedIn]);

  const getCall = useCallback(
    async (id: string) => {
      const res = await appStore.getMeetingByRoomId(id);
      if (res) {
        setMeetingState({
          room: res.room,
          space: res.space,
        });

        console.log(res);
        if (res.room?.welcomePageEnabled) {
          setStep(STATES.WELCOME_SCREEN);
        } else if (res.room?.password) {
          setStep(STATES.PASSWORD_SCREEN);
        } else {
          prepareAccountAndRedirectToMeeting();
        }
      } else {
        setStep(STATES.MEETING_NOT_FOUND);
      }
    },
    [appStore, prepareAccountAndRedirectToMeeting]
  );

  useEffect(() => {
    getCall(roomId);
  }, [roomId, getCall]);

  if (step === STATES.MEETING_NOT_FOUND) {
    return <div>Meeting not found</div>;
  }

  if (step === STATES.LOADING_SCREEN) {
    return <RouterLoader />;
  } else if (step === STATES.MEETING_SCREEN) {
    return (
      <Meeting
        spaceId={meetingState!.space?.spaceId}
        roomId={meetingState!.room?.roomId}
      />
    );
  } else if (step === STATES.WELCOME_SCREEN) {
    return (
      <SpaceWelcomeScreen
        spaceName={meetingState!.room?.name}
        onSuccess={() => {
          prepareAccountAndRedirectToMeeting();
        }}
        welcomePage={{
          enabled: meetingState!.room?.welcomePageEnabled,
          date: meetingState!.room?.welcomePageDate,
          name: meetingState!.room?.welcomePageName,
          description: meetingState!.room?.welcomePageDescription,
          background: meetingState!.room?.welcomePageBackground,
        }}
        logo={meetingState!.space?.logo}
        spaceId={meetingState!.space?.spaceId}
      />
    );
  } else if (step === STATES.PASSWORD_SCREEN) {
    return (
      <SpaceLogin
        spaceName={meetingState!.space?.name}
        onSuccess={() => {
          if (meetingState!.room?.welcomePageEnabled) {
            setStep(STATES.WELCOME_SCREEN);
          } else {
            prepareAccountAndRedirectToMeeting();
          }
        }}
        roomId={meetingState!.room?.roomId}
        background={"/backgrounds/default/bg3.jpg"}
      />
    );
  } else if (step === STATES.CREATE_ACCOUNT_SCREEN) {
    return <CreateAccountForm />;
  }
  return null;
};

export default observer(MeetingRouter);
