import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import SpaceContainer from "../../features/space/SpaceContainer";
import MainRouter from "./main-router";
// import Account from "../private/account/AccountContainer";

const LoggedIn = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // @ts-ignore
      console.log(window.gtag);
      // @ts-ignore
      window.gtag &&
        window.gtag("event", "conversion", {
          send_to: "AW-11378315379/eY1ECIDCru0YEPOgzbEq",
        });
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Switch>
      <Route path="/space">
        <SpaceContainer />
      </Route>
      <Route exact path="/">
        <MainRouter />
      </Route>
      <Redirect to="/space" />
    </Switch>
  );
};

export default LoggedIn;
