import styles from "./index.module.scss";
import { Controller } from "react-hook-form";
import React from "react";
import { DatePicker as AntdDatePicker } from "antd";

const getError = (type: string) => {
  if (type === "required") {
    return "This field is required";
  } else {
    return "Check this field";
  }
};
const DatePicker = (props: any) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>{props.label}</label>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field }) => (
          <AntdDatePicker
            showTime={props.showTime}
            size={"large"}
            showSecond={false}
            picker={props.picker}
            placeholder={props.placeholder}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      {props.error ? (
        <span className={styles.error}>{getError(props.error.type)}</span>
      ) : null}
    </div>
  );
};

export default DatePicker;
