import React, { useEffect } from "react";
import { Layout } from "antd";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import ReconnectIndicator from "../../../features/space/reconnectIndicator/ReconnectIndicator";
import Preza from "../../../features/workspace/preza/Preza";
import MobileSlides from "../../../features/workspace/preza/MobileSlides";
import { useWindowSize } from "../../../helpers/helper";

interface Props {
  plotId: string;
  username: string;
  avatar: string | null;
  identityId: string;
  onCloseButtonClicked?: () => void;
  noConnect: boolean;
}

const Plot = (props: Props) => {
  const {
    dataStore: { plotStore },
  } = useStore();

  const { width } = useWindowSize();

  useEffect(() => {
    if (!props.noConnect) {
      plotStore.connect({
        plotId: props.plotId,
        username: props.username,
        avatar: props.avatar,
        identityId: props.identityId,
      });
    }

    return () => {
      (async () => await plotStore.disconnect())();
    };
  }, [
    props.avatar,
    props.plotId,
    props.username,
    props.identityId,
    plotStore,
    props.noConnect,
  ]);

  return (
    <Layout>
      <ReconnectIndicator />
      {width ? (
        width > 500 ? (
          <Preza onCloseButtonClicked={props.onCloseButtonClicked} />
        ) : (
          <MobileSlides />
        )
      ) : null}
    </Layout>
  );
};

export default observer(Plot);
