import React from "react";
import styles from "./reconnectIndicator.module.scss";
import { useStore } from "../../../hooks/use-store";
import { CONNECTION_STATUS } from "../../../types";
import { Spin } from "antd";
import { observer } from "mobx-react";

const ReconnectIndicator = () => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  return (
    <div
      className={
        spaceStore.connectionStatus === CONNECTION_STATUS.NORMAL
          ? styles.hidden
          : styles.visible
      }
    >
      <div className={styles.block}>
        <span>
          Whoops! You've got disconnected because of your network connection.
        </span>
        <br />
        {spaceStore.connectionStatus !== CONNECTION_STATUS.RECONNECTING ? (
          <span>
            Reconnecting... &nbsp;&nbsp;
            <Spin />
          </span>
        ) : (
          <span>
            Connection refused... &nbsp;&nbsp;
            <Spin />
          </span>
        )}

        <br />
        <span>If reconnect goes on too long - try:</span>
        <ul>
          <li>Checking the network cables, modem and router</li>
          <li>Reconnecting to Wi-Fi</li>
          <li>Check connection speed: speedtest.net</li>
        </ul>
      </div>
    </div>
  );
};

export default observer(ReconnectIndicator);
