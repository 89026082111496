import React from "react";
import styles from "./addElement.module.scss";
import figma from "./imgs/figma.png";

const Instructions = () => {
  return (
    <div className={styles.instructionsContainer}>
      <span>Copy link to file from browser url</span>
      <img src={figma} alt={""} />
    </div>
  );
};

export default Instructions;
