import React from "react";
import styles from "./kickedPage.module.scss";
import Logo from "../../../components/Logo";

const KickedPage = () => {
  return (
    <div className={styles.visible}>
      <div className={styles.block}>
        <span>You have been disconnected</span>
        <Logo />
      </div>
    </div>
  );
};

export default KickedPage;
