import CrudService from "../core/crud-service";

export default class RoomsService extends CrudService {
  static route = "api/v1/rooms";

  async listBySpaceId(spaceId: string) {
    return await this.httpService.get<any>(`${this.path}/space/${spaceId}`);
  }

  async getMeetingByRoomId(roomId: string) {
    return await this.httpService.get<any>(
      `${this.path}/getMeetingByRoomId/${roomId}`
    );
  }

  async listPexelsBackgrounds(query: string) {
    return await this.httpService.post<any, any>(`${this.path}/pexels-search`, {
      query,
    });
  }

  async validatePassword(model: { roomId: string; password: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/${model.roomId}/validate-password`,
      {
        password: model.password,
      }
    );
  }
}
