import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import FormInput from "../../../components/form/input";
import FormButton from "../../../components/form/button";
import styles from "./spaceLogin.module.scss";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";

interface FormProps {
  password: string;
}

interface Props {
  roomId: string | undefined;
  background: string | undefined;
  spaceName: string | undefined;
  onSuccess: () => void;
}
const Login = ({ roomId, onSuccess, background, spaceName }: Props) => {
  const {
    dataStore: { appStore },
  } = useStore();

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      password: "",
    });
  }, [reset]);

  const validate = async (data: FormProps) => {
    const validation = await appStore.validatePassword({
      roomId: roomId!,
      password: data.password,
    });

    if (validation.valid) {
      onSuccess();
    } else {
      NOTIFICATION_SERVICE.errorNotification("Incorrect Password");
    }
    console.log(validation);
  };

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={styles.card}>
        <Typography.Title level={3}>
          Enter password to enter Cake meeting
        </Typography.Title>

        <FormInput
          type="password"
          rules={{
            required: true,
          }}
          error={errors.password}
          control={control}
          placeholder={"Meeting password"}
          label={"Password"}
          name={"password"}
        />
        <br />
        <FormButton loading={isSubmitting} onClick={handleSubmit(validate)}>
          Enter space
        </FormButton>
      </div>

      <div className={styles.note}>
        <span>Forgot password? Contact room administrator</span>
      </div>
    </div>
  );
};

export default Login;
