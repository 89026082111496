import { makeObservable, observable, toJS } from "mobx";
import { ROLE } from "../../../types";

export interface ParticipantProps {
  username: string;
  avatar: string;
  identityId: string;
  roomId: string;
  role: ROLE;
}

export default class Participant {
  id: number;

  @observable text: string = "";

  @observable username: string;
  @observable avatar: string;
  @observable roomId: string;
  @observable identityId: string;
  @observable role: ROLE;

  constructor(props: ParticipantProps) {
    Object.assign(this, props);
    makeObservable(this);
  }

  // @action
  // toggleIsDone = () => {
  //     this.isDone = !this.isDone;
  // };

  // @action
  // updatePosition = (x: number, y: number) => {
  //     console.log('POS CHANGES')
  //     runInAction(() => {
  //         this.posX = x;
  //         this.posY = y;
  //     })
  //
  // };

  // updateText = async (text: string) => {
  //     const {apiFactory} = getEnv();
  //
  //     await apiFactory.todosService.update(this.id, this.toJSON());
  //
  //     runInAction(() => {
  //         this.text = text;
  //     });
  // };

  toJSON() {
    return toJS(this);
  }
}
