/* eslint-disable jsx-a11y/anchor-is-valid */
import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import { Button, Image, Popover } from "antd";
import styles from "./sidebarSpaceMenu.module.scss";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import test from "../../../pages/private/account/spaces/test.gif";
import { TARIFFS } from "../../../helpers/enums";

interface ISidebarSpaceMenu {
  onEditSpaceClick: () => void;
  onEditSpaceBillingClick: () => void;
  onEditAccountClick: () => void;
}
const SidebarSpaceMenu = ({
  onEditSpaceClick,
  onEditSpaceBillingClick,
  onEditAccountClick,
}: ISidebarSpaceMenu) => {
  // const {t} = useTranslation();
  const {
    dataStore: { spaceStore },
    uiStore: { authStore },
  } = useStore();

  return (
    <div className={styles.menuContainer}>
      <Popover
        placement="rightBottom"
        popupVisible={true}
        showArrow={false}
        // visible={true
        content={
          <div className={styles.innerContainer}>
            <div>
              <div>
                <div className={styles.popoverLogo}>
                  <Image
                    preview={false}
                    height={48}
                    width={48}
                    style={{
                      borderRadius: "6px",
                      objectFit: "contain",
                    }}
                    alt="space logo"
                    src={spaceStore.logo || test}
                  />
                  <span>{spaceStore.spaceName}</span>
                </div>
                <div className={styles.divider}></div>
                {spaceStore.plan === TARIFFS.FREE ? (
                  <>
                    <span className={styles.subHeader}>
                      Your video time per meeting or video call session is
                      limited to 60 minutes. To unlock unlimited video time,{" "}
                      <a
                        // href="https://309sov53iwj.typeform.com/to/Ti5MBxu8"
                        // target={"_blank"}
                        // rel="noreferrer"
                        onClick={onEditSpaceBillingClick}
                      >
                        click here
                      </a>
                      .
                    </span>

                    <div className={styles.divider}></div>
                    <a
                      className={styles.special}
                      href="https://buy.stripe.com/fZedR6aeUfizfGUcMM"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Limited Offer: grab the LTD deal now
                    </a>
                  </>
                ) : (
                  <span className={styles.subHeader}>
                    Your workspace is currently on <b>Cake's Unlimited Plan</b>.
                  </span>
                )}
              </div>
              <div className={styles.divider}></div>
            </div>
            <Button
              type="text"
              onClick={() => {
                onEditSpaceClick();
              }}
            >
              Space name & logo
            </Button>
            <Button
              type="text"
              onClick={() => {
                onEditSpaceBillingClick();
              }}
            >
              Upgrade space
            </Button>
            <Button
              type="text"
              onClick={() => {
                onEditAccountClick();
              }}
            >
              Profile settings
            </Button>
            <div className={styles.divider}></div>
            <Button
              type="text"
              onClick={() => {
                window.location.href = "https://feedback.cake.work/";
              }}
            >
              Suggest a feature
            </Button>
            <Button
              type="text"
              onClick={() => {
                window.location.href =
                  "https://www.youtube.com/watch?v=krx04M-r9mQ";
              }}
            >
              Onboarding video
            </Button>
            <div className={styles.divider}></div>
            <Button
              type="text"
              onClick={() => {
                authStore.logout();
                window.location.href = "/login";
              }}
            >
              Sign out of Cake
            </Button>
          </div>
        }
      >
        <div>
          <div className={styles.spaceLogo}>
            <Image
              preview={false}
              height={100}
              width={100}
              style={{
                borderRadius: "6px",
                objectFit: "contain",
              }}
              alt="space logo"
              src={spaceStore.logo || test}
            />
            <span>
              {spaceStore.spaceName}
              <svg className="remix">
                <use xlinkHref={`${remixiconUrl}#ri-arrow-down-s-line`} />
              </svg>
            </span>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default observer(SidebarSpaceMenu);
