import React from "react";
import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import styles from "./spaceMenu.module.scss";
import { Popconfirm, Popover } from "antd";
import { track } from "@amplitude/analytics-browser";
// import KeynoteMenu from "./KeynotesMenu";
const SpaceMenu = () => {
  const {
    dataStore: { elementsStore, spaceStore },
  } = useStore();

  return (
    <div className={styles.menu}>
      {elementsStore.followActivated ? (
        <div className={styles.indicator} />
      ) : null}
      <div>
        {/* <Popover
          placement="topLeft"
          popupVisible={true}
          content={
            <div className={styles.popover}>
              <span>
                User cursors helps you to identify on what the coworker looking
                at the moment
              </span>
            </div>
          }
        >
          <div
            className={styles.container}
            onClick={() => elementsStore.changeShowParticipantCursors()}
          >
            <span>
              {elementsStore.showParticipantCursors ? "Hide" : "Show"} user
              cursors
            </span>
          </div>
        </Popover>
        <Popover
          placement="topLeft"
          popupVisible={true}
          content={
            <div className={styles.popover}>
              <span>Hide the board, you will see only video avatars</span>
            </div>
          }
        >
          <div
            className={styles.container}
            onClick={() => {
              spaceStore.updateRoom({
                ...spaceStore.currentRoom!,
                focusModeEnabled: true,
              });
              track("start_conversation_mode");
            }}
          >
            <span>Start conversation mode</span>
          </div>
        </Popover> */}

        {/* <Popover
          placement="topLeft"
          popupVisible={true}
          style={{ width: "371px" }}
          content={
            <div className={styles.keynotePopover}>
              <KeynoteMenu />
            </div>
          }
        >
          <div className={styles.container}>
            <span>Cake Slides</span>
          </div>
        </Popover> */}
        {elementsStore.followActivated ? (
          elementsStore.selfFollow ? (
            <div className={styles.presenter}>
              <span onClick={() => elementsStore.stopFollow()}>
                Disable presenter mode
              </span>
            </div>
          ) : (
            <div className={styles.presenter}>
              <span>
                Presenter:{" "}
                {
                  spaceStore.participantMap.get(elementsStore.followIdentityId!)
                    ?.username
                }
              </span>
            </div>
          )
        ) : (
          <Popconfirm
            title={"All users in room will follow your screen"}
            onConfirm={() => {
              elementsStore.startFollowMode();
              track("start_follow_mode");
            }}
            okText="Present"
            cancelText="Cancel"
          >
            <Popover
              placement="topLeft"
              popupVisible={true}
              content={
                <div className={styles.popover}>
                  <span>
                    <b>You will broadcast your screen.</b>
                  </span>
                  <br />
                  <span>
                    You room members will follow your mouse position while the
                    presenter mode is active.
                  </span>
                </div>
              }
            >
              <div className={styles.container}>
                <span>Start presenter mode</span>
              </div>
            </Popover>
          </Popconfirm>
        )}
      </div>
    </div>
  );
};

export default observer(SpaceMenu);
