import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import styles from "./preza.module.scss";
import PrezaPage from "./PrezaPage";
import { Avatar } from "antd";
import React, { Fragment } from "react";
import ParticipantAvatar from "./participantAvatar/ParticipantAvatar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

const Preza = () => {
  const {
    dataStore: { plotStore },
  } = useStore();
  const participants = Array.from(plotStore.participantMap.values());

  return (
    <div>
      <div className={styles.nameContainer}>
        <div className={styles.left}>
          {plotStore.logo ? (
            <img src={plotStore.logo} alt="logo" className={styles.logo} />
          ) : null}
          <span>{plotStore.name}</span>
        </div>
        <div className={styles.right}>
          <Avatar.Group maxCount={8}>
            {participants.map((rp) => (
              <Fragment key={rp.identityId}>
                <ParticipantAvatar
                  size={22}
                  username={rp.username}
                  identityId={rp.identityId}
                  avatar={rp.avatar}
                />
              </Fragment>
            ))}
          </Avatar.Group>
        </div>
      </div>
      <div className={styles.allContainer}>
        <Swiper
          pagination={{
            type: "progressbar",
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {plotStore.pagesOrder.map((itm) => (
            <React.Fragment key={itm}>
              <SwiperSlide className={styles.swiperSlide}>
                {plotStore.background ? (
                  <div
                    style={{
                      backgroundImage: `url('${plotStore.background}')`,
                    }}
                    className={styles.backgroundImage}
                  ></div>
                ) : null}
                <PrezaPage
                  layout={plotStore.pages.get(itm)!}
                  onChangeLayout={(lay) => {}}
                />
              </SwiperSlide>
            </React.Fragment>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default observer(Preza);
