import React, { useEffect } from "react";
import { Modal } from "antd";
import FormButton from "../../../../components/form/button";
import FormInput from "../../../../components/form/input";
import { useForm } from "react-hook-form";

interface Props {
  visible: boolean;
  loading: boolean;
  handleAdd: (url: string) => void;
  handleCancel: () => void;
}

interface FormProps {
  name: string;
}

const SpaceSlidesCreateNewModal = ({
  visible,
  handleAdd,
  handleCancel,
  loading,
}: Props) => {
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      name: "",
    });
  }, [reset, visible]);

  const submit = async (data: FormProps) => {
    handleAdd(data.name);
  };

  return (
    <Modal
      title={"Slide name"}
      open={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <br />
      <FormInput
        type="text"
        rules={{
          required: true,
        }}
        error={errors.name}
        control={control}
        placeholder={"🍰 How to bake cake?"}
        label={""}
        name={"name"}
      />
      <br />
      <FormButton
        loading={isSubmitting || loading}
        onClick={handleSubmit(submit)}
      >
        Create
      </FormButton>
    </Modal>
  );
};

export default SpaceSlidesCreateNewModal;
