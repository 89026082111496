import { action, computed, makeObservable, observable } from "mobx";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import User from "../../data/models/user";
import { StorageItems } from "../../../services/core/storage-service";
import { init, track } from "@amplitude/analytics-browser";
import { ROLE } from "../../../types";

export enum AuthState {
  Authenticating = "authenticating",
  LoggedIn = "loggedIn",
  LoggedOut = "loggedOut",
}

class AuthStore {
  @observable
  currentUser: User | null;
  @observable
  authState: AuthState = AuthState.LoggedOut;

  constructor() {
    makeObservable(this);
  }

  async loginIfTokenExists() {
    const token = getEnv().apiFactory.authService.getCredentials(
      StorageItems.TOKEN
    );

    if (token) {
      await this.loginWithToken();
    }
  }

  @action
  logout = () => {
    const { apiFactory } = getEnv();

    apiFactory.authService.clearCredentials();

    this.setUser(null);
    this.setAuthState(AuthState.LoggedOut);
    apiFactory.saveToken("");
  };

  @computed
  get user() {
    return this.currentUser;
  }

  @computed
  get isLoggedIn() {
    return this.authState === AuthState.LoggedIn;
  }

  @action
  setAuthState(authState: AuthState) {
    this.authState = authState;
  }

  @action
  setUser(user: any) {
    if (!user) {
      this.currentUser = null;
    } else {
      this.currentUser = new User(user);
      const key = getEnv().envConfig.amplitude_key;

      init(key, user.email);
      track("dashboard_loaded");
    }
  }

  private async afterLoginEvents(token: string, user: any) {
    const { apiFactory } = getEnv();

    if (token) {
      apiFactory.saveToken(token);
      apiFactory.authService.storeCredentials(token);
    }

    this.setAuthState(AuthState.LoggedIn);
    this.setUser(user);
  }

  loginWithToken = async () => {
    try {
      this.setAuthState(AuthState.Authenticating);

      const result = await getEnv().apiFactory.authService.getProfile();

      console.log(result, "result");
      await this.afterLoginEvents(result.token, result);
    } catch (e) {
      this.setAuthState(AuthState.LoggedOut);
      console.log(e, "Failed to login from token");
    }
  };

  syncUser = async () => {
    try {
      const result = await getEnv().apiFactory.authService.getProfile();
      await this.afterLoginEvents(result.token, result);
    } catch (e) {
      this.setAuthState(AuthState.LoggedOut);
      console.log(e, "Failed to login from token");
    }
  };

  login = async (email: string, password: string): Promise<any> => {
    try {
      this.setAuthState(AuthState.Authenticating);

      const result = await getEnv().apiFactory.authService.login(
        email,
        password
      );
      await this.afterLoginEvents(result.token, result.user);
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      this.setAuthState(AuthState.LoggedOut);
      console.log(e, "Failed to login");
    }
  };

  sendInviteToCake = async (model: { emails: string }): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.sendInvites(model);
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
    }
  };

  sendInviteToSpace = async (model: {
    emails: string;
    spaceId: string;
    date?: string;
    role: ROLE;
    rooms?: string;
  }): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.sendSpaceInvites(model);
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
    }
  };

  generateInviteLink = async (model: {
    spaceId: string;
    date?: string;
    role: ROLE;
    rooms?: string;
  }): Promise<any> => {
    try {
      return getEnv().apiFactory.authService.generateInviteLink(model);
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
    }
  };

  update = async (model: {
    avatar: string | null;
    firstName: string;
    lastName: string;
  }): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.update(
        model.avatar,
        model.firstName,
        model.lastName
      );

      this.setUser({
        ...this.currentUser,
        avatar: model.avatar,
        firstName: model.firstName,
        lastName: model.lastName,
      });
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      console.log(e, "Failed to login");
    }
  };

  register = async (model: {
    firstName: string;
    lastName: string;
    password: string;
    email?: string;
    avatar?: string;
    inviteCode?: string;
  }): Promise<{ ok: boolean }> => {
    try {
      this.setAuthState(AuthState.Authenticating);
      const result = await getEnv().apiFactory.authService.register(model);
      await this.afterLoginEvents(result.token, result.user);
      // // @ts-ignore
      // window.fbq && window.fbq("track", "CompleteRegistration");
      // @ts-ignore
      window.lintrk && window.lintrk("track", { conversion_id: 9254930 });
      return {
        ok: true,
      };
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      this.setAuthState(AuthState.LoggedOut);
      return {
        ok: false,
      };
    }
  };

  attachEmail = async (email: string): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.attachEmail(email);
      return {
        ok: true,
      };
    } catch (e) {
      return {
        ok: false,
      };
      // this.setAuthState(AuthState.LoggedOut);
      // console.log(e, "Failed to register");
    }
  };

  forgotPassword = async (email: string): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.forgotPassword(email);
      return {
        ok: true,
      };
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      return {
        ok: false,
      };
    }
  };

  resetPassword = async (password: string, hash: string): Promise<any> => {
    try {
      await getEnv().apiFactory.authService.resetPassword(password, hash);
      return {
        ok: true,
      };
    } catch (e) {
      getEnv().apiFactory.authService.parseError(e);
      return {
        ok: false,
      };
    }
  };
}

export default AuthStore;
