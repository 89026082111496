import React, { useState } from "react";
import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import { Avatar, Badge, Button, Popconfirm, Popover } from "antd";
import styles from "./sidebarParticipant.module.scss";
import { getInitials } from "../../../helpers/helper";
import { ROLE } from "../../../types";

const SidebarParticipant = (props: {
  identityId: string;
  avatar: string;
  username: string;
  role: string;
  onClick?: () => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visible, setVisible] = useState(false);

  const {
    uiStore: { authStore },
    dataStore: { spaceStore },
  } = useStore();

  const isYou = props.identityId === authStore.currentUser?.identityId;

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    if (newVisible) {
      if (
        !isYou &&
        spaceStore.role !== ROLE.GUEST &&
        spaceStore.role !== ROLE.COLLABORATOR
      ) {
        setVisible(newVisible);
      }
    }
  };

  // const isSpaceOwner = +props.identityId === +spaceStore.ownerIdentity;

  // console.log(props.identityId, spaceStore.ownerIdentity);
  const getContent = () => {
    if (props.role === ROLE.ADMIN) {
      return (
        <div className={styles.popupContainer}>
          <span>
            Admin can see all rooms, modify them and invite new team members.
          </span>
        </div>
      );
    } else if (props.role === ROLE.COLLABORATOR) {
      return (
        <div className={styles.popupContainer}>
          <span>
            You can promote this user to <b>Guest</b>
          </span>
          <Button
            onClick={() =>
              spaceStore.updateUserRole({
                identityId: props.identityId,
                role: ROLE.GUEST,
              })
            }
          >
            Demote
          </Button>
          <span>
            Guests can use chat and screensharing. Edit of content is disabled
            for them
          </span>
        </div>
      );
    } else {
      return (
        <div className={styles.popupContainer}>
          <span>
            You can promote this user to <b>Collaborator</b>
          </span>
          <Button
            onClick={() =>
              spaceStore.updateUserRole({
                identityId: props.identityId,
                role: ROLE.COLLABORATOR,
              })
            }
          >
            Promote
          </Button>
          <span>
            Collaborator can edit slides, add text, images and integrations
          </span>
        </div>
      );
    }
  };
  return (
    <Popover
      content={
        <div className={styles.popover}>
          {getContent()}
          <span className={styles.close} onClick={hide}>
            Close
          </span>
          {spaceStore.role === ROLE.ADMIN ? (
            <Popconfirm
              title={"Kick user outside of room?"}
              onConfirm={() => {
                spaceStore.kick({ identityId: props.identityId });
              }}
              okText="Yes"
              cancelText="No"
            >
              <span className={styles.close}>Kick user</span>
            </Popconfirm>
          ) : null}
        </div>
      }
      title={""}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement={"right"}
    >
      <div
        key={props.identityId}
        className={styles.user}
        onClick={props.onClick}
      >
        <Badge dot color={"green"} offset={[-1, 26]}>
          <Avatar
            src={props.avatar}
            size={30}
            //shape="square"
            className={styles.userAvatar}
          >
            {getInitials(props.username)}
          </Avatar>
        </Badge>
        <span className={styles.name}>
          {props.username}
          {isYou ? " (you)" : ""}
        </span>
        <span className={styles.role}>{props.role.toLowerCase()}</span>
      </div>
    </Popover>
  );
};

export default observer(SidebarParticipant);
