import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import FormInput from "../../../components/form/input";
import FormButton from "../../../components/form/button";
import styles from "./forgotPassword.module.scss";
import { Link } from "react-router-dom";
import Logo from "../../../components/Logo";
import { useTranslation } from "react-i18next";

interface FormProps {
  email: string;
}

const ForgotPassword = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const [success, showSuccess] = useState(false);
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      email: "",
    });
  }, [reset]);

  const login = async (data: FormProps) => {
    const res = await authStore.forgotPassword(data.email);

    if (res.ok) {
      showSuccess(true);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.card}>
        <Typography.Title level={2}>
          {t("forget.header", {
            defaultValue: "Forgot your password? No worries!",
          })}
        </Typography.Title>
        {success ? (
          <span>
            {t("forget.sent", {
              defaultValue: "Email has been sent. Please check your inbox",
            })}
          </span>
        ) : (
          <>
            <FormInput
              type="text"
              rules={{
                required: true,
              }}
              error={errors.email}
              control={control}
              label={t("forget.email", { defaultValue: "Enter email" })}
              name={"email"}
            />
            <span>
              {t("forget.will.send", {
                defaultValue: "We will send the instructions to your email",
              })}
            </span>
            <br />
            <br />
            <FormButton loading={isSubmitting} onClick={handleSubmit(login)}>
              {t("forget.send.email", {
                defaultValue: "Send magic link to email",
              })}
            </FormButton>
          </>
        )}
      </div>
      <div className={styles.note}>
        <span>
          <span>
            {t("forget.already.have", {
              defaultValue: "Already have an account? Login",
            })}
            &nbsp;
            <Link to={"/login"}>
              {t("forget.here", { defaultValue: "here" })}
            </Link>
          </span>
        </span>
      </div>
    </div>
  );
};

export default ForgotPassword;
