import HttpService from "./http-service";
import AuthService from "../api/auth-service";
import TodosService from "../api/todos-service";
import SpacesService from "../api/spaces-service";
import RelatedSpacesService from "../api/related-spaces-service";
import InvitesService from "../api/invites-service";
import RoomsService from "../api/rooms-service";
import SpaceJoinsService from "../api/space-joins-service";
import BillingService from "../api/billing-service";
import PlotService from "../api/plot-service";

const ApiList = [
  {
    variableName: "todosService",
    classEntity: TodosService,
    route: TodosService.route,
  },
  {
    variableName: "authService",
    classEntity: AuthService,
    route: AuthService.route,
  },
  {
    variableName: "spacesService",
    classEntity: SpacesService,
    route: SpacesService.route,
  },
  {
    variableName: "invitesService",
    classEntity: InvitesService,
    route: InvitesService.route,
  },
  {
    variableName: "roomsService",
    classEntity: RoomsService,
    route: RoomsService.route,
  },
  {
    variableName: "spaceJoinsService",
    classEntity: SpaceJoinsService,
    route: SpaceJoinsService.route,
  },
  {
    variableName: "relatedSpacesService",
    classEntity: RelatedSpacesService,
    route: RelatedSpacesService.route,
  },
  {
    variableName: "billingService",
    classEntity: BillingService,
    route: BillingService.route,
  },
  {
    variableName: "plotService",
    classEntity: PlotService,
    route: PlotService.route,
  },
];

export interface ApiFactoryParams {
  httpService: HttpService;
}

// declaration merging with class
interface ApiFactory {
  relatedSpacesService: RelatedSpacesService;
  spacesService: SpacesService;
  invitesService: InvitesService;
  roomsService: RoomsService;
  spaceJoinsService: SpaceJoinsService;
  plotService: PlotService;
  todosService: TodosService;
  authService: AuthService;
  billingService: BillingService;
}

class ApiFactory {
  private httpService: HttpService;

  [index: string]: any;

  constructor({ httpService }: ApiFactoryParams) {
    this.httpService = httpService;

    ApiList.forEach((api) => {
      this[api.variableName] = new api.classEntity({
        httpService,
        route: api.route,
      });
    });
  }

  saveToken(token: string) {
    this.httpService.setToken(token);
  }
}

export default ApiFactory;
