import React, { Fragment, useState } from "react";
import MenuItem from "./MenuItem";

import styles from "./menuBar.module.scss";
import ReactDOM from "react-dom";
import PasteLinkModal from "./PasteLinkModal";
import { Button, MenuProps, Modal } from "antd";
import { Dropdown, message } from "antd";
import { useRequest } from "ahooks";
import { NOTIFICATION_SERVICE } from "../../../../components/notifications/NotificationService";

interface IMenuBar {
  editor: any;
  credits: number;
  email?: string | null;
  gptRewriteFunc: (model: {
    text: string;
    tone: string;
  }) => Promise<{ text: string; error?: string }>;
}

const MenuBar = ({ editor, gptRewriteFunc, credits, email }: IMenuBar) => {
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);

  const { loading, runAsync } = useRequest(
    async (model: { text: string; tone: string }) => gptRewriteFunc(model),
    {
      loadingDelay: 100,
      manual: true,
      onSuccess: (model) => {
        if (model.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        }
      },
    }
  );

  const onClick: MenuProps["onClick"] = async ({ key }) => {
    if (credits < 1) {
      return setUpgradeModalVisible(true);
    }
    const { from, to, empty } = editor.state.selection;

    if (empty) {
      NOTIFICATION_SERVICE.errorNotification("Hightlight text first");
      return null;
    }

    const text = editor.state.doc.textBetween(from, to, " ");
    console.log(editor.state.doc.textBetween(from, to, " "));

    const res = await runAsync({ text, tone: key });

    if (res.text) {
      return editor
        .chain()
        .focus()
        .command(({ tr }: any) => {
          // manipulate the transaction
          tr.insertText(res.text);
          return true;
        })
        .run();
    }

    message.info(`Click on item ${key}`);
  };

  const menuItems: MenuProps["items"] = [
    {
      label: "Rewrite",
      key: "rewrite",
    },
    {
      label: "Reduce",
      key: "reduce",
    },
    {
      label: "Extend",
      key: "extend",
    },
    {
      label: "Change tone",
      key: "4",
      children: [
        {
          key: "neutral",
          label: "Neutral",
        },
        {
          key: "friendly",
          label: "Friendly",
        },
        {
          key: "excited",
          label: "Excited",
        },
        {
          key: "intellectual",
          label: "Intellectual",
        },
        {
          key: "business",
          label: "Business",
        },
      ],
    },
  ];

  const items = [
    {
      icon: "bold",
      title: "Bold",
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive("bold"),
    },
    {
      icon: "italic",
      title: "Italic",
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive("italic"),
    },
    {
      icon: "strikethrough",
      title: "Strike",
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive("strike"),
    },
    {
      icon: "code-view",
      title: "Code",
      action: () => editor.chain().focus().toggleCode().run(),
      isActive: () => editor.isActive("code"),
    },
    {
      icon: "mark-pen-line",
      title: "Highlight",
      action: () => editor.chain().focus().toggleHighlight().run(),
      isActive: () => editor.isActive("highlight"),
    },
    {
      type: "divider",
    },
    {
      icon: "h-1",
      title: "Heading 1",
      action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive("heading", { level: 1 }),
    },
    {
      icon: "h-2",
      title: "Heading 2",
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive("heading", { level: 2 }),
    },
    {
      icon: "h-3",
      title: "Heading 3",
      action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      isActive: () => editor.isActive("heading", { level: 3 }),
    },
    {
      icon: "h-4",
      title: "Heading 4",
      action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
      isActive: () => editor.isActive("heading", { level: 4 }),
    },
    {
      icon: "paragraph",
      title: "Paragraph",
      action: () => editor.chain().focus().setParagraph().run(),
      isActive: () => editor.isActive("paragraph"),
    },
    {
      icon: "link",
      title: "Link",
      action: () => {
        setLinkModalOpen(true);
      },
      isActive: () => editor.isActive("link"),
    },
    {
      icon: "list-unordered",
      title: "Bullet List",
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive("bulletList"),
    },
    {
      icon: "list-ordered",
      title: "Ordered List",
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive("orderedList"),
    },
    {
      icon: "list-check-2",
      title: "Task List",
      action: () => editor.chain().focus().toggleTaskList().run(),
      isActive: () => editor.isActive("taskList"),
    },
    {
      icon: "code-box-line",
      title: "Code Block",
      action: () => editor.chain().focus().toggleCodeBlock().run(),
      isActive: () => editor.isActive("codeBlock"),
    },
    {
      type: "divider",
    },
    {
      icon: "double-quotes-l",
      title: "Blockquote",
      action: () => editor.chain().focus().toggleBlockquote().run(),
      isActive: () => editor.isActive("blockquote"),
    },
    {
      icon: "separator",
      title: "Horizontal Rule",
      action: () => editor.chain().focus().setHorizontalRule().run(),
    },
    {
      type: "divider",
    },
    {
      icon: "text-wrap",
      title: "Hard Break",
      action: () => editor.chain().focus().setHardBreak().run(),
    },
    {
      icon: "align-left",
      title: "Left",
      action: () => editor.chain().focus().setTextAlign("left").run(),
      isActive: () => editor.isActive({ textAlign: "left" }),
    },
    {
      icon: "align-center",
      title: "Center",
      action: () => editor.chain().focus().setTextAlign("center").run(),
      isActive: () => editor.isActive({ textAlign: "center" }),
    },
    {
      icon: "align-right",
      title: "Right",
      action: () => editor.chain().focus().setTextAlign("right").run(),
      isActive: () => editor.isActive({ textAlign: "right" }),
    },

    // <button
    //     onClick={() => editor.chain().focus().setTextAlign('left').run()}
    //     className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
    //   >
    //     left
    //   </button>
    //   <button
    //     onClick={() => editor.chain().focus().setTextAlign('center').run()}
    //     className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
    //   >
    //     center
    //   </button>
    //   <button
    //     onClick={() => editor.chain().focus().setTextAlign('right').run()}
    //     className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
    //   >
    //     right
    //   </button>
    //   <button
    //     onClick={() => editor.chain().focus().setTextAlign('justify').run()}
    //     className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
    //   >
    //     justify
    //   </button>
    //   <button onClick={() => editor.chain().focus().unsetTextAlign().run()}>unsetTextAlign</button>

    // {
    //     icon: 'format-clear',
    //     title: 'Clear Format',
    //     action: () => editor.chain().focus().clearNodes().unsetAllMarks()
    //         .run(),
    // },
    // {
    //     type: 'divider',
    // },
    // {
    //     icon: 'arrow-go-back-line',
    //     title: 'Undo',
    //     action: () => editor.chain().focus().undo().run(),
    // },
    // {
    //     icon: 'arrow-go-forward-line',
    //     title: 'Redo',
    //     action: () => editor.chain().focus().redo().run(),
    // },
  ];
  return ReactDOM.createPortal(
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.type === "divider" ? (
            <div className={styles.divider} />
          ) : (
            <MenuItem {...item} />
          )}
        </Fragment>
      ))}

      <Dropdown menu={{ items: menuItems, onClick }}>
        <Button
          size="middle"
          loading={loading}
          onClick={(e) => e.preventDefault()}
          type="text"
        >
          🪄 AI Edit
        </Button>
      </Dropdown>

      <PasteLinkModal
        visible={linkModalOpen}
        handleAdd={(url) => {
          editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: url })
            .run();
          setLinkModalOpen(false);
        }}
        handleCancel={() => setLinkModalOpen(false)}
      />

      <Modal
        open={upgradeModalVisible}
        onCancel={() => setUpgradeModalVisible(false)}
        footer={null}
      >
        <div>Not enough credits to perform this operation.</div>
        <div>
          Please{" "}
          <a
            href="https://309sov53iwj.typeform.com/to/Ti5MBxu8"
            target={"_blank"}
            rel="noreferrer"
          >
            contact us
          </a>{" "}
          and we will provide you a personal quote
        </div>
      </Modal>
    </div>,
    // @ts-ignore
    document.getElementById("text-controls")
  );
};

export default MenuBar;
