import { observer } from "mobx-react";
import { useStore } from "../../../../hooks/use-store";
import styles from "./preview.module.scss";
import PreviewPrezaPage from "../PreviewPrezaPage";
import { Avatar, Popconfirm } from "antd";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import ParticipantAvatar from "../participantAvatar/ParticipantAvatar";
import NewSlideModal from "./NewSlideModal";
import { Fragment } from "react";

// https://react-resizable-panels.vercel.app/examples/nested
const Preview = () => {
  const {
    dataStore: { plotStore },
  } = useStore();

  const participants = Array.from(plotStore.participantMap.values());

  const page = plotStore.activePageId;

  return (
    <div className={styles.container}>
      <div>
        {plotStore.pagesOrder.map((itm, key) => {
          const previewPage = plotStore.pages.get(itm);
          if (!previewPage) {
            return null;
          }
          return (
            <div
              className={styles.page}
              key={itm}
              onClick={() => plotStore.changeActivePageId(itm)}
            >
              {itm === page ? <div className={styles.activeIndicator} /> : null}

              <div
                className={`${styles.number} ${
                  itm === page ? styles.activeNumber : ""
                }`}
              >
                {key + 1}
              </div>

              {plotStore.readonly ? null : (
                <>
                  <div
                    className={styles.upArrow}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await plotStore.moveUp(itm);
                    }}
                  >
                    <svg className="remix">
                      <use xlinkHref={`${remixiconUrl}#ri-arrow-up-line`} />
                    </svg>
                  </div>
                  <div
                    className={styles.downArrow}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await plotStore.moveDown(itm);
                    }}
                  >
                    <svg className="remix">
                      <use xlinkHref={`${remixiconUrl}#ri-arrow-down-line`} />
                    </svg>
                  </div>
                </>
              )}

              <div className={styles.previewWrapper}>
                {plotStore.readonly ? null : (
                  <Popconfirm
                    title={"This action cannot be undone"}
                    onConfirm={() => {
                      plotStore.deletePrezaPage(itm);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div className={styles.deleteIcon}>
                      <span>Delete</span>
                    </div>
                  </Popconfirm>
                )}

                <div className={styles.sliderWrapper}>
                  <div>
                    <PreviewPrezaPage
                      layout={previewPage}
                      active={itm === page}
                    ></PreviewPrezaPage>
                  </div>
                </div>
              </div>
              <div className={styles.particpantContainer}>
                <Avatar.Group maxCount={8}>
                  {participants
                    .filter((p) => p.activePageId === itm)
                    .map((rp) => (
                      <Fragment key={rp.identityId}>
                        <ParticipantAvatar
                          size={28}
                          username={rp.username}
                          identityId={rp.identityId}
                          avatar={rp.avatar}
                        />
                      </Fragment>
                    ))}
                </Avatar.Group>
              </div>
            </div>
          );
        })}

        {plotStore.readonly ? null : <NewSlideModal />}
      </div>
    </div>
  );
};

export default observer(Preview);
