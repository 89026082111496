import React, { useEffect } from "react";
import { Modal } from "antd";
import FormButton from "../../../components/form/button";
import FormInput from "../../../components/form/input";
import { useForm } from "react-hook-form";

interface Props {
  name?: string;
  instructions?: any;
  visible: boolean;
  handleAdd: (url: string) => void;
  handleCancel: () => void;
}

interface FormProps {
  url: string;
}

const AddElement = ({
  visible,
  handleAdd,
  handleCancel,
  name,
  instructions,
}: Props) => {
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      url: "",
    });
  }, [reset]);

  const submit = async (data: FormProps) => {
    await handleAdd(data.url);
  };

  return (
    <Modal title={name} open={visible} onCancel={handleCancel} footer={null}>
      {instructions}
      <FormInput
        type="text"
        rules={{
          required: true,
        }}
        error={errors.url}
        control={control}
        label={"Link"}
        name={"url"}
      />
      <br />
      <br />
      <FormButton loading={isSubmitting} onClick={handleSubmit(submit)}>
        Add
      </FormButton>
    </Modal>
  );
};

export default AddElement;
