import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import styles from "./preza.module.scss";
import GridLayout from "react-grid-layout";
import TextElement from "./text/Text";
import Image from "./image/ImageCell";
import { ELEMENT_TYPES, OBJECT_FIT } from "../../../types";
import Iframe from "./Iframe";
import Screenshare from "./Screenshare";

interface Props {
  selectedCell: string | GridLayout.Layout;
  itm: string;
}

const Cell = ({ selectedCell, itm }: Props) => {
  const {
    dataStore: { plotStore },
  } = useStore();

  const element = plotStore.elementsMap.get(itm);

  if (!element) {
    return null;
  }
  return (
    <div className={styles.cellWrapper}>
      {element?.type === ELEMENT_TYPES.IMAGE ? (
        <Image
          readonly={plotStore.readonly}
          isActive={selectedCell === itm}
          onChangeCb={(model: {
            cdnUrl: string;
            objectFit: OBJECT_FIT;
            background: string;
          }) => {
            plotStore.updatePrezaElementData(itm, {
              height: 0,
              width: 0,
              ...model,
            });
          }}
          // @ts-ignore
          cdnUrl={element.data.cdnUrl}
          // @ts-ignore
          objectFit={element.data.objectFit}
          // @ts-ignore
          background={element.data.background}
        />
      ) : null}
      {element?.type === ELEMENT_TYPES.TEXT ? (
        <TextElement
          readonly={plotStore.readonly}
          isActive={selectedCell === itm}
          onChangeText={(text, timestamp, align) => {
            plotStore.updatePrezaElementData(itm, {
              content: text,
              timestamp,
              align,
            });
          }}
          // @ts-ignore
          timestamp={element.data.timestamp}
          // @ts-ignore
          content={element.data.content}
          // @ts-ignore
          align={element.data.align}
        />
      ) : null}
      {element?.type === ELEMENT_TYPES.IFRAME ? (
        <Iframe
          readonly={plotStore.readonly}
          onChangeCb={(url: string) => {
            plotStore.updatePrezaElementData(itm, {
              url: url,
            });
          }}
          // @ts-ignore
          url={element.data.url}
        />
      ) : null}
      {element?.type === ELEMENT_TYPES.SCREENSHARE ? <Screenshare /> : null}
    </div>
  );
};

export default observer(Cell);
