import React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { PERIOD, PRICES, TARIFFS } from "./enums";

export const getInitials = (username?: string | null) => {
  const u = username ? username.split(" ") : [];
  if (u.length > 1) {
    return `${u[0][0]}${u[1][0]}`;
  } else {
    if (username) {
      return username[0];
    } else {
      return "U";
    }
  }
};

export const useTraceUpdate = (props: any) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        // @ts-ignore
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
};

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        // @ts-ignore
        width: window.innerWidth,
        // @ts-ignore
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const getPlanMaxSpaces = (planId: string) => {
  if (planId === TARIFFS.FREE) {
    return 1;
  } else if (
    planId === PRICES[TARIFFS.GROWTH][PERIOD.MONTH].priceId ||
    planId === PRICES[TARIFFS.GROWTH][PERIOD.YEAR].priceId
  ) {
    return 5;
  } else if (
    planId === PRICES[TARIFFS.SCALE][PERIOD.MONTH].priceId ||
    planId === PRICES[TARIFFS.SCALE][PERIOD.YEAR].priceId
  ) {
    return 100;
  } else {
    return 100;
  }
};

export const getPlanMaxRooms = (planId: string) => {
  if (planId === TARIFFS.FREE) {
    return 5;
  } else if (
    planId === PRICES[TARIFFS.GROWTH][PERIOD.MONTH].priceId ||
    planId === PRICES[TARIFFS.GROWTH][PERIOD.YEAR].priceId
  ) {
    return 10;
  } else if (
    planId === PRICES[TARIFFS.SCALE][PERIOD.MONTH].priceId ||
    planId === PRICES[TARIFFS.SCALE][PERIOD.YEAR].priceId
  ) {
    return 100;
  } else {
    return 100;
  }
};

export const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
  const newArr = [...arr];
  var element = newArr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
};

export const getUserIdFromSession = (sessionId: string) => {
  const strs = sessionId.split("-");
  if (strs.length > 1) {
    return +strs[0];
  } else {
    return 0;
  }
};
