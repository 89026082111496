import React from "react";

const SVG = () => {
  return (
    <div>
      <svg
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        width="2500"
        height="2500"
      >
        <path d="M0 0h48v48H0z" fill="#ffd02f" />
        <path
          d="M32.708 6.4h-5.124l4.549 7.05-9.617-7.05h-5.124l4.549 9.238L12.324 6.4H7.2l4.474 11.926L7.2 41.6h5.124l9.617-24.955L17.392 41.6h5.124l9.617-27.142-4.549 27.142h5.124L42.4 11.785z"
          fill="#050038"
        />
      </svg>
    </div>
  );
};

export default SVG;
