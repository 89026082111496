import React from "react";
import { observer } from "mobx-react";

import styles from "./addElement.module.scss";

const AddElementItem = (model: {
  name: string;
  svg?: any;
  onClick: () => void;
}) => {
  return (
    <div className={styles.option} onClick={model.onClick}>
      <div className={styles.item}>
        <span>{model.name}</span>
        {model.svg}
      </div>
    </div>
  );
};

export default observer(AddElementItem);
