import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Logo from "../../../components/Logo";
import RouterLoader from "../../../features/space/routerLoader/RouterLoader";
import { useStore } from "../../../hooks/use-store";
import Plot from "./Plot";
import styles from "./plot.module.scss";
enum STATES {
  LOADING_SCREEN,
  PASSWORD_SCREEN,
  ACCESS_DENIED_SCREEN,
  PLOT_SCREEN,
  NOT_FOUND_SCREEN,
}

const PlotWrapper = () => {
  const {
    dataStore: { appStore, plotStore },
    uiStore: { authStore },
  } = useStore();

  const [step, setStep] = useState(STATES.LOADING_SCREEN);

  const { plotId } = useParams<{ plotId: string }>();

  const getData = useCallback(async () => {
    const s = await appStore.getKeynotePermission({
      plotId,
      email: authStore.currentUser?.email || null,
      password: "",
    });
    console.log(s);

    if (!s.exists) {
      setStep(STATES.NOT_FOUND_SCREEN);
      // show forbidden
    } else if (s.errors?.forbidden) {
      setStep(STATES.ACCESS_DENIED_SCREEN);
      // show forbidden
    } else if (s.errors?.invalidPassword) {
      setStep(STATES.PASSWORD_SCREEN);
      // show invalid password
    } else if (s.editable) {
      plotStore.setReadonlyMode(false);
      setStep(STATES.PLOT_SCREEN);
    } else {
      plotStore.setTestData(s.state);
      plotStore.setReadonlyMode(true);
      setStep(STATES.PLOT_SCREEN);
    }
  }, [appStore, authStore.currentUser?.email, plotId, plotStore]);

  useEffect(() => {
    getData();
  }, [getData]);

  const state = {
    username: authStore.currentUser
      ? `${authStore.currentUser!.firstName} ${authStore.currentUser!.lastName}`
      : "Anonymous",
    avatar: authStore.currentUser?.avatar,
    identityId:
      `${authStore.currentUser?.identityId}-page` || `identityId${Date.now()}`,
  };

  if (step === STATES.NOT_FOUND_SCREEN) {
    return (
      <div className={styles.visible}>
        <div className={styles.block}>
          <span>Ooops.</span>
          <br />
          <span>Presentation not found</span>
          <Logo />
        </div>
      </div>
    );
  }

  if (step === STATES.LOADING_SCREEN) {
    return <RouterLoader />;
  }

  if (step === STATES.PLOT_SCREEN) {
    return (
      <>
        <Plot
          noConnect={plotStore.readonly}
          plotId={plotId}
          username={state.username}
          avatar={state.avatar || null}
          identityId={state.identityId}
        />
        <a
          target={"_blank"}
          href="https://www.cake.work"
          rel="noreferrer"
          className={styles.badge}
        >
          Create free presentations with <b>Cake 🍰</b>
        </a>
      </>
    );
  }
  if (step === STATES.ACCESS_DENIED_SCREEN) {
    return (
      <div className={styles.visible}>
        <div className={styles.block}>
          <span>Ooops. Access denied.</span>
          <br />
          <span>Please ask administrator to share this presentation</span>
          <Logo />
        </div>
      </div>
    );
  }

  return null;
};

export default observer(PlotWrapper);
