import React from "react";
import styles from "./imageControls.module.scss";
import ReactDOM from "react-dom";
import { OBJECT_FIT } from "../../../../types";
import { Segmented } from "antd";

const ImageControls = (model: {
  objectFit: OBJECT_FIT;
  cdnUrl: string;
  background: string;
  onChangeCb: (objectFit: OBJECT_FIT) => void;
  removeImage: () => void;
  onChangeBackground: (color: string) => void;
}) => {
  return ReactDOM.createPortal(
    <div className={styles.container}>
      <div className={styles.label}>Image display</div>
      <Segmented
        className={styles.segment}
        onChange={(el) => {
          model.onChangeCb(el as OBJECT_FIT);
        }}
        value={model.objectFit}
        options={[
          {
            label: (
              <div className={styles.segmentItem}>
                <span>CONTAIN</span>
              </div>
            ),
            value: OBJECT_FIT.CONTAIN,
          },
          {
            label: (
              <div>
                <span>COVER</span>
              </div>
            ),
            value: OBJECT_FIT.COVER,
          },
        ]}
      />
      <br />
      <br />
      {model.cdnUrl ? (
        <div className={styles.label} onClick={model.removeImage}>
          Remove image
        </div>
      ) : null}
      <br />
      {model.background !== "transparent" ? (
        <div
          className={styles.label}
          onClick={() => model.onChangeBackground("transparent")}
        >
          Remove background
        </div>
      ) : (
        <div
          className={styles.label}
          onClick={() => model.onChangeBackground("theme")}
        >
          Set default background
        </div>
      )}
    </div>,
    // @ts-ignore
    document.getElementById("slides-controls")
  );
};

export default ImageControls;
