export enum ELEMENT_TYPES {
  "IFRAME" = "IFRAME",
  "LINK" = "LINK",
  "TEXT" = "TEXT",
  "SCREENSHARE" = "SCREENSHARE",
  "IMAGE" = "IMAGE",
}

export const RTC_WD = "wss://cake.livekit.cloud";

export enum TARIFFS {
  FREE = "FREE",
  GROWTH = "GROWTH",
  SCALE = "SCALE",
  EARLY_ADOPTERS = "EARLY_ADOPTERS",
  LIFETIME_PRO_VIEDEDINGUE = "LIFETIME_PRO_VIEDEDINGUE",
}

export enum PERIOD {
  MONTH,
  YEAR,
}

export const PRICES = {
  [TARIFFS.GROWTH]: {
    [PERIOD.MONTH]: {
      priceId: "GROWTH-V2-EUR-Monthly",
      price: "€9.99",
    },
    [PERIOD.YEAR]: {
      priceId: "GROWTH-V2-EUR-Yearly",
      price: "€7.99",
    },
  },
  [TARIFFS.SCALE]: {
    [PERIOD.MONTH]: {
      priceId: "SCALE-V2-EUR-Monthly",
      price: "€15.99",
    },
    [PERIOD.YEAR]: {
      priceId: "SCALE-V2-EUR-Yearly",
      price: "€12.99",
    },
  },
};
