import React from "react";
import styles from "./textControls.module.scss";
import ReactDOM from "react-dom";
import { TEXT_ALIGN } from "../../../../../types";
import { Segmented } from "antd";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";

const TextControls = (model: {
  align: TEXT_ALIGN;
  onChangeCb: (align: TEXT_ALIGN) => void;
}) => {
  return ReactDOM.createPortal(
    <div className={styles.container}>
      <div className={styles.label}>Text align</div>
      <Segmented
        className={styles.segment}
        onChange={(el) => {
          model.onChangeCb(el as TEXT_ALIGN);
        }}
        value={model.align}
        options={[
          {
            label: (
              <div className={styles.segmentItem}>
                <svg className="remix">
                  <use xlinkHref={`${remixiconUrl}#ri-align-top`} />
                </svg>
              </div>
            ),
            value: TEXT_ALIGN.START,
          },
          {
            label: (
              <div className={styles.segmentItem}>
                <svg className="remix">
                  <use xlinkHref={`${remixiconUrl}#ri-align-vertically`} />
                </svg>
              </div>
            ),
            value: TEXT_ALIGN.CENTER,
          },
          {
            label: (
              <div className={styles.segmentItem}>
                <svg className="remix">
                  <use xlinkHref={`${remixiconUrl}#ri-align-bottom`} />
                </svg>
              </div>
            ),
            value: TEXT_ALIGN.END,
          },
        ]}
      />
    </div>,
    // @ts-ignore
    document.getElementById("slides-controls")
  );
};

export default TextControls;
