import { action, makeObservable, observable } from "mobx";
import { io, Socket } from "socket.io-client";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import Element, { Pos, Size } from "./element";
import { ELEMENT_TYPES } from "../../../helpers/enums";
import {
  ElementUpdate,
  IframeElementData,
  ImageElementData,
  RoomElement,
  TextElementData,
} from "../../../types";
import { nanoid } from "nanoid";

export type Room = {
  roomId: string;
  route: string;
  component: string;
  name: string;
  capacity: number;
  data: any;
};

const positionAround = (around: Pos, minRadius: number, maxRadius: number) => {
  const r = Math.sqrt(
    Math.random() * (maxRadius ** 2 - minRadius ** 2) + minRadius ** 2
  );
  const theta = Math.random() * 2 * Math.PI;
  const x = around.x + r * Math.cos(theta);
  const y = around.y + r * Math.sin(theta);
  return { x, y };
};
export class ElementsStore {
  ready: boolean = false;
  socket: Socket;
  identityId: string;

  @observable elementsMap: Map<string, Element> = new Map();
  @observable cursorsMap: Map<
    string,
    {
      identityId: string;
      pos: Pos;
    }
  > = new Map();
  @observable currentRoomId: string;
  @observable zoomLevel = 0.3;
  @observable mapLeftX = 2000;
  @observable mapLeftY = 1300;
  @observable selfFollow = false;
  @observable followActivated = false;
  @observable followIdentityId: string | null = null;
  @observable needUpdate = Date.now();
  @observable showParticipantCursors = true;
  @observable emojis: string[] = [];

  @observable roomMeetingTimeout: string | null = null;

  @observable followX = -800;
  @observable followY = -800;

  constructor() {
    makeObservable(this);
  }

  private getSocket = () => {
    if (this.socket) {
      return this.socket;
    } else {
      return {
        emit: () => {},
        disconnect: () => {},
      };
    }
  };

  async disconnect() {
    if (this.ready) {
      this.getSocket().disconnect();
      this.ready = false;
    }
  }

  async connectSocket(model: { roomId: string; identityId: string }) {
    this.socket.on("connect", () => {
      this.ready = true;

      this.getSocket().emit(
        "join",
        {
          roomId: model.roomId,
          identityId: model.identityId,
        },
        (res: {
          elements: RoomElement[];
          // slide?: {
          //   slideElementsMap: { [key: string]: PrezaElement };
          //   slidePages: { [key: string]: GridLayout.Layout[] };
          //   slideStyle: string;
          // };
          roomMeetingTimeout: string | null;
          follow: {
            identityId: string;
            pos: Pos;
            zoom: number;
          } | null;
        }) => {
          this.roomMeetingTimeout = res.roomMeetingTimeout;
          for (const element of res.elements) {
            this.elementsMap.set(
              element.id,
              new Element({
                type: element.type,
                id: element.id,
                pos: element.pos,
                size: element.size,
                data: element.data,
              })
            );
          }

          if (res.follow) {
            this.followActivated = true;
            this.selfFollow = false;
            this.followIdentityId = res.follow.identityId;
          }
        }
      );
    });
  }

  async connect(roomId: string, identityId: string) {
    this.ready = false;
    this.currentRoomId = roomId;
    this.identityId = identityId;
    this.followActivated = false;
    this.selfFollow = false;
    this.followIdentityId = null;
    this.roomMeetingTimeout = null;
    this.emojis = [];

    this.socket = io(getEnv().envConfig.api_end_point, {
      path: "/room/socket.io",
      transports: ["websocket"],
    });

    // reconnect
    this.socket.io.on("open", async () => {
      await this.connectSocket({
        roomId: this.currentRoomId,
        identityId: this.identityId,
      });
    });

    this.socket.on("elementsUpdate", (data: ElementUpdate[]) => {
      for (const el of data) {
        const element = this.elementsMap.get(el.id);
        if (element) {
          this.elementsMap.set(
            el.id,
            new Element({
              ...element,
              ...el,
            })
          );
        }
      }
    });
    this.socket.on("elementAdded", (data: Element) => {
      this.elementsMap.set(data.id, new Element(data));
    });
    this.socket.on("elementDeleted", (data: { id: string }) => {
      this.elementsMap.delete(data.id);
    });
    this.socket.on(
      "updateRoomMeetingTimeout",
      (data: { roomMeetingTimeout: string | null }) => {
        this.roomMeetingTimeout = data.roomMeetingTimeout;
      }
    );
    this.socket.on(
      "userCursorsChanged",
      (data: { pos: Pos; identityId: string }[]) => {
        for (const ps of data) {
          this.cursorsMap.set(ps.identityId, ps);
        }
      }
    );

    this.socket.on("userCursorDeleted", (data: { identityId: string }) => {
      this.cursorsMap.delete(data.identityId);
    });
    this.socket.on(
      "followUpdated",
      (data: { identityId: string; pos: Pos; zoom: number }) => {
        this.followActivated = true;
        this.changeZoomLevel(data.zoom);
        this.followX = data.pos.x;
        this.followY = data.pos.y;
        this.followIdentityId = data.identityId;
        if (!this.selfFollow) {
          this.needUpdate = Date.now();
        }
      }
    );

    this.socket.on(
      "followStopped",
      (data: { identityId: string; pos: Pos; zoom: number }) => {
        this.followActivated = false;
        this.selfFollow = false;
        this.followIdentityId = null;
      }
    );

    this.socket.on("reactionAdded", (data: { emoji: string }) => {
      this.emojis = [...this.emojis, data.emoji];
    });
  }

  @action
  changeZoomLevel = (zoomLevel: number, needUpdate = false) => {
    this.zoomLevel = +zoomLevel.toFixed(2);
    if (needUpdate) {
      this.needUpdate = Date.now();
    }
  };

  @action
  changeMapPosition(state: {
    positionY: number;
    positionX: number;
    scale: number;
  }) {
    // this.mapLeftX = Math.max(Math.abs(state.positionX / state.scale), 0);
    // this.mapLeftY = Math.max(Math.abs(state.positionY / state.scale), 0);

    // console.log(state.positionY, state.scale);
    //console.log(state.positionX / state.scale, this.mapLeftX, this.mapLeftY);
    this.followX = state.positionX;
    this.followY = state.positionY;
    if (this.selfFollow) {
      this.getSocket().emit(
        "updateFollow",
        {
          roomId: this.currentRoomId,
          identityId: this.identityId,
          zoom: this.zoomLevel,
          pos: {
            x: state.positionX,
            y: state.positionY,
          },
        },
        () => {}
      );
    }
  }

  @action
  addElement = (elementType: ELEMENT_TYPES, data?: any) => {
    const id = nanoid();
    let element;

    const pos = positionAround(
      { x: this.mapLeftX, y: this.mapLeftY },
      300,
      600
    );

    if (elementType === ELEMENT_TYPES.TEXT) {
      element = {
        type: ELEMENT_TYPES.TEXT,
        id: id,
        pos: {
          x: pos.x,
          y: pos.y,
        },
        size: {
          w: 600,
          h: 600,
        },
        data: {
          content: "<p>Hello!</p>",
          timestamp: Date.now(),
        },
      };
    }
    if (elementType === ELEMENT_TYPES.IMAGE) {
      element = {
        type: ELEMENT_TYPES.IMAGE,
        id: id,
        pos: {
          x: pos.x,
          y: pos.y,
        },
        size: {
          w: data.width,
          h: data.height,
        },
        data: {
          cdnUrl: data.cdnUrl,
          width: data.width,
          height: data.height,
        },
      };
    }
    if (elementType === ELEMENT_TYPES.IFRAME) {
      element = {
        type: ELEMENT_TYPES.IFRAME,
        id: id,
        pos: {
          x: pos.x,
          y: pos.y,
        },
        size: {
          w: 600,
          h: 600,
        },
        data: {
          url: data.url,
        },
      };
    }
    if (elementType === ELEMENT_TYPES.LINK) {
      element = {
        type: ELEMENT_TYPES.LINK,
        id: id,
        pos: {
          x: pos.x,
          y: pos.y,
        },
        size: {
          w: 600,
          h: 100,
        },
        data: {
          url: data.url,
        },
      };
    }

    // @ts-ignore
    // this.elementsMap.set(id, new Element(element))
    this.socket.emit(
      "addElement",
      {
        roomId: this.currentRoomId,
        element,
      },
      () => {}
    );
  };

  @action
  deleteElement = (id: string) => {
    this.getSocket().emit(
      "deleteElement",
      {
        roomId: this.currentRoomId,
        elementId: id,
      },
      () => {}
    );
  };

  @action
  changeRoom = async (roomId: string) => {
    this.selfFollow = false;
    this.followActivated = false;
    this.emojis = [];
    this.roomMeetingTimeout = null;
    this.cursorsMap = new Map();
    this.elementsMap = new Map();

    this.getSocket().emit(
      "changeRoom",
      {
        fromRoomId: this.currentRoomId,
        toRoomId: roomId,
      },
      (res: {
        elements: RoomElement[];
        // slide?: {
        //   slideElementsMap: { [key: string]: PrezaElement };
        //   slidePages: { [key: string]: GridLayout.Layout[] };
        //   slideStyle: string;
        // };
        roomMeetingTimeout: string | null;
        follow: {
          identityId: string;
          pos: Pos;
          zoom: number;
        } | null;
      }) => {
        this.roomMeetingTimeout = res.roomMeetingTimeout;
        if (res.follow) {
          this.followActivated = true;
          this.selfFollow = false;
          this.followIdentityId = res.follow.identityId;
        }
        for (const element of res.elements) {
          this.elementsMap.set(
            element.id,
            new Element({
              type: element.type,
              id: element.id,
              pos: element.pos,
              size: element.size,
              data: element.data,
            })
          );
        }
      }
    );

    this.currentRoomId = roomId;
  };

  @action
  updateElementPos = async (id: string, pos: Pos) => {
    const element = this.elementsMap.get(id);
    if (element) {
      this.elementsMap.set(
        id,
        new Element({
          ...element,
          pos,
        })
      );

      this.getSocket().emit(
        "patchElement",
        {
          roomId: this.currentRoomId,
          update: {
            id,
            pos,
          },
        },
        () => {}
      );
    }
  };

  @action
  updateElementSizeAndPos = async (id: string, size: Size, pos: Pos) => {
    const element = this.elementsMap.get(id);
    if (element) {
      this.getSocket().emit(
        "patchElement",
        {
          roomId: this.currentRoomId,
          update: {
            id,
            size,
            pos,
          },
        },
        () => {}
      );

      this.elementsMap.set(
        id,
        new Element({
          ...element,
          size,
          pos,
        })
      );
    }
  };

  @action
  updateElementData = async (
    id: string,
    data: ImageElementData | TextElementData | IframeElementData
  ) => {
    const element = this.elementsMap.get(id);
    if (element) {
      this.getSocket().emit(
        "patchElement",
        {
          roomId: this.currentRoomId,
          update: {
            id,
            data,
          },
        },
        () => {}
      );

      // this.elementsMap.set(
      //     id,
      //     new Element({
      //         ...element,
      //         data,
      //     })
      // );
    }
  };

  @action
  changeCursorPosition = async (pos: Pos, identityId: string) => {
    this.getSocket().emit(
      "updateUserCursorPosition",
      {
        roomId: this.currentRoomId,
        identityId: identityId,
        pos,
      },
      () => {}
    );
  };

  @action
  cursorLeave = async (identityId: string) => {
    this.getSocket().emit(
      "deleteUserCursorPosition",
      {
        roomId: this.currentRoomId,
        identityId: identityId,
      },
      () => {
        this.cursorsMap.delete(identityId);
      }
    );
  };

  @action
  startFollowMode = async () => {
    console.log(this.identityId, this.identityId);
    this.getSocket().emit(
      "addFollow",
      {
        roomId: this.currentRoomId,
        identityId: this.identityId,
        zoom: this.zoomLevel,
        pos: {
          x: this.followX,
          y: this.followY,
        },
      },
      (res: { status: string }) => {
        console.log("res", res);
        if (res.status === "ok") {
          this.selfFollow = true;
          this.followActivated = true;
        } else {
          // show error message
        }
      }
    );
  };

  @action
  stopFollow = async () => {
    this.getSocket().emit(
      "stopFollow",
      {
        roomId: this.currentRoomId,
      },
      (res: { status: string }) => {
        console.log("res", res);
        if (res.status === "ok") {
          this.selfFollow = true;
          this.followActivated = true;
        } else {
          // show error message
        }
      }
    );
  };

  @action
  addReaction = (emoji: string) => {
    this.getSocket().emit(
      "addReaction",
      {
        roomId: this.currentRoomId,
        emoji,
      },
      () => {}
    );
  };

  @action
  changeShowParticipantCursors = async () => {
    this.showParticipantCursors = !this.showParticipantCursors;
  };
}
