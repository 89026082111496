import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import Cursor from "./Cursor";

const Cursors = () => {
  const {
    dataStore: { spaceStore, elementsStore },
  } = useStore();

  if (!elementsStore.showParticipantCursors) {
    return null;
  }

  const cursors = Array.from(elementsStore.cursorsMap.values());
  const otherCursors = cursors.filter(
    (itm) => itm.identityId !== spaceStore.identity
  );

  //console.log(otherCursors);
  return (
    <>
      {otherCursors.map((itm) => (
        <Fragment key={itm.identityId}>
          <Cursor
            x={itm.pos.x}
            y={itm.pos.y}
            color={"orange"}
            zoomLevel={elementsStore.zoomLevel}
            name={spaceStore.participantMap.get(itm.identityId)?.username}
          />
        </Fragment>
      ))}
    </>
  );
};

export default observer(Cursors);
