import React, { useState, useEffect } from "react";
import { createLocalTracks, Room, setLogLevel } from "livekit-client";
import { LiveKitRoom } from "@livekit/react-components";
import StageRenderer from "./StageRenderer";
// import Sider from "antd/es/layout/Sider"

type Props = {
  waitBeforeShow?: number;
  url: string;
  token: string;
};
const Stage = ({ waitBeforeShow = 10, url, token }: Props) => {
  console.log("rerender");
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(false);
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow, token]);

  return isShown ? (
    <LiveKitRoom
      roomOptions={{
        adaptiveStream: true,
        // videoCaptureDefaults: {
        //   resolution: VideoPresets.h1440.resolution,
        // },
      }}
      url={url}
      token={token}
      stageRenderer={(props) => <StageRenderer {...props} />}
      onConnected={(room) => handleConnected(room)}
    />
  ) : null;
};

async function handleConnected(room: Room) {
  if (process.env.NODE_ENV === "development") {
    setLogLevel("debug");
  }
  try {
    console.log("connected to room", room);
    let enableCamera = true;
    let enableMic = true;
    const localStorageEnableCam = window.localStorage.getItem("enableCam");
    const localStorageEnableMic = window.localStorage.getItem("enableMic");
    if (window.localStorage) {
      enableCamera = localStorageEnableCam
        ? localStorageEnableCam === "true"
        : true;

      enableMic = localStorageEnableMic
        ? localStorageEnableMic === "true"
        : true;
    }

    const localStorageAudioDeviceId =
      window.localStorage.getItem("audioDeviceId");
    const localStorageVideoDeviceId =
      window.localStorage.getItem("videoDeviceId");
    const devices = await navigator.mediaDevices.enumerateDevices();

    const audioDevice = devices.find(
      (itm) => itm.deviceId === localStorageAudioDeviceId
    );
    const videoDevice = devices.find(
      (itm) => itm.deviceId === localStorageVideoDeviceId
    );

    const tracks = await createLocalTracks({
      audio: {
        echoCancellation: true,
        deviceId: audioDevice ? audioDevice.deviceId : undefined,
      },
      video: {
        facingMode: "user",
        deviceId: videoDevice ? videoDevice.deviceId : undefined,
      },
    });
    for (const track of tracks) {
      await room.localParticipant.publishTrack(track, { simulcast: true });
    }
    await room.localParticipant.setMicrophoneEnabled(enableMic);
    await room.localParticipant.setCameraEnabled(enableCamera);
  } catch (e) {}

  // await room.localParticipant.enableCameraAndMicrophone();

  // await room.localParticipant.setCameraEnabled(enableCamera);
  // await room.localParticipant.setMicrophoneEnabled(enableMic);
}

export default Stage;
