import { Avatar, Tooltip } from "antd";
import { getInitials } from "../../../../helpers/helper";
import { useSpring, animated } from "@react-spring/web";

interface IParticipantAvatar {
  username: string;
  identityId: string;
  avatar: string;
  size?: number;
}

const ParticipantAvatar = ({
  username,
  identityId,
  avatar,
  size = 22,
}: IParticipantAvatar) => {
  const myspring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 80,
  });

  return (
    <animated.span
      style={myspring}
      className="ant-avatar ant-avatar-circle ant-avatar-image"
    >
      <Tooltip title={username} arrowPointAtCenter>
        <Avatar
          size={size}
          style={{
            backgroundColor: "#272b31",
            cursor: "pointer",
          }}
          src={avatar}
        >
          {getInitials(username)}
        </Avatar>
      </Tooltip>
    </animated.span>
  );
};

export default ParticipantAvatar;
