import React, { useEffect } from "react";
import { observer } from "mobx-react";
import throttle from "lodash/throttle";

import { useSpring, animated } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";

import { useStore } from "../../hooks/use-store";
import AddElement from "./addElement/AddElement";
import ElementsBase from "./elements/ElementsBase";
// import Onboarding from "./onboarding/Onboarding";
import MultiplayerCursors from "./cursor/MultiplayerCursors";
// import SpaceMenu from "./spaceMenu/SpaceMenu";
import { Pos } from "../../types";

import styles from "./workspace.module.scss";
import SoundNotification from "./soundNotification/SoundNotification";
import PasteImage from "./pasteImage/PasteImage";
import Reaction from "./reaction/Reaction";
import SpaceMenu from "./spaceMenu/SpaceMenu";

const useGesture = createUseGesture([dragAction, pinchAction]);

const Workspace = () => {
  const {
    dataStore: { spaceStore, elementsStore },
  } = useStore();

  console.log(spaceStore.currentRoom?.background);

  const debouncedChangeCursorPosition = throttle((pos: Pos) => {
    elementsStore.changeCursorPosition(pos, spaceStore.identity);
  }, 200);

  useEffect(() => {
    const handler = (e: any) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);

  const [style, api] = useSpring(() => ({
    x: -1000,
    y: -1200,
    scale: elementsStore.zoomLevel,
  }));
  const ref = React.useRef(null);

  useEffect(() => {
    api.start({
      scale: elementsStore.zoomLevel,
      x: elementsStore.followX,
      y: elementsStore.followY,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsStore.needUpdate]);

  useGesture(
    {
      onScroll: ({ event, offset: [s, y], direction: [dx, dy], memo }) => {
        // event.preventDefault()

        console.log(s);

        // elementsStore.changeMapPosition({
        //   positionX: elementsStore.mapLeftX,
        //   positionY: elementsStore.mapLeftY,
        //   scale: s,
        // });
        //
        // api.start({ scale: s, x: elementsStore.mapLeftX, y: elementsStore.mapLeftY });
        // return memo;
      },
      // onHover: ({active, event}) => console.log('hover', event, active),
      // onMove: ({event}) => console.log('move', event),
      onWheel: ({ event, offset: [s, y], direction: [dx, dy], memo }) => {
        // event.preventDefault()

        console.log(s);

        // elementsStore.changeMapPosition({
        //   positionX: elementsStore.mapLeftX,
        //   positionY: elementsStore.mapLeftY,
        //   scale: s,
        // });
        //
        // api.start({ scale: s, x: elementsStore.mapLeftX, y: elementsStore.mapLeftY });
        // return memo;
      },
      onDrag: ({
        pinching,
        cancel,
        dragging,
        event,
        target,
        offset: [x, y],
        ...rest
      }) => {
        if (
          pinching ||
          (elementsStore.followActivated && !elementsStore.selfFollow) ||
          (event.target as any)?.id !== "background"
        )
          return cancel();

        elementsStore.changeMapPosition({
          positionX: x,
          positionY: y,
          scale: elementsStore.zoomLevel,
        });
        api.start({ x, y });
      },
      onPinch: ({
        origin: [ox, oy],
        first,
        cancel,
        movement: [ms],
        offset: [s, a],
        memo,
      }) => {
        if (elementsStore.followActivated && !elementsStore.selfFollow)
          return cancel();
        if (first) {
          // @ts-ignore
          const { width, height, x, y } = ref.current.getBoundingClientRect();
          const tx = ox - (x + width / 2);
          const ty = oy - (y + height / 2);
          memo = [style.x.get(), style.y.get(), tx, ty];
        }

        const x = memo[0] - (ms - 1) * memo[2];
        const y = memo[1] - (ms - 1) * memo[3];
        elementsStore.changeZoomLevel(s);
        elementsStore.changeMapPosition({
          positionX: x,
          positionY: y,
          scale: s,
        });
        api.start({ scale: s, x, y });
        return memo;
      },
    },
    {
      target: ref,
      drag: {
        from: () => [style.x.get(), style.y.get()],
      },
      pinch: { scaleBounds: { min: 0.2, max: 3 }, rubberband: true },
    }
  );

  return (
    <>
      <SpaceMenu />
      <AddElement />
      {/* <Onboarding /> */}
      <SoundNotification />
      <PasteImage />

      <Reaction />
      <div className={styles.wrapper}>
        <div className={styles.zoomContainer}>
          <button
            onClick={() => {
              // if (elementsStore.zoomLevel < 1.1) {
              elementsStore.changeZoomLevel(
                elementsStore.zoomLevel + 0.1,
                true
              );
              // }
            }}
          >
            <span>zoom in</span>
          </button>
          <button
            onClick={() => {
              elementsStore.changeZoomLevel(
                elementsStore.zoomLevel - 0.1,
                true
              );
            }}
          >
            <span>zoom out</span>
          </button>
          <div className={styles.percent}>
            <span> {(elementsStore.zoomLevel * 100).toFixed(0)}%</span>
          </div>
        </div>
        <animated.div ref={ref} style={style}>
          <div
            id={"background"}
            className={styles.background}
            style={{
              backgroundImage: `url(${spaceStore.currentRoom?.background})`,
            }}
            onMouseMove={(e) => {
              debouncedChangeCursorPosition({
                x: e.nativeEvent.offsetX,
                y: e.nativeEvent.offsetY,
              });
            }}
          />
          <ElementsBase />
          {elementsStore.showParticipantCursors ? <MultiplayerCursors /> : null}

          <div
            onMouseLeave={(e) => {
              elementsStore.cursorLeave(spaceStore.identity);
            }}
          />
        </animated.div>
      </div>
    </>
  );
};

export default observer(Workspace);
