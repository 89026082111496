import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import FormInput from "../../../components/form/input";
import FormButton from "../../../components/form/button";
import styles from "./login.module.scss";
import { Link } from "react-router-dom";
import Logo from "../../../components/Logo";
import { useTranslation } from "react-i18next";

interface FormProps {
  username: string;
  password: string;
}

const Login = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      username: "",
      password: "",
    });
  }, [reset]);

  const login = async (data: FormProps) => {
    await authStore.login(data.username, data.password);
  };

  return (
    <div className={styles.container}>
      <div className={styles.background}>
        {/*<a*/}
        {/*  href="https://my.perkzilla.com/t91"*/}
        {/*  target={"_blank"}*/}
        {/*  rel="noreferrer"*/}
        {/*  className={styles.text}*/}
        {/*>*/}
        {/*  <span className={styles.mainText}>*/}
        {/*    Share Cake with your friends & get rewards!*/}
        {/*  </span>*/}
        {/*  <br />*/}
        {/*  <span className={styles.description}>*/}
        {/*    Help us spread the word and get up to 100% Off any Cake subscription*/}
        {/*    plan!*/}
        {/*  </span>*/}
        {/*</a>*/}
        <div
          className={styles.cakeLogo}
          style={{ backgroundImage: `url("/public/cake.svg")` }}
        />
      </div>
      <div>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.card}>
          <Typography.Title level={2}>
            {t("loginpage.login", { defaultValue: "Login" })}
          </Typography.Title>

          <FormInput
            type="text"
            rules={{
              required: true,
            }}
            error={errors.username}
            control={control}
            label={t("loginpage.email")}
            name={"username"}
          />
          <FormInput
            type="password"
            rules={{
              required: true,
            }}
            error={errors.password}
            control={control}
            label={t("loginpage.password", { defaultValue: "Password" })}
            name={"password"}
          />
          <br />
          <FormButton loading={isSubmitting} onClick={handleSubmit(login)}>
            {t("loginpage.login", { defaultValue: "Login" })}
          </FormButton>
        </div>

        <div className={styles.note}>
          <span>
            {t("loginpage.no.account", { defaultValue: "No account? Create" })}
            &nbsp;
            <Link to={"/new-account"}>
              {t("loginpage.here", { defaultValue: "here" })}
            </Link>
            <br />
            {t("loginpage.forgot.reset", {
              defaultValue: "Forgot password? Reset",
            })}
            &nbsp;
            <Link to={"/forgot-password"}>
              {t("loginpage.here", { defaultValue: "here" })}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
