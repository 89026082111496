import styles from "./index.module.scss";
import { Controller } from "react-hook-form";
import { Input } from "antd";
import React from "react";

const getError = (type: string) => {
  if (type === "required") {
    return "This field is required";
  } else {
    return "Check this field";
  }
};
const FormInput = (props: any) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>{props.label}</label>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field }) =>
          props.type !== "password" ? (
            <Input
              size="large"
              disabled={props.disabled}
              type={props.type}
              onChange={field.onChange}
              value={field.value}
              placeholder={props.placeholder}
            />
          ) : (
            <Input.Password
              size="large"
              disabled={props.disabled}
              type={props.type}
              onChange={field.onChange}
              value={field.value}
              placeholder={props.placeholder}
            />
          )
        }
      />
      {props.error ? (
        <span className={styles.error}>{getError(props.error.type)}</span>
      ) : null}
    </div>
  );
};

export default FormInput;
