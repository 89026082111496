import { Input as AntInput } from "antd";
import styles from "./textarea.module.scss";

const TextArea = AntInput.TextArea;

const Textarea = (props: any) => {
  return (
    <TextArea {...props} className={styles.container}>
      {props.children}
    </TextArea>
  );
};

export default Textarea;
