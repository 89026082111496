import React from "react";
import styles from "./addElement.module.scss";
import miro from "./imgs/miro.png";

const Instructions = () => {
  return (
    <div className={styles.instructionsContainer}>
      <span>
        Copy link to board from browser url, make sure that everyone in the
        space get an access to board
      </span>
      <img src={miro} alt={""} />
    </div>
  );
};

export default Instructions;
