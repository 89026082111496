import styles from "./index.module.scss";
import { Controller } from "react-hook-form";
import React from "react";
import { Switch } from "antd";

const getError = (type: string) => {
  if (type === "required") {
    return "This field is required";
  } else {
    return "Check this field";
  }
};
const FormSwitch = (props: any) => {
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field }) => (
          <Switch
            disabled={props.disabled}
            onChange={field.onChange}
            checked={field.value}
            checkedChildren={props.checkedChildren}
            unCheckedChildren={props.unCheckedChildren}
          />
        )}
      />
      {props.error ? (
        <span className={styles.error}>{getError(props.error.type)}</span>
      ) : null}
    </>
  );
};

export default FormSwitch;
