import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import Button from "../../../components/button/Button";
import styles from "./spaceMenu.module.scss";
import { Avatar, List } from "antd";
import dayjs from "dayjs";
import { useState } from "react";

const KeynoteMenu = () => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const [loading, setLoading] = useState(false);

  return (
    <div style={{ width: "371px" }}>
      <div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          spaceStore.slides && (
            <div>
              <List>
                {spaceStore.slides.length > 0 ? (
                  spaceStore.slides.map(
                    (itm: {
                      name: string;
                      plotId: string;
                      updatedAt: string;
                    }) => (
                      <List.Item key={itm.name}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              style={{ backgroundColor: "black" }}
                              src={itm.name}
                            >
                              {itm.name.charAt(0)}
                            </Avatar>
                          }
                          title={itm.name}
                          description={`updated ${dayjs(itm.updatedAt).fromNow(
                            true
                          )} ago`}
                        />
                        <div
                          className={styles.openPresentationButton}
                          onClick={() => {
                            spaceStore.updateRoom({
                              ...spaceStore.currentRoom!,
                              plotId: itm.plotId,
                              focusModeEnabled: true,
                            });
                          }}
                        >
                          Insert into meeting
                        </div>
                      </List.Item>
                    )
                  )
                ) : (
                  <>
                    <div>Currently empty</div>
                    <br />
                    <div>
                      <Button
                        type="primary"
                        onClick={async () => {
                          setLoading(true);
                          await spaceStore.createSlide({
                            name: "Presentation",
                            cb: (plotId) => {
                              spaceStore.updateRoom({
                                ...spaceStore.currentRoom!,
                                plotId: plotId,
                                focusModeEnabled: true,
                              });
                              setLoading(false);
                            },
                          });
                        }}
                      >
                        Create new
                      </Button>
                    </div>
                  </>
                )}
              </List>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default observer(KeynoteMenu);
