import { observer } from "mobx-react";

import { ELEMENT_TYPES } from "../../../../../helpers/enums";
import { useStore } from "../../../../../hooks/use-store";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import { useState } from "react";
import AddGiphyElementModal from "../../../addElement/AddGiphyElementModal";
import { OBJECT_FIT } from "../../../../../types";

const SlidesElementGiphy = () => {
  const {
    dataStore: { plotStore },
  } = useStore();

  const [open, setOpen] = useState(false);

  // plotStore.addNewBlockToPrezaPage({
  //   type: ELEMENT_TYPES.IFRAME,
  // })
  return (
    <>
      <div onClick={() => setOpen(true)}>
        <svg className="remix" style={{ width: "32px", height: "32px" }}>
          <use xlinkHref={`${remixiconUrl}#ri-file-gif-fill`} />
        </svg>
        <span>Giphy</span>
      </div>

      <AddGiphyElementModal
        visible={open}
        handleAdd={(image) => {
          console.log(image);
          const data = {
            cdnUrl: image.cdnUrl,
            height: image.height,
            width: image.width,
            objectFit: OBJECT_FIT.CONTAIN,
            background: "transparent",
          };

          plotStore.addNewBlockToPrezaPage({
            type: ELEMENT_TYPES.IMAGE,
            data,
          });
          setOpen(false);
        }}
        handleCancel={() => setOpen(false)}
      />
    </>
  );
};

export default observer(SlidesElementGiphy);
