import { makeObservable, observable, toJS } from "mobx";

export interface ParticipantProps {
  identityId: string;
  username: string;
  avatar: string | null;
  activePageId: string;
  activeElementId: string | null;
}

export default class PlotParticipant {
  id: number;

  @observable text: string = "";

  @observable username: string;
  @observable avatar: string;
  @observable identityId: string;
  @observable activePageId: string;
  @observable activeElementId: string;

  constructor(props: ParticipantProps) {
    Object.assign(this, props);
    makeObservable(this);
  }

  toJSON() {
    return toJS(this);
  }
}
