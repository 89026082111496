import { Popconfirm } from "antd";
import React from "react";
import { Rnd } from "react-rnd";
import { useStore } from "../../../hooks/use-store";
import styles from "./workspaceItem.module.scss";
import TextElement from "../elements/text/Text";
import { ELEMENT_TYPES } from "../../../types";
import { observer } from "mobx-react";
import { useTraceUpdate } from "../../../helpers/helper";

const WorkspaceItem = (props: {
  id: string;
  pos: { x: number; y: number };
  size: { w: number; h: number };
  type: ELEMENT_TYPES;
  data: any;
}) => {
  const {
    dataStore: { elementsStore },
  } = useStore();

  useTraceUpdate(props);

  let element = null;
  if (props.type === ELEMENT_TYPES.IFRAME) {
    element = (
      <div className={styles.full}>
        <iframe
          allow={"encrypted-media; autoplay; clipboard-read; clipboard-write"}
          title={"embed"}
          className={styles.iframe}
          // @ts-ignore
          src={props.data.url}
        />
      </div>
    );
  } else if (props.type === ELEMENT_TYPES.LINK) {
    element = (
      <div className={styles.full}>
        <a
          className={styles.link}
          href={props.data.url}
          target={"_blank"}
          rel="noreferrer"
        >
          {props.data.url}
        </a>
      </div>
    );
  } else if (props.type === ELEMENT_TYPES.TEXT) {
    element = (
      <div className={styles.editor}>
        <TextElement
          onChangeText={(text, timestamp) => {
            elementsStore.updateElementData(props.id, {
              content: text,
              timestamp,
            });
          }}
          // @ts-ignore
          timestamp={props.data.timestamp}
          // @ts-ignore
          content={props.data.content}
        />
      </div>
    );
  } else if (props.type === ELEMENT_TYPES.IMAGE) {
    element = (
      <img
        className={styles.image}
        // @ts-ignore
        src={props.data.cdnUrl}
        alt={"a"}
      />
    );
  } else if (props.type === ELEMENT_TYPES.SCREENSHARE) {
    element = (
      <div
        className={styles.screenshareContainer}
        id={"screenshare-slides-container"}
      >
        <span>Screen share placeholder</span>
      </div>
    );
  }

  if (!props.pos.x) {
    return null;
  }

  return (
    <Rnd
      position={{
        x: props.pos.x,
        y: props.pos.y,
      }}
      size={{
        width: props.size.w,
        height: props.size.h,
      }}
      onResizeStop={(e, dir, el, delta, pos) => {
        // console.log("onResize", props.size, delta);
        // console.log(+props.size.w, delta.width, props.size.w + delta.width);

        e.stopPropagation();
        if (props.type === ELEMENT_TYPES.IMAGE) {
          if (delta.width > 1000 || delta.height > 1000) {
            return;
          } else {
            elementsStore.updateElementSizeAndPos(
              props.id,
              {
                w: props.size.w + delta.width,
                h: props.size.h + delta.height,
              },
              { x: pos.x, y: pos.y }
            );
          }
        } else {
          elementsStore.updateElementSizeAndPos(
            props.id,
            {
              w: props.size.w + delta.width,
              h: props.size.h + delta.height,
            },
            { x: pos.x, y: pos.y }
          );
        }
      }}
      onDrag={(e) => {
        e.stopPropagation();
      }}
      onDragStart={(e) => {
        e.stopPropagation();
      }}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onResizeStart={(e) => {
        e.stopPropagation();
      }}
      onResize={(e) => {
        e.stopPropagation();
      }}
      onDragStop={(e, data) => {
        e.stopPropagation();
        elementsStore.updateElementPos(props.id, { x: data.x, y: data.y });
      }}
      scale={elementsStore.zoomLevel}
      className={styles.container}
      enableResizing={
        !elementsStore.followActivated || elementsStore.selfFollow
      }
      disableDragging={
        elementsStore.followActivated && !elementsStore.selfFollow
      }
    >
      <div
        style={{
          transform: `scale(${1 / (elementsStore.zoomLevel + 0.2)})`,
        }}
        className={styles.leftTopDot}
      />
      <div
        style={{
          transform: `scale(${1 / (elementsStore.zoomLevel + 0.2)})`,
        }}
        className={styles.leftBottomDot}
      />
      <div
        style={{
          transform: `scale(${1 / (elementsStore.zoomLevel + 0.2)})`,
        }}
        className={styles.rightTopDot}
      />
      <div
        style={{
          transform: `scale(${1 / (elementsStore.zoomLevel + 0.2)})`,
        }}
        className={styles.rightBottomDop}
      />

      <div
        style={{
          transform: `scale(${1 / (elementsStore.zoomLevel + 0.2)})`,
        }}
        className={styles.draggableArea}
      >
        {props.type === ELEMENT_TYPES.SCREENSHARE ? null : (
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={() => elementsStore.deleteElement(props.id)}
            okText="Yes"
            cancelText="No"
          >
            <div>
              <span>Delete</span>
            </div>
          </Popconfirm>
        )}

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            viewBox="0 0 24 24"
          >
            <path d="M8.5,17c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S9.6,17,8.5,17z M15.5,7c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S14.4,7,15.5,7z M8.5,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S9.6,10,8.5,10z M15.5,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S16.6,10,15.5,10z M15.5,17c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S16.6,17,15.5,17z M8.5,3c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S9.6,3,8.5,3z" />
          </svg>
        </div>
      </div>

      <div
        style={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          borderRadius: "8px",
        }}
      >
        {element}
      </div>
    </Rnd>
  );
};

export default observer(WorkspaceItem);
