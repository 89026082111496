import React, { useEffect } from "react";
import { useStore } from "../../../hooks/use-store";
import { Drawer, Form, Col, Row } from "antd";
import FormInput from "../../../components/form/input";
import FormButton from "../../../components/form/button";
import { useForm } from "react-hook-form";

interface Props {
  onClose: () => void;
  visible: boolean;
}

interface FormProps {
  name: string;
}

const AddRoom = (props: Props) => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      name: "",
    });
  }, [props.visible, reset]);

  const submitFn = async (data: FormProps) => {
    await spaceStore.addRoom({
      name: data.name,
    });
    props.onClose();
  };
  return (
    <Drawer
      title="Add new room"
      width={620}
      onClose={props.onClose}
      open={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <FormInput
              type="text"
              rules={{
                required: true,
                minLength: 1,
              }}
              error={errors.name}
              control={control}
              label={"Room name"}
              name={"name"}
            />
            <br />
            <FormButton loading={isSubmitting} onClick={handleSubmit(submitFn)}>
              Add
            </FormButton>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddRoom;
