import React from "react";
import { Widget } from "@typeform/embed-react";
import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
// import styles from "./sessionOver.module.scss";

const KickedPage = () => {
  const {
    uiStore: { authStore },
  } = useStore();

  const widgetContainerStyle = {
    width: "100%",
    height: "100%",
    margin: "0 auto",
  };
  return (
    <Widget
      id={"S0Ov4t7W"}
      style={widgetContainerStyle}
      hidden={{ email: authStore.currentUser!.email! }}
    />
  );
};

export default observer(KickedPage);
