import React from "react";
import FormButton from "../../../components/form/button";
import styles from "./spaceWelcomeScreen.module.scss";
import Countdown from "react-countdown";
import Logo from "../../../components/Logo";

interface Props {
  spaceId: string | undefined;
  spaceName: string | undefined;
  logo: string | undefined;
  welcomePage:
    | {
        enabled: boolean;
        date: string;
        name: string;
        description: string;
        background: string;
      }
    | undefined;
  onSuccess: () => void;
}

const SpaceWelcomeScreen = ({
  onSuccess,
  spaceName,
  welcomePage,
  logo,
}: Props) => {
  const date = welcomePage?.date ? new Date(welcomePage?.date) : new Date();

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.header}
        style={{
          backgroundImage: `url(${
            welcomePage?.background || "/backgrounds/default/bg3.jpg"
          })`,
        }}
      >
        <div>
          {logo ? (
            <div className={styles.spaceLogo}>
              <img alt="space logo" src={logo} />
            </div>
          ) : null}
          <div className={styles.inviteName}>
            <span>Cake invites you to {spaceName}</span>
          </div>
          <div className={styles.eventName}>
            <span>{welcomePage?.name}</span>
          </div>
          <div className={styles.countdown}>
            <Countdown
              date={date}
              renderer={({ days, hours, minutes, seconds, completed }: any) => {
                if (completed) {
                  // Render a complete state
                  return (
                    <FormButton onClick={onSuccess}>Join event</FormButton>
                  );
                } else {
                  // Render a countdown
                  return (
                    <>
                      <div className={styles.countdownText}>
                        <span className={styles.startingIn}>Starting in:</span>
                        <span>
                          {days}d : {hours}h : {minutes}m : {seconds}s
                        </span>
                      </div>
                    </>
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={styles.cardWrapper}>
          <div>
            <div className={styles.eventDescription}>
              {welcomePage?.description || "Nothing about this space for now"}
            </div>
          </div>
          <div>
            <div>
              <div className={styles.powered}>
                This event is powered by <b>Cake</b>
              </div>
              <div className={styles.powered}>
                Entering the event you accept the{" "}
                <a
                  href={"https://www.cake.work/legal/terms-of-use"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Terms
                </a>{" "}
                and{" "}
                <a
                  target={"_blank"}
                  rel={"noreferrer"}
                  href={"https://www.cake.work/legal/privacy"}
                >
                  Conditions
                </a>
              </div>
            </div>

            {/*<div className={styles.feedback}>*/}
            {/*  <span className={styles.title}>Got a feedback?</span>*/}

            {/*  <span className={styles.description}>*/}
            {/*    You are welcome! Please fill the form and we will contact you as*/}
            {/*    soon as possible!*/}
            {/*  </span>*/}
            {/*  <Button*/}
            {/*    type="primary"*/}
            {/*    fluid*/}
            {/*    onClick={() => {*/}
            {/*      // @ts-ignore*/}
            {/*      window.location =*/}
            {/*        "https://309sov53iwj.typeform.com/to/Ti5MBxu8";*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Share feedback*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className={styles.order}></div>
      <div className={styles.footer}>
        <a href={"https://www.cake.work"} rel="noreferrer" target={"_blank"}>
          <span>Powered by</span>
          <Logo />
        </a>
      </div>
    </div>
  );
};

export default SpaceWelcomeScreen;
