import React, { useState } from "react";
import { Gluejar } from "@charliewilco/gluejar";
import { UploadClient } from "@uploadcare/upload-client";
import { ELEMENT_TYPES } from "../../../../helpers/enums";
import { useStore } from "../../../../hooks/use-store";
import { observer } from "mobx-react";
import { NOTIFICATION_SERVICE } from "../../../../components/notifications/NotificationService";
import { OBJECT_FIT } from "../../../../types";

const client = new UploadClient({ publicKey: "f4d9f840695d97e9cbb7" });

const PasteImage = () => {
  const {
    dataStore: { plotStore },
  } = useStore();
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  return (
    // @ts-ignore
    <Gluejar
      onPaste={async (state) => {
        for (const image of state.images) {
          NOTIFICATION_SERVICE.successNotification(
            "Adding an image from buffer..."
          );
          if (!uploadedImages.includes(image)) {
            console.log("start upload", image);
            let blob = await fetch(image).then((r) => r.blob());
            const res: any = await client.uploadFile(blob);
            console.log(res);

            const data = {
              cdnUrl: res.cdnUrl,
              height: res.imageInfo.height,
              width: res.imageInfo.width,
              objectFit: OBJECT_FIT.CONTAIN,
            };
            plotStore.addNewBlockToPrezaPage({
              type: ELEMENT_TYPES.IMAGE,
              data,
            });

            setUploadedImages([...uploadedImages, image]);
            console.log("uploaded", image);
          }
        }
      }}
      onError={(err) => console.error(err)}
    />
  );
};

export default observer(PasteImage);
