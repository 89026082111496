import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../hooks/use-store";
import styles from "./chat.module.scss";
import { Avatar } from "antd";
import FormButton from "../../components/form/button";
import { Controller, useForm } from "react-hook-form";
import Input from "../input";
import { observer } from "mobx-react";
import { getInitials } from "../../helpers/helper";
import dayjs from "dayjs";

interface FormProps {
  name: string;
}

const Chat = () => {
  const [count, setCount] = useState(0);
  const {
    dataStore: { chatStore },
  } = useStore();

  const myRef = useRef(null);

  const messages = Array.from(chatStore.messages);

  useEffect(() => {
    if (myRef && myRef.current) {
      // @ts-ignore
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [count]);

  useEffect(() => {
    if (messages.length > count) {
      setCount(messages.length);
    }
  }, [messages.length, count]);

  const { control, reset, handleSubmit } = useForm<FormProps>();

  const submitFn = async (data: FormProps) => {
    await chatStore.send(data.name);
    reset();
  };

  return (
    <div className={styles.container}>
      <div className={styles.close}>
        <span
          onClick={() => {
            chatStore.hide();
          }}
        >
          Close Chat
        </span>
      </div>
      <div className={styles.messages}>
        {messages.map((itm) => {
          const avatar = (
            <div className={styles.avatar}>
              <Avatar
                // style={{ backgroundColor: "#E8B80E" }}
                //shape="square"
                size={42}
                src={itm.user.avatar}
              >
                {getInitials(itm.user.firstName)}
              </Avatar>
            </div>
          );

          const text = (
            <div className={styles.content}>
              <span className={styles.title}>
                <span className={styles.name}>
                  {itm.user.firstName + " " + itm.user.lastName}
                </span>
                <span className={styles.time}>{`${dayjs(itm.created_at).fromNow(
                  true
                )} ago`}</span>
              </span>
              <div className={styles.text}>
                <div>{itm.text}</div>
              </div>
            </div>
          );

          if (itm.user.id === chatStore.identity) {
            return (
              <div className={styles.selfMessage} key={itm.id}>
                {text}
                {avatar}
              </div>
            );
          } else {
            return (
              <div className={styles.message} key={itm.id}>
                {avatar}
                {text}
              </div>
            );
          }
        })}
        <div ref={myRef}></div>
      </div>
      <div className={styles.inputContainer}>
        <form onSubmit={handleSubmit(submitFn)}>
          <Controller
            name={"name"}
            control={control}
            render={({ field }) => (
              <Input
                placeholder={"Enter a message"}
                size="middle"
                type={"text"}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <FormButton htmlType={"submit"}>Send</FormButton>
        </form>
      </div>
    </div>
  );
};

export default observer(Chat);
