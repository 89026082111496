import { Button as AntButton } from "antd";
import styles from "./button.module.scss";
const Button = (props: any) => {
  return (
    <AntButton
      {...props}
      className={props.inverted ? styles.blackContainer : styles.blackContainer}
    >
      {props.children}
    </AntButton>
  );
};

export default Button;
