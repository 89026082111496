import React from "react";
import { Modal } from "antd";
// @ts-ignore
import ReactGiphySearchBox from "react-giphy-searchbox";
import styles from "./addElement.module.scss";

import { Image } from "../../../types";

interface Props {
  visible: boolean;
  handleAdd: (image: Image) => void;
  handleCancel: () => void;
}
const AddGiphyElement = ({ visible, handleAdd, handleCancel }: Props) => {
  return (
    <Modal
      width={640}
      title="Add Giphy"
      open={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div className={styles.maxWidth}>
        {
          // @ts-ignore
          <ReactGiphySearchBox
            listWrapperClassName={styles.giphtWrapper}
            apiKey="PGuA2dx5GnFBLrdtsRwnk76UNNVJI7g5"
            onSelect={async (item: any) => {
              await handleAdd({
                cdnUrl: item.images.original.url,
                height: item.images.original.height,
                width: item.images.original.width,
              });
              console.log(item);
            }}
            masonryConfig={[
              { columns: 2, imageWidth: 110, gutter: 5 },
              { mq: "700px", columns: 3, imageWidth: 190, gutter: 5 },
            ]}
          />
        }
      </div>
    </Modal>
  );
};

export default AddGiphyElement;
