import { observer } from "mobx-react";
import { Popover } from "antd";
import { useStore } from "../../../../../hooks/use-store";
import styles from "./theme.module.scss";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import { themeColors } from "../../../../../stores/data/space-store/theme";

const Theme = () => {
  const {
    dataStore: { plotStore },
  } = useStore();

  return (
    <>
      <Popover
        placement="left"
        popupVisible={true}
        // defaultVisible={true}
        showArrow={false}
        // visible={true}
        // overlayClassName={styles.overlay}
        // overlayInnerStyle={{padding: 0, borderRadius: '8px'}}
        content={
          <div className={styles.popover}>
            Choose presentation theme
            <div className={styles.addElementContainer}>
              {[
                "BLACK",
                "YELLOW",
                "BLUE",
                "LIGHT",
                "CREMA",
                "MARINE",
                "NIGHT",
                "PAPER",
                "GREEN",
              ].map((itm) => (
                <div
                  style={{
                    // @ts-ignore
                    background: themeColors[itm]["--slides-background"],
                  }}
                  onClick={() => {
                    plotStore.changePlot({ theme: itm });
                  }}
                >
                  <span
                    className={styles.title}
                    style={{
                      // @ts-ignore
                      color: themeColors[itm]["--slides-text-color-primary"],
                    }}
                  >
                    Title
                  </span>
                  <span
                    className={styles.body}
                    style={{
                      // @ts-ignore
                      color: themeColors[itm]["--slides-text-color-secondary"],
                    }}
                  >
                    Body text
                  </span>
                </div>
              ))}
            </div>
          </div>
        }
      >
        <div className={styles.trigger}>
          <svg className="remix">
            <use xlinkHref={`${remixiconUrl}#ri-palette-fill`} />
          </svg>
        </div>
      </Popover>
    </>
  );
};

export default observer(Theme);
