import React from "react";

const SVG = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="49px"
        height="67px"
        viewBox="0 0 49 67"
        version="1.1"
      >
        <defs>
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-1"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-3"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-5"
          />
          <linearGradient
            x1="50.0053945%"
            y1="8.58610612%"
            x2="50.0053945%"
            y2="100.013939%"
            id="linearGradient-7"
          >
            <stop stop-color="#263238" stop-opacity="0.2" offset="0%" />
            <stop stop-color="#263238" stop-opacity="0.02" offset="100%" />
          </linearGradient>
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-8"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-10"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-12"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-14"
          />
          <radialGradient
            cx="3.16804688%"
            cy="2.71744318%"
            fx="3.16804688%"
            fy="2.71744318%"
            r="161.248516%"
            gradientTransform="translate(0.031680,0.027174),scale(1.000000,0.727273),translate(-0.031680,-0.027174)"
            id="radialGradient-16"
          >
            <stop stop-color="#FFFFFF" stop-opacity="0.1" offset="0%" />
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%" />
          </radialGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Consumer-Apps-Sheets-Large-VD-R8-"
            transform="translate(-451.000000, -451.000000)"
          >
            <g id="Hero" transform="translate(0.000000, 63.000000)">
              <g id="Personal" transform="translate(277.000000, 299.000000)">
                <g
                  id="Sheets-icon"
                  transform="translate(174.833333, 89.958333)"
                >
                  <g id="Group">
                    <g id="Clipped">
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L36.9791667,10.3541667 L29.5833333,0 Z"
                        id="Path"
                        fill="#0F9D58"
                        fill-rule="nonzero"
                        mask="url(#mask-2)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-4" fill="white">
                        <use xlinkHref="#path-3" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M11.8333333,31.8020833 L11.8333333,53.25 L35.5,53.25 L35.5,31.8020833 L11.8333333,31.8020833 Z M22.1875,50.2916667 L14.7916667,50.2916667 L14.7916667,46.59375 L22.1875,46.59375 L22.1875,50.2916667 Z M22.1875,44.375 L14.7916667,44.375 L14.7916667,40.6770833 L22.1875,40.6770833 L22.1875,44.375 Z M22.1875,38.4583333 L14.7916667,38.4583333 L14.7916667,34.7604167 L22.1875,34.7604167 L22.1875,38.4583333 Z M32.5416667,50.2916667 L25.1458333,50.2916667 L25.1458333,46.59375 L32.5416667,46.59375 L32.5416667,50.2916667 Z M32.5416667,44.375 L25.1458333,44.375 L25.1458333,40.6770833 L32.5416667,40.6770833 L32.5416667,44.375 Z M32.5416667,38.4583333 L25.1458333,38.4583333 L25.1458333,34.7604167 L32.5416667,34.7604167 L32.5416667,38.4583333 Z"
                        id="Shape"
                        fill="#F1F1F1"
                        fill-rule="nonzero"
                        mask="url(#mask-4)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-6" fill="white">
                        <use xlinkHref="#path-5" />
                      </mask>
                      <g id="SVGID_1_" />
                      <polygon
                        id="Path"
                        fill="url(#linearGradient-7)"
                        fillRule="nonzero"
                        mask="url(#mask-6)"
                        points="30.8813021 16.4520313 47.3333333 32.9003646 47.3333333 17.75"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-9" fill="white">
                        <use xlinkHref="#path-8" />
                      </mask>
                      <g id="SVGID_1_" />
                      <g id="Group" mask="url(#mask-9)">
                        <g transform="translate(26.625000, -2.958333)">
                          <path
                            d="M2.95833333,2.95833333 L2.95833333,16.2708333 C2.95833333,18.7225521 4.94411458,20.7083333 7.39583333,20.7083333 L20.7083333,20.7083333 L2.95833333,2.95833333 Z"
                            id="Path"
                            fill="#87CEAC"
                            fill-rule="nonzero"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Clipped">
                      <mask id="mask-11" fill="white">
                        <use xlinkHref="#path-10" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,4.80729167 C0,2.36666667 1.996875,0.369791667 4.4375,0.369791667 L29.5833333,0.369791667 L29.5833333,0 L4.4375,0 Z"
                        id="Path"
                        fill-opacity="0.2"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                        mask="url(#mask-11)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-13" fill="white">
                        <use xlinkHref="#path-12" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M42.8958333,64.7135417 L4.4375,64.7135417 C1.996875,64.7135417 0,62.7166667 0,60.2760417 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,60.2760417 C47.3333333,62.7166667 45.3364583,64.7135417 42.8958333,64.7135417 Z"
                        id="Path"
                        fill-opacity="0.2"
                        fill="#263238"
                        fill-rule="nonzero"
                        mask="url(#mask-13)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-15" fill="white">
                        <use xlinkHref="#path-14" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M34.0208333,17.75 C31.5691146,17.75 29.5833333,15.7642188 29.5833333,13.3125 L29.5833333,13.6822917 C29.5833333,16.1340104 31.5691146,18.1197917 34.0208333,18.1197917 L47.3333333,18.1197917 L47.3333333,17.75 L34.0208333,17.75 Z"
                        id="Path"
                        fill-opacity="0.1"
                        fill="#263238"
                        fill-rule="nonzero"
                        mask="url(#mask-15)"
                      />
                    </g>
                  </g>
                  <path
                    d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
                    id="Path"
                    fill="url(#radialGradient-16)"
                    fill-rule="nonzero"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48px"
        height="66px"
        viewBox="0 0 48 66"
        version="1.1"
      >
        <defs>
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-1"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-3"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-5"
          />
          <linearGradient
            x1="50.0053945%"
            y1="8.58610612%"
            x2="50.0053945%"
            y2="100.013939%"
            id="linearGradient-7"
          >
            <stop stop-color="#BF360C" stop-opacity="0.2" offset="0%" />
            <stop stop-color="#BF360C" stop-opacity="0.02" offset="100%" />
          </linearGradient>
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-8"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-10"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-12"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-14"
          />
          <path
            d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
            id="path-16"
          />
          <radialGradient
            cx="3.16804688%"
            cy="2.71744318%"
            fx="3.16804688%"
            fy="2.71744318%"
            r="161.248516%"
            gradientTransform="translate(0.031680,0.027174),scale(1.000000,0.727273),translate(-0.031680,-0.027174)"
            id="radialGradient-18"
          >
            <stop stop-color="#FFFFFF" stop-opacity="0.1" offset="0%" />
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%" />
          </radialGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Consumer-Apps-Slides-Large-VD-R8"
            transform="translate(-449.000000, -452.000000)"
          >
            <g id="Hero" transform="translate(0.000000, 63.000000)">
              <g id="Personal" transform="translate(277.000000, 299.000000)">
                <g
                  id="Slides-icon"
                  transform="translate(172.000000, 90.000000)"
                >
                  <g id="Group">
                    <g id="Clipped">
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L36.9791667,10.3541667 L29.5833333,0 Z"
                        id="Path"
                        fill="#F4B400"
                        fill-rule="nonzero"
                        mask="url(#mask-2)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-4" fill="white">
                        <use xlinkHref="#path-3" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M33.28125,29.5833333 L14.0520833,29.5833333 C12.8317708,29.5833333 11.8333333,30.5817708 11.8333333,31.8020833 L11.8333333,51.03125 C11.8333333,52.2515625 12.8317708,53.25 14.0520833,53.25 L33.28125,53.25 C34.5015625,53.25 35.5,52.2515625 35.5,51.03125 L35.5,31.8020833 C35.5,30.5817708 34.5015625,29.5833333 33.28125,29.5833333 Z M32.5416667,46.59375 L14.7916667,46.59375 L14.7916667,36.2395833 L32.5416667,36.2395833 L32.5416667,46.59375 Z"
                        id="Shape"
                        fill="#F1F1F1"
                        fill-rule="nonzero"
                        mask="url(#mask-4)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-6" fill="white">
                        <use xlinkHref="#path-5" />
                      </mask>
                      <g id="SVGID_1_" />
                      <polygon
                        id="Path"
                        fill="url(#linearGradient-7)"
                        fill-rule="nonzero"
                        mask="url(#mask-6)"
                        points="30.8813021 16.4520313 47.3333333 32.9003646 47.3333333 17.75"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-9" fill="white">
                        <use xlinkHref="#path-8" />
                      </mask>
                      <g id="SVGID_1_" />
                      <g id="Group" mask="url(#mask-9)">
                        <g transform="translate(26.625000, -2.958333)">
                          <path
                            d="M2.95833333,2.95833333 L2.95833333,16.2708333 C2.95833333,18.7225521 4.94411458,20.7083333 7.39583333,20.7083333 L20.7083333,20.7083333 L2.95833333,2.95833333 Z"
                            id="Path"
                            fill="#FADA80"
                            fill-rule="nonzero"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Clipped">
                      <mask id="mask-11" fill="white">
                        <use xlinkHref="#path-10" />
                      </mask>
                      <g id="SVGID_1_" />
                      <polygon
                        id="Path"
                        fill-opacity="0.1"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                        mask="url(#mask-11)"
                        points="29.5833333 0 29.5833333 0.369791667 46.9635417 17.75 47.3333333 17.75"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-13" fill="white">
                        <use xlinkHref="#path-12" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,4.80729167 C0,2.36666667 1.996875,0.369791667 4.4375,0.369791667 L29.5833333,0.369791667 L29.5833333,0 L4.4375,0 Z"
                        id="Path"
                        fill-opacity="0.2"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                        mask="url(#mask-13)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-15" fill="white">
                        <use xlinkHref="#path-14" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M42.8958333,64.7135417 L4.4375,64.7135417 C1.996875,64.7135417 0,62.7166667 0,60.2760417 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,60.2760417 C47.3333333,62.7166667 45.3364583,64.7135417 42.8958333,64.7135417 Z"
                        id="Path"
                        fill-opacity="0.2"
                        fill="#BF360C"
                        fill-rule="nonzero"
                        mask="url(#mask-15)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-17" fill="white">
                        <use xlinkHref="#path-16" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M34.0208333,17.75 C31.5691146,17.75 29.5833333,15.7642188 29.5833333,13.3125 L29.5833333,13.6822917 C29.5833333,16.1340104 31.5691146,18.1197917 34.0208333,18.1197917 L47.3333333,18.1197917 L47.3333333,17.75 L34.0208333,17.75 Z"
                        id="Path"
                        fill-opacity="0.1"
                        fill="#BF360C"
                        fill-rule="nonzero"
                        mask="url(#mask-17)"
                      />
                    </g>
                  </g>
                  <path
                    d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
                    id="Path"
                    fill="url(#radialGradient-18)"
                    fill-rule="nonzero"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47px"
        height="65px"
        viewBox="0 0 47 65"
        version="1.1"
      >
        <defs>
          <path
            d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z"
            id="path-1"
          />
          <path
            d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z"
            id="path-3"
          />
          <linearGradient
            x1="50.0053945%"
            y1="8.58610612%"
            x2="50.0053945%"
            y2="100.013939%"
            id="linearGradient-5"
          >
            <stop stop-color="#1A237E" stop-opacity="0.2" offset="0%" />
            <stop stop-color="#1A237E" stop-opacity="0.02" offset="100%" />
          </linearGradient>
          <path
            d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z"
            id="path-6"
          />
          <path
            d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z"
            id="path-8"
          />
          <path
            d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z"
            id="path-10"
          />
          <path
            d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z"
            id="path-12"
          />
          <path
            d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z"
            id="path-14"
          />
          <radialGradient
            cx="3.16804688%"
            cy="2.71744318%"
            fx="3.16804688%"
            fy="2.71744318%"
            r="161.248516%"
            gradientTransform="translate(0.031680,0.027174),scale(1.000000,0.723077),translate(-0.031680,-0.027174)"
            id="radialGradient-16"
          >
            <stop stop-color="#FFFFFF" stop-opacity="0.1" offset="0%" />
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%" />
          </radialGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Consumer-Apps-Docs-Large-VD-R8"
            transform="translate(-451.000000, -463.000000)"
          >
            <g id="Hero" transform="translate(0.000000, 63.000000)">
              <g id="Personal" transform="translate(277.000000, 309.000000)">
                <g id="Docs-icon" transform="translate(174.000000, 91.000000)">
                  <g id="Group">
                    <g id="Clipped">
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L36.71875,10.3409091 L29.375,0 Z"
                        id="Path"
                        fill="#4285F4"
                        fill-rule="nonzero"
                        mask="url(#mask-2)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-4" fill="white">
                        <use xlinkHref="#path-3" />
                      </mask>
                      <g id="SVGID_1_" />
                      <polygon
                        id="Path"
                        fill="url(#linearGradient-5)"
                        fill-rule="nonzero"
                        mask="url(#mask-4)"
                        points="30.6638281 16.4309659 47 32.8582386 47 17.7272727"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-7" fill="white">
                        <use xlinkHref="#path-6" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M11.75,47.2727273 L35.25,47.2727273 L35.25,44.3181818 L11.75,44.3181818 L11.75,47.2727273 Z M11.75,53.1818182 L29.375,53.1818182 L29.375,50.2272727 L11.75,50.2272727 L11.75,53.1818182 Z M11.75,32.5 L11.75,35.4545455 L35.25,35.4545455 L35.25,32.5 L11.75,32.5 Z M11.75,41.3636364 L35.25,41.3636364 L35.25,38.4090909 L11.75,38.4090909 L11.75,41.3636364 Z"
                        id="Shape"
                        fill="#F1F1F1"
                        fill-rule="nonzero"
                        mask="url(#mask-7)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-9" fill="white">
                        <use xlinkHref="#path-8" />
                      </mask>
                      <g id="SVGID_1_" />
                      <g id="Group" mask="url(#mask-9)">
                        <g transform="translate(26.437500, -2.954545)">
                          <path
                            d="M2.9375,2.95454545 L2.9375,16.25 C2.9375,18.6985795 4.90929688,20.6818182 7.34375,20.6818182 L20.5625,20.6818182 L2.9375,2.95454545 Z"
                            id="Path"
                            fill="#A1C2FA"
                            fill-rule="nonzero"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Clipped">
                      <mask id="mask-11" fill="white">
                        <use xlinkHref="#path-10" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,4.80113636 C0,2.36363636 1.9828125,0.369318182 4.40625,0.369318182 L29.375,0.369318182 L29.375,0 L4.40625,0 Z"
                        id="Path"
                        fill-opacity="0.2"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                        mask="url(#mask-11)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-13" fill="white">
                        <use xlinkHref="#path-12" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M42.59375,64.6306818 L4.40625,64.6306818 C1.9828125,64.6306818 0,62.6363636 0,60.1988636 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,60.1988636 C47,62.6363636 45.0171875,64.6306818 42.59375,64.6306818 Z"
                        id="Path"
                        fill-opacity="0.2"
                        fill="#1A237E"
                        fill-rule="nonzero"
                        mask="url(#mask-13)"
                      />
                    </g>
                    <g id="Clipped">
                      <mask id="mask-15" fill="white">
                        <use xlinkHref="#path-14" />
                      </mask>
                      <g id="SVGID_1_" />
                      <path
                        d="M33.78125,17.7272727 C31.3467969,17.7272727 29.375,15.7440341 29.375,13.2954545 L29.375,13.6647727 C29.375,16.1133523 31.3467969,18.0965909 33.78125,18.0965909 L47,18.0965909 L47,17.7272727 L33.78125,17.7272727 Z"
                        id="Path"
                        fill-opacity="0.1"
                        fill="#1A237E"
                        fill-rule="nonzero"
                        mask="url(#mask-15)"
                      />
                    </g>
                  </g>
                  <path
                    d="M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z"
                    id="Path"
                    fill="url(#radialGradient-16)"
                    fill-rule="nonzero"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default SVG;
