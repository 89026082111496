import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import ReactDOM from "react-dom";
import {
  useParticipant,
  VideoRenderer,
  AudioRenderer,
} from "@livekit/react-components";
import {
  Participant as LiveKitParticipant,
  ConnectionQuality,
} from "livekit-client";
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks/use-store";
import styles from "./meetingParticipant.module.scss";

const ScreenShare = (props: { screenSharePublication: any }) => {
  const [el, setEl] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const element = document.getElementById("screenshare-fullscreen-container");
    setEl(element);
  }, [
    props.screenSharePublication?.track,
    props.screenSharePublication?.isMuted,
    props.screenSharePublication?.isEnabled,
  ]);

  if (
    !props.screenSharePublication ||
    !props.screenSharePublication.track ||
    props.screenSharePublication.isMuted ||
    !props.screenSharePublication.isEnabled ||
    !el
  ) {
    return null;
  }

  return ReactDOM.createPortal(
    <VideoRenderer
      track={props.screenSharePublication.track}
      isLocal={false}
      className={styles.screenshare}
    />,
    el
  );
};

const CustomParticipantView = (props: {
  identity: string;
  participant: LiveKitParticipant;
}) => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const {
    cameraPublication,
    isLocal,
    screenSharePublication,
    microphonePublication,
    isSpeaking,
    connectionQuality,
  } = useParticipant(props.participant);

  const videoMuted =
    !cameraPublication ||
    !cameraPublication.isSubscribed ||
    !cameraPublication.track ||
    cameraPublication.isMuted;

  const micMuted =
    !microphonePublication ||
    !microphonePublication.track ||
    microphonePublication.isMuted;

  const participant = spaceStore.participantMap.get(props.identity);

  if (!participant) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.participant}>
        {videoMuted ? (
          <div className={styles.loading}>
            <Avatar className={styles.avatar} src={participant?.avatar}>
              {participant?.username!}
            </Avatar>
          </div>
        ) : (
          <VideoRenderer
            track={cameraPublication?.track!}
            isLocal={isLocal}
            className={styles.video}
          />
        )}

        {micMuted ? null : !isLocal ? (
          <AudioRenderer
            track={microphonePublication?.track!}
            isLocal={isLocal}
          />
        ) : null}
      </div>
      {isSpeaking ? <div className={styles.indicator} /> : null}

      <ScreenShare screenSharePublication={screenSharePublication} />

      <div className={styles.name}>
        <div>
          {connectionQuality === ConnectionQuality.Excellent && (
            <svg
              className={styles.connection}
              width="14"
              height="14"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.5 10.5V7.5C0.5 7.23478 0.605357 6.98043 0.792893 6.79289C0.98043 6.60536 1.23478 6.5 1.5 6.5C1.76522 6.5 2.01957 6.60536 2.20711 6.79289C2.39464 6.98043 2.5 7.23478 2.5 7.5V10.5C2.5 10.7652 2.39464 11.0196 2.20711 11.2071C2.01957 11.3946 1.76522 11.5 1.5 11.5C1.23478 11.5 0.98043 11.3946 0.792893 11.2071C0.605357 11.0196 0.5 10.7652 0.5 10.5ZM4.5 10.5V4.5C4.5 4.23478 4.60536 3.98043 4.79289 3.79289C4.98043 3.60536 5.23478 3.5 5.5 3.5C5.76522 3.5 6.01957 3.60536 6.20711 3.79289C6.39464 3.98043 6.5 4.23478 6.5 4.5V10.5C6.5 10.7652 6.39464 11.0196 6.20711 11.2071C6.01957 11.3946 5.76522 11.5 5.5 11.5C5.23478 11.5 4.98043 11.3946 4.79289 11.2071C4.60536 11.0196 4.5 10.7652 4.5 10.5ZM8.5 10.5V1.5C8.5 1.23478 8.60536 0.98043 8.79289 0.792893C8.98043 0.605357 9.23478 0.5 9.5 0.5C9.76522 0.5 10.0196 0.605357 10.2071 0.792893C10.3946 0.98043 10.5 1.23478 10.5 1.5V10.5C10.5 10.7652 10.3946 11.0196 10.2071 11.2071C10.0196 11.3946 9.76522 11.5 9.5 11.5C9.23478 11.5 8.98043 11.3946 8.79289 11.2071C8.60536 11.0196 8.5 10.7652 8.5 10.5Z"
                fill="green"
                stroke="green"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          {connectionQuality === ConnectionQuality.Good && (
            <svg
              className={styles.connection}
              width="14"
              height="14"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.5 10.5V7.5C0.5 7.23478 0.605357 6.98043 0.792893 6.79289C0.98043 6.60536 1.23478 6.5 1.5 6.5C1.76522 6.5 2.01957 6.60536 2.20711 6.79289C2.39464 6.98043 2.5 7.23478 2.5 7.5V10.5C2.5 10.7652 2.39464 11.0196 2.20711 11.2071C2.01957 11.3946 1.76522 11.5 1.5 11.5C1.23478 11.5 0.98043 11.3946 0.792893 11.2071C0.605357 11.0196 0.5 10.7652 0.5 10.5ZM4.5 10.5V4.5C4.5 4.23478 4.60536 3.98043 4.79289 3.79289C4.98043 3.60536 5.23478 3.5 5.5 3.5C5.76522 3.5 6.01957 3.60536 6.20711 3.79289C6.39464 3.98043 6.5 4.23478 6.5 4.5V10.5C6.5 10.7652 6.39464 11.0196 6.20711 11.2071C6.01957 11.3946 5.76522 11.5 5.5 11.5C5.23478 11.5 4.98043 11.3946 4.79289 11.2071C4.60536 11.0196 4.5 10.7652 4.5 10.5Z"
                fill="yellow"
                stroke="yellow"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.5 10.5V1.5C8.5 1.23478 8.60536 0.98043 8.79289 0.792893C8.98043 0.605357 9.23478 0.5 9.5 0.5C9.76522 0.5 10.0196 0.605357 10.2071 0.792893C10.3946 0.98043 10.5 1.23478 10.5 1.5V10.5C10.5 10.7652 10.3946 11.0196 10.2071 11.2071C10.0196 11.3946 9.76522 11.5 9.5 11.5C9.23478 11.5 8.98043 11.3946 8.79289 11.2071C8.60536 11.0196 8.5 10.7652 8.5 10.5Z"
                stroke="yellow"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          {connectionQuality === ConnectionQuality.Poor && (
            <svg
              className={styles.connection}
              width="14"
              height="14"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.5 10.5V7.5C0.5 7.23478 0.605357 6.98043 0.792893 6.79289C0.98043 6.60536 1.23478 6.5 1.5 6.5C1.76522 6.5 2.01957 6.60536 2.20711 6.79289C2.39464 6.98043 2.5 7.23478 2.5 7.5V10.5C2.5 10.7652 2.39464 11.0196 2.20711 11.2071C2.01957 11.3946 1.76522 11.5 1.5 11.5C1.23478 11.5 0.98043 11.3946 0.792893 11.2071C0.605357 11.0196 0.5 10.7652 0.5 10.5Z"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.5 10.5V4.5C4.5 4.23478 4.60536 3.98043 4.79289 3.79289C4.98043 3.60536 5.23478 3.5 5.5 3.5C5.76522 3.5 6.01957 3.60536 6.20711 3.79289C6.39464 3.98043 6.5 4.23478 6.5 4.5V10.5C6.5 10.7652 6.39464 11.0196 6.20711 11.2071C6.01957 11.3946 5.76522 11.5 5.5 11.5C5.23478 11.5 4.98043 11.3946 4.79289 11.2071C4.60536 11.0196 4.5 10.7652 4.5 10.5ZM8.5 10.5V1.5C8.5 1.23478 8.60536 0.98043 8.79289 0.792893C8.98043 0.605357 9.23478 0.5 9.5 0.5C9.76522 0.5 10.0196 0.605357 10.2071 0.792893C10.3946 0.98043 10.5 1.23478 10.5 1.5V10.5C10.5 10.7652 10.3946 11.0196 10.2071 11.2071C10.0196 11.3946 9.76522 11.5 9.5 11.5C9.23478 11.5 8.98043 11.3946 8.79289 11.2071C8.60536 11.0196 8.5 10.7652 8.5 10.5Z"
                stroke="red"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          {micMuted ? (
            <svg
              className={styles.muteIcon}
              width="32"
              height="31"
              viewBox="0 0 32 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15.9375" cy="15.75" r="15.25" />
              <g clip-path="url(#clip0_2_123)">
                <path
                  d="M21.3836 15.1852C21.3836 15.8223 21.2812 16.4357 21.0938 17.0073L20.2072 16.0878C20.2652 15.7907 20.2944 15.4883 20.2943 15.1852V14.0556C20.2943 13.9058 20.3517 13.7621 20.4538 13.6562C20.556 13.5503 20.6945 13.4907 20.8389 13.4907C20.9834 13.4907 21.1219 13.5503 21.2241 13.6562C21.3262 13.7621 21.3836 13.9058 21.3836 14.0556V15.1852ZM15.9372 19.7037C16.8282 19.7037 17.656 19.427 18.3467 18.9502L19.1288 19.7625C18.3512 20.3472 17.4376 20.7071 16.4818 20.8051V23.0926H19.7497C19.8941 23.0926 20.0326 23.1521 20.1348 23.258C20.2369 23.364 20.2943 23.5076 20.2943 23.6574C20.2943 23.8072 20.2369 23.9509 20.1348 24.0568C20.0326 24.1627 19.8941 24.2222 19.7497 24.2222H12.1247C11.9802 24.2222 11.8417 24.1627 11.7395 24.0568C11.6374 23.9509 11.58 23.8072 11.58 23.6574C11.58 23.5076 11.6374 23.364 11.7395 23.258C11.8417 23.1521 11.9802 23.0926 12.1247 23.0926H15.3925V20.8051C14.0491 20.6651 12.8037 20.0126 11.8979 18.9741C10.9922 17.9357 10.4907 16.5854 10.4907 15.1852V14.0556C10.4907 13.9058 10.5481 13.7621 10.6502 13.6562C10.7524 13.5503 10.8909 13.4907 11.0354 13.4907C11.1798 13.4907 11.3183 13.5503 11.4205 13.6562C11.5226 13.7621 11.58 13.9058 11.58 14.0556V15.1852C11.58 16.3836 12.0391 17.5329 12.8562 18.3803C13.6733 19.2277 14.7816 19.7037 15.9372 19.7037ZM19.205 9.53704V15.0485L12.8414 8.44921C13.0908 7.68924 13.5921 7.04571 14.2562 6.63318C14.9203 6.22065 15.704 6.06589 16.4677 6.19646C17.2315 6.32703 17.9259 6.73444 18.4271 7.34618C18.9284 7.95792 19.204 8.73428 19.205 9.53704Z"
                  fill="white"
                />
                <path
                  d="M17.5559 18.1302L12.6693 13.0616V15.1853C12.6692 15.7787 12.8193 16.3617 13.1046 16.8761C13.3899 17.3905 13.8005 17.8181 14.2952 18.1163C14.79 18.4145 15.3515 18.5727 15.9238 18.5751C16.496 18.5776 17.0588 18.4241 17.5559 18.1302ZM9.01587 7.67775L22.0873 21.2333L22.8585 20.4335L9.78708 6.87797L9.01587 7.67775Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_123">
                  <rect
                    width="17.4286"
                    height="18.0741"
                    fill="white"
                    transform="translate(7.2229 6.14816)"
                  />
                </clipPath>
              </defs>
            </svg>
          ) : null}
          <span>{participant?.username}</span>
        </div>
      </div>
    </div>
  );
};

export default observer(CustomParticipantView);
