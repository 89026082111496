import React, { useEffect } from "react";
import { Modal } from "antd";
import FormButton from "../../../../components/form/button";
import FormInput from "../../../../components/form/input";
import { useForm } from "react-hook-form";

interface Props {
  visible: boolean;
  handleAdd: (url: string) => void;
  handleCancel: () => void;
}

interface FormProps {
  url: string;
}

const PasteLinkModal = ({ visible, handleAdd, handleCancel }: Props) => {
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      url: "",
    });
  }, [reset, visible]);

  const submit = async (data: FormProps) => {
    handleAdd(data.url);
  };

  return (
    <Modal
      title={"Insert link"}
      open={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <FormInput
        type="text"
        rules={{
          required: true,
        }}
        error={errors.url}
        control={control}
        label={"Link"}
        name={"url"}
      />
      <br />
      <br />
      <FormButton loading={isSubmitting} onClick={handleSubmit(submit)}>
        Paste
      </FormButton>
    </Modal>
  );
};

export default PasteLinkModal;
