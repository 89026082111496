import CrudService from "../core/crud-service";

export default class SpaceJoinsService extends CrudService {
  static route = "api/v1/space-joins";

  async countBySpaceId(spaceId: string) {
    return await this.httpService.get<any>(
      `${this.path}/count/week/${spaceId}`
    );
  }
}
