import { observer } from "mobx-react";
const RoomHeader = () => {
  // return (
  //   <div className={styles.container}>
  //     <div>
  //       {/*<svg width="112" height="28" viewBox="0 0 112 28" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
  //       {/*    <g clipPath="url(#clip0_13_239)">*/}
  //       {/*        <path*/}
  //       {/*            d="M0.524903 7.34835H3.76153V8.8265C4.30946 8.21485 4.93385 7.76886 5.60921 7.46304C6.28457 7.15721 7.02364 7.01704 7.82643 7.01704C8.62921 7.01704 9.35554 7.20818 10.0054 7.6032C10.6553 7.99823 11.1777 8.57164 11.5728 9.3362C12.0825 8.57164 12.7196 7.99823 13.4714 7.6032C14.2232 7.20818 15.0388 7.01704 15.918 7.01704C16.8355 7.01704 17.6382 7.23367 18.3391 7.65417C19.0399 8.08742 19.5369 8.63536 19.8427 9.32346C20.1485 10.0116 20.3015 11.1329 20.3015 12.6748V20.2311H17.0393V13.6942C17.0393 12.2288 16.8609 11.2476 16.4914 10.7251C16.1219 10.2027 15.5867 9.94785 14.8604 9.94785C14.3124 9.94785 13.8155 10.1008 13.3695 10.4193C12.9235 10.7379 12.6049 11.1711 12.3883 11.7318C12.1717 12.2925 12.0697 13.1845 12.0697 14.4205V20.2439H8.80761V14C8.80761 12.8532 8.71841 12.0122 8.55275 11.4897C8.3871 10.9673 8.13225 10.585 7.7882 10.3301C7.44415 10.0753 7.03638 9.94785 6.55216 9.94785C6.01697 9.94785 5.52001 10.1135 5.08676 10.4321C4.65351 10.7506 4.3222 11.1966 4.10558 11.7828C3.88895 12.3562 3.77427 13.2737 3.77427 14.5097V20.2439H0.537645L0.524902 7.34835L0.524903 7.34835Z"*/}
  //       {/*            fill="white" stroke="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M31.6679 7.34835H34.9045V20.2439H31.6679V18.8804C31.0308 19.4793 30.4064 19.9126 29.7692 20.1802C29.1321 20.4478 28.444 20.5752 27.7049 20.5752C26.0356 20.5752 24.5957 19.9253 23.3852 18.6383C22.1746 17.3513 21.563 15.7457 21.563 13.8216C21.563 11.8338 22.1491 10.2027 23.3215 8.92844C24.4938 7.65418 25.921 7.01704 27.603 7.01704C28.3803 7.01704 29.1066 7.15721 29.782 7.45029C30.4573 7.74337 31.0817 8.17662 31.6679 8.76278V7.34835L31.6679 7.34835ZM28.2656 9.99882C27.2589 9.99882 26.4307 10.3556 25.7681 11.0565C25.1054 11.7573 24.7741 12.6748 24.7741 13.7706C24.7741 14.8792 25.1054 15.7967 25.7808 16.523C26.4562 17.2494 27.2844 17.6062 28.2656 17.6062C29.285 17.6062 30.126 17.2494 30.7886 16.5485C31.4513 15.8477 31.7953 14.9175 31.7953 13.7706C31.7953 12.6493 31.464 11.7446 30.7886 11.0437C30.1388 10.3429 29.2978 9.99882 28.2656 9.99882V9.99882Z"*/}
  //       {/*            fill="white" stroke="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M46.2454 18.6383C45.672 19.2117 45.0858 19.6195 44.4614 19.8616C43.837 20.1165 43.1234 20.2311 42.3461 20.2311C40.6131 20.2311 39.1605 19.6068 37.9627 18.358C36.7649 17.1092 36.1787 15.5291 36.1787 13.6177C36.1787 11.5662 36.8158 9.92236 38.0774 8.68633C39.2242 7.56498 40.5877 7.0043 42.1805 7.0043C42.9196 7.0043 43.6204 7.14447 44.283 7.41207C44.9456 7.67966 45.6083 8.1384 46.2709 8.76278V7.34835H49.4948V18.3962L46.2454 18.6383ZM42.8941 10.037C41.8747 10.037 41.0337 10.3811 40.371 11.0565C39.7084 11.7446 39.3771 12.5983 39.3771 13.6305C39.3771 14.7008 39.7212 15.5801 40.3965 16.2682C41.0719 16.9563 41.9256 17.3003 42.9578 17.3003C43.9645 17.3003 44.7927 16.969 45.4426 16.2937C46.0925 15.6183 46.4238 14.7391 46.4238 13.656C46.4238 12.5856 46.0925 11.7063 45.4426 11.0437C44.7672 10.3684 43.9262 10.037 42.8941 10.037V10.037Z"*/}
  //       {/*            fill="white" stroke="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M51.3809 7.34835H54.1588V8.96667C54.4646 8.32953 54.8596 7.84531 55.3566 7.51401C55.8535 7.1827 56.4015 7.01704 56.9876 7.01704C57.4081 7.01704 57.8414 7.13173 58.3001 7.34835L57.2934 10.139C56.9112 9.94785 56.6053 9.85865 56.3632 9.85865C55.8663 9.85865 55.4458 10.1645 55.1017 10.7761C54.7577 11.3878 54.592 12.5983 54.592 14.4078L54.6047 15.0322V20.2439H51.3936L51.3809 7.34835H51.3809Z"*/}
  //       {/*            fill="white" stroke="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M64.4931 7.0043C65.7036 7.0043 66.8505 7.31013 67.9209 7.92177C68.9912 8.53342 69.8323 9.36169 70.4312 10.3938C71.0301 11.4387 71.3359 12.5601 71.3359 13.7706C71.3359 14.9812 71.0301 16.128 70.4312 17.1857C69.8323 18.2433 69.004 19.0716 67.9591 19.6705C66.9142 20.2694 65.7674 20.5625 64.5058 20.5625C62.6582 20.5625 61.0781 19.8998 59.7656 18.5873C58.4531 17.2749 57.8032 15.6693 57.8032 13.7961C57.8032 11.7828 58.5423 10.1008 60.0204 8.76279C61.3329 7.59046 62.8111 7.0043 64.4931 7.0043V7.0043ZM64.5568 10.0625C63.5501 10.0625 62.7091 10.4066 62.0465 11.1074C61.3839 11.8083 61.0398 12.7003 61.0398 13.7961C61.0398 14.9175 61.3712 15.8222 62.0338 16.523C62.6964 17.2239 63.5374 17.5679 64.5441 17.5679C65.5507 17.5679 66.4045 17.2111 67.0671 16.5103C67.7297 15.8095 68.0738 14.9047 68.0738 13.7961C68.0738 12.6875 67.7425 11.7955 67.0799 11.0947C66.43 10.4066 65.5762 10.0625 64.5568 10.0625V10.0625Z"*/}
  //       {/*            fill="white" stroke="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M72.5078 7.34834H75.7827V13.554C75.7827 14.7645 75.8719 15.6056 76.0375 16.077C76.2032 16.5485 76.4708 16.9181 76.8403 17.1729C77.2098 17.4278 77.6686 17.5679 78.2038 17.5679C78.7389 17.5679 79.1977 17.4405 79.58 17.1856C79.9622 16.9308 80.2298 16.5485 80.421 16.0516C80.5611 15.682 80.6249 14.8792 80.6249 13.6687V7.34834H83.8615V12.8149C83.8615 15.0704 83.6831 16.6122 83.3263 17.4405C82.893 18.4472 82.2559 19.2245 81.4022 19.7724C80.5484 20.3203 79.478 20.5879 78.1783 20.5879C76.7638 20.5879 75.617 20.2694 74.7505 19.645C73.884 19.0206 73.2596 18.1286 72.9028 17.0073C72.648 16.23 72.5206 14.8028 72.5206 12.7385L72.5078 7.34834V7.34834Z"*/}
  //       {/*            fill="white" stroke="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M85.4927 7.34835H88.7293V8.67358C89.4684 8.0492 90.131 7.62869 90.7299 7.38658C91.3288 7.14447 91.9405 7.01704 92.5521 7.01704C93.8264 7.01704 94.9095 7.46304 95.8015 8.35502C96.5533 9.11958 96.9229 10.2409 96.9229 11.7191V20.2439H93.7245V14.5989C93.7245 13.057 93.6607 12.0376 93.5206 11.5279C93.3804 11.0182 93.1383 10.6359 92.7943 10.3684C92.4502 10.1008 92.0297 9.97333 91.52 9.97333C90.8701 9.97333 90.3094 10.19 89.838 10.6359C89.3665 11.0819 89.0479 11.6808 88.8568 12.4581C88.7676 12.8659 88.7166 13.7324 88.7166 15.0704V20.2439H85.48L85.4927 7.34835L85.4927 7.34835Z"*/}
  //       {/*            fill="white" stroke="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M108.238 2.36598H111.475V20.2439H108.238V18.8804C107.601 19.4793 106.977 19.9126 106.34 20.1802C105.702 20.4478 105.014 20.5752 104.275 20.5752C102.606 20.5752 101.166 19.9253 99.9555 18.6383C98.7449 17.3513 98.1333 15.7457 98.1333 13.8216C98.1333 11.8338 98.7195 10.2027 99.8918 8.92843C101.064 7.65417 102.491 7.01704 104.173 7.01704C104.951 7.01704 105.677 7.15721 106.352 7.45029C107.028 7.74337 107.652 8.17662 108.238 8.76278V2.36598V2.36598ZM104.836 9.99881C103.842 9.99881 103.001 10.3556 102.338 11.0565C101.676 11.7573 101.344 12.6748 101.344 13.7706C101.344 14.8792 101.676 15.7967 102.351 16.523C103.026 17.2366 103.855 17.6062 104.836 17.6062C105.855 17.6062 106.696 17.2494 107.359 16.5485C108.022 15.8477 108.366 14.9175 108.366 13.7706C108.366 12.6493 108.034 11.7446 107.359 11.0437C106.709 10.3429 105.855 9.99881 104.836 9.99881H104.836Z"*/}
  //       {/*            fill="white" stroke="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M40.2435 22.2317C40.7277 22.4866 41.3266 22.6267 42.0402 22.6267C42.7538 22.6267 43.7222 22.4993 44.2192 22.2445C44.7161 21.9896 45.4552 21.6073 45.6973 21.1104C45.8757 20.7408 46.207 19.9381 46.207 18.7275V16.4721H49.4819V17.861C49.4819 20.1165 49.2525 21.6583 48.781 22.4866C48.2076 23.4932 47.3666 24.2705 46.258 24.8185C45.1494 25.3664 43.7349 25.634 42.0147 25.634C40.1543 25.634 39.3897 25.1625 38.9819 25.0606"*/}
  //       {/*            fill="white"/>*/}
  //       {/*        <path*/}
  //       {/*            d="M40.2435 22.2317C40.7277 22.4866 41.3266 22.6267 42.0402 22.6267C42.7538 22.6267 43.7222 22.4993 44.2192 22.2445C44.7161 21.9896 45.4552 21.6073 45.6973 21.1104C45.8757 20.7408 46.207 19.9381 46.207 18.7275V16.4721H49.4819V17.861C49.4819 20.1165 49.2525 21.6583 48.781 22.4866C48.2076 23.4932 47.3666 24.2705 46.258 24.8185C45.1494 25.3664 43.7349 25.634 42.0147 25.634C40.1543 25.634 39.3897 25.1625 38.9819 25.0606"*/}
  //       {/*            stroke="white"/>*/}
  //       {/*    </g>*/}
  //       {/*    <defs>*/}
  //       {/*        <clipPath id="clip0_13_239">*/}
  //       {/*            <rect width="112" height="28" fill="white"/>*/}
  //       {/*        </clipPath>*/}
  //       {/*    </defs>*/}
  //       {/*</svg>*/}
  //
  //       <Link to={'/account'} className={styles.logo}><Logo/></Link>
  //     </div>
  //   </div>
  // );

  return null;
};

export default observer(RoomHeader);
