import React, { Fragment, useState } from "react";
import { useStore } from "../../hooks/use-store";
import { observer } from "mobx-react";
import { Image } from "antd";
import styles from "./meetingSidebar.module.scss";
import NavigationBanner from "../sidebar/navigation/NavigationBanner";

import test from "../../pages/private/account/spaces/test.gif";
import SidebarParticipant from "../sidebar/participant/SidebarParticipant";
import EditAccount from "../sidebar/editAccount/EditAccount";

const MeetingSidebar = () => {
  const {
    dataStore: { spaceStore },
    uiStore: { authStore },
  } = useStore();

  const [editAccountDrawerOpen, setEditAccountDrawerOpen] = useState(false);

  const participants = Array.from(spaceStore.participantMap.values());

  return (
    <div className={styles.container}>
      <div
        className={styles.collapse}
        onClick={() => spaceStore.collapseSideBar()}
      >
        {spaceStore.spaceSidebarCollapsed ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            >
              <rect width="16" height="16" x="4" y="4" rx="2" />
              <path d="M15 4v16M9 10l2 2l-2 2" />
            </g>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            >
              <rect width="16" height="16" x="4" y="4" rx="2" />
              <path d="M9 4v16m6-10l-2 2l2 2" />
            </g>
          </svg>
        )}
        {spaceStore.spaceSidebarCollapsed ? null : <span>Collapse</span>}
      </div>

      {spaceStore.spaceSidebarCollapsed ? null : (
        <>
          <div className={styles.menuContainer}>
            <div className={styles.spaceLogo}>
              <Image
                preview={false}
                height={100}
                width={100}
                style={{
                  borderRadius: "6px",
                  objectFit: "contain",
                }}
                alt="space logo"
                src={spaceStore.logo || test}
              />
              <span>{spaceStore.currentRoom?.name}</span>
            </div>
          </div>

          <div className={styles.roomsContainer}>
            <div className={styles.roomsHeader}>Online users:</div>

            {participants
              .filter((p) => p.roomId === spaceStore.currentRoom?.roomId)
              .map((itm) => (
                <Fragment key={itm.identityId}>
                  <SidebarParticipant
                    onClick={() => {
                      if (
                        itm.identityId === authStore.currentUser?.identityId
                      ) {
                        setEditAccountDrawerOpen(true);
                      }
                    }}
                    avatar={itm.avatar}
                    username={itm.username}
                    identityId={itm.identityId}
                    role={itm.role}
                  />
                </Fragment>
              ))}
          </div>
          <div className={styles.flexGrow} />
          <NavigationBanner />
        </>
      )}
      <EditAccount
        visible={editAccountDrawerOpen}
        onClose={() => setEditAccountDrawerOpen(false)}
      />
    </div>
  );
};

export default observer(MeetingSidebar);
