import styles from "./cursor.module.scss";
type Props = {
  color: string;
  name: string | undefined;
  x: number;
  y: number;
  zoomLevel: number;
};

export default function Cursor({ color, x, y, name, zoomLevel }: Props) {
  return (
    <div
      className={styles.container}
      style={{
        transform: `translateX(${x}px) translateY(${y}px) scale(${
          1 / zoomLevel
        })`,
      }}
    >
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.921 1.29995C1.936 0.531947 0.5 1.23395 0.5 2.48195V19.4909C0.5 20.9129 2.295 21.5369 3.177 20.4209L7.367 15.1209C7.5216 14.9256 7.71842 14.7678 7.9427 14.6594C8.16698 14.551 8.41289 14.4948 8.662 14.4949H15.514C16.942 14.4949 17.563 12.6869 16.435 11.8109L2.921 1.29895V1.29995Z"
          fill="white"
        />
      </svg>
      <span className={styles.text}>{name}</span>
    </div>
  );
}
