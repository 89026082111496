import { makeObservable, observable, toJS } from "mobx";
import { ELEMENT_TYPES } from "../../../helpers/enums";

export interface Pos {
  x: number;
  y: number;
}

export interface Size {
  w: number;
  h: number;
}

export interface ElementProps {
  id: string;
  type: ELEMENT_TYPES;
  pos: Pos;
  size: Size;
  data: object;
}

export default class Element {
  @observable id: string;
  @observable type: ELEMENT_TYPES;
  @observable pos: Pos;
  @observable size: Size;
  @observable data: object;

  constructor(props: ElementProps) {
    Object.assign(this, props);
    makeObservable(this);
  }

  toJSON() {
    return toJS(this);
  }
}
