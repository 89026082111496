import { SpaceStore } from "./space-store/space-store";
import { ElementsStore } from "./space-store/elements-store";
import { AppStore } from "./app-store/app-store";
import { ChatStore } from "./space-store/chat-store";
import { PlotStore } from "./space-store/plot-store";

class DataStore {
  spaceStore: SpaceStore = new SpaceStore();
  plotStore: PlotStore = new PlotStore();
  elementsStore: ElementsStore = new ElementsStore();
  chatStore: ChatStore = new ChatStore();
  appStore: AppStore = new AppStore();
}

export default DataStore;
