import { observer } from "mobx-react";
import styles from "./preza.module.scss";
import { useState } from "react";
import FigmaInstructions from "../addElement/FigmaInstructions";
import AddIframeElementModal from "../addElement/AddIframeElementModal";
import Button from "../../../components/button/Button";

interface Props {
  url: string;
  readonly: boolean;
  onChangeCb: (cdnUrl: string) => void;
}

enum TYPE {
  FIGMA,
  MIRO,
  PITCH,
  INVISION,
  GOOGLE,
  AIRTABLE,
  LINK,
}

const IframeComponent = ({ url, onChangeCb, readonly }: Props) => {
  // const { t } = useTranslation();

  const [iframeModalOpen, setIframeModalOpen] = useState<null | {
    name: string;
    type: TYPE;
    instructions: any;
  }>(null);

  return (
    <>
      {url ? (
        <div className={styles.image}>
          <iframe
            allow={"encrypted-media; autoplay; clipboard-read; clipboard-write"}
            title={"embed"}
            className={styles.iframe}
            // @ts-ignore
            src={url}
          />
        </div>
      ) : (
        <div className={styles.addImageWrapper}>
          <svg
            viewBox="0 0 52 52"
            style={{
              width: "52px",
              height: "52px",
            }}
          >
            <path d="M15.3697 29.7344C13.4659 31.6382 13.4659 34.7249 15.3697 36.6287C17.2736 38.5325 20.3602 38.5325 22.264 36.6287L27.4348 31.458C29.3386 29.5542 29.3386 26.4675 27.4348 24.5637C26.8002 23.9291 26.8002 22.9002 27.4348 22.2656C28.0694 21.631 29.0983 21.631 29.7329 22.2656C32.9059 25.4386 32.9059 30.5831 29.7329 33.7561L24.5621 38.9268C21.3891 42.0998 16.2447 42.0998 13.0717 38.9268C9.89864 35.7538 9.89864 30.6093 13.0717 27.4363L14.7952 25.7128C15.4298 25.0782 16.4587 25.0782 17.0933 25.7128C17.7279 26.3474 17.7279 27.3763 17.0933 28.0109L15.3697 29.7344Z"></path>
            <path d="M36.6271 22.2656C38.531 20.3618 38.531 17.2751 36.6271 15.3713C34.7233 13.4675 31.6367 13.4675 29.7329 15.3713L24.5621 20.542C22.6583 22.4459 22.6583 25.5325 24.5621 27.4363C25.1967 28.0709 25.1967 29.0998 24.5621 29.7344C23.9275 30.369 22.8986 30.369 22.264 29.7344C19.091 26.5614 19.091 21.417 22.264 18.2439L27.4348 13.0732C30.6078 9.90022 35.7522 9.90022 38.9252 13.0732C42.0983 16.2462 42.0983 21.3907 38.9252 24.5637L37.2017 26.2873C36.5671 26.9219 35.5382 26.9219 34.9036 26.2873C34.269 25.6527 34.269 24.6238 34.9036 23.9892L36.6271 22.2656Z"></path>
          </svg>
          {!readonly ? (
            <Button
              type="primary"
              onClick={() => {
                setIframeModalOpen({
                  type: TYPE.FIGMA,
                  name: "Embed Figma",
                  instructions: <FigmaInstructions />,
                });
              }}
            >
              Add iframe
            </Button>
          ) : null}
        </div>
      )}

      <AddIframeElementModal
        name={iframeModalOpen?.name}
        instructions={iframeModalOpen?.instructions}
        visible={!!iframeModalOpen}
        handleAdd={(url) => {
          let targetUrl = url;
          if (iframeModalOpen?.type === TYPE.MIRO) {
            targetUrl = `https://miro.com/app/live-embed/${url.replace(
              "https://miro.com/app/board/",
              ""
            )}`;
          }

          if (iframeModalOpen?.type === TYPE.FIGMA) {
            // 'https://www.figma.com/file/M3rEDtIcvkZrwZCSRAPyHp/VSBLE-2.0-ver.-3?node-id=0%3A1'
            targetUrl = `https://www.figma.com/embed?embed_host=share&url=${encodeURIComponent(
              url
            )}`;
          }

          // if (iframeModalOpen?.type === TYPE.LINK) {
          //   elementsStore.addElement(ELEMENT_TYPES.LINK, {
          //     url: targetUrl,
          //   });
          // } else {
          //   elementsStore.addElement(ELEMENT_TYPES.IFRAME, {
          //     url: targetUrl,
          //   });
          // }

          onChangeCb(targetUrl);
          setIframeModalOpen(null);
        }}
        handleCancel={() => setIframeModalOpen(null)}
      />
    </>
  );
};

export default observer(IframeComponent);
