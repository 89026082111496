import React, { useEffect, useState } from "react";
import { Drawer, Form, Col, Row, Space, Avatar } from "antd";
import { useForm } from "react-hook-form";
import { Widget } from "@uploadcare/react-widget";
import Button from "../../button/Button";
import FormInput from "../../form/input";
import FormButton from "../../form/button";

import styles from "./editSpace.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  initialData: {
    name?: string;
    logo?: string;
  };
  submit: (model: { name: string; logo: string | null }) => void;
  visible: boolean;
}

interface FormProps {
  name: string;
  password?: string;
  privat: boolean;
}

const EditSpaceDrawer = (props: Props) => {
  const { t } = useTranslation();
  const [logo, setLogo] = useState<string | null>(null);

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      name: "",
      privat: false,
    });
  }, [reset]);

  useEffect(() => {
    if (props.initialData) {
      setValue("name", props.initialData?.name || "");

      if (props.initialData?.logo) {
        setLogo(props.initialData?.logo);
      } else {
        setLogo("");
      }
    }
  }, [props.initialData, props.initialData?.name, setValue, reset]);

  const submit = async (data: FormProps) => {
    await props.submit({
      name: data.name,
      logo: logo || null,
    });
  };

  return (
    <Drawer
      title={t("edit.space.header", {
        defaultValue: "Edit space",
      })}
      width={480}
      onClose={props.onClose}
      open={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={props.onClose}>
            {t("common.cancel", {
              defaultValue: "Cancel",
            })}
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <div className={styles.logo}>
              <Avatar
                style={{
                  backgroundColor: "#a8a8a8",
                  borderRadius: "6px",
                  height: "140px",
                  width: "140px",
                }}
                size={120}
                src={logo}
              />
              <div>
                <Widget
                  localeTranslations={{
                    buttons: {
                      choose: {
                        files: {
                          one: t("edit.upload.logo", {
                            defaultValue: "Upload space logo",
                          }),
                        },
                      },
                    },
                  }}
                  clearable={true}
                  // @ts-ignore
                  onChange={(info: { cdnUrl: string }) => {
                    setLogo(info.cdnUrl);
                  }}
                  tabs={"file camera url"}
                  publicKey="f4d9f840695d97e9cbb7"
                />
                {logo ? (
                  <>
                    &nbsp;
                    <Button
                      type="primary"
                      onClick={() => {
                        setLogo("");
                      }}
                    >
                      {t("edit.clear.logo", {
                        defaultValue: "Clear logo",
                      })}
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
            <FormInput
              type="text"
              rules={{
                required: true,
                minLength: 1,
              }}
              error={errors.name}
              control={control}
              label={t("edit.space.name", {
                defaultValue: "Space name",
              })}
              name={"name"}
            />

            <br />
            <FormButton loading={isSubmitting} onClick={handleSubmit(submit)}>
              {t("common.save", {
                defaultValue: "Save",
              })}
            </FormButton>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditSpaceDrawer;
