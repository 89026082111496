import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks/use-store";
import styles from "./grid.module.scss";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";

const Grid = () => {
  const {
    dataStore: { plotStore },
  } = useStore();

  return (
    <div
      className={styles.trigger}
      onClick={() => {
        plotStore.setGridVisibility(!plotStore.displayGrid);
      }}
    >
      <svg className={plotStore.displayGrid ? styles.selected : ""}>
        <use xlinkHref={`${remixiconUrl}#ri-pencil-ruler-2-fill`} />
      </svg>
    </div>
  );
};

export default observer(Grid);
