import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "antd";
import { useForm } from "react-hook-form";
import { Widget } from "@uploadcare/react-widget";
import { useStore } from "../../../hooks/use-store";
import FormInput from "../../../components/form/input";
import FormButton from "../../../components/form/button";
import Logo from "../../../components/Logo";

import styles from "./createAccountForm.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface FormProps {
  firstName: string;
  lastName: string;
  email: string;
}

const CreateAccountForm = () => {
  const { t } = useTranslation();

  const {
    uiStore: { authStore },
  } = useStore();

  const {
    control,
    reset,
    // setValue,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  const [avatar, setAvatar] = useState<string | undefined>(undefined);

  useEffect(() => {
    reset({
      firstName: "",
      lastName: "",
      email: "",
    });
  }, [reset]);

  const login = async (data: FormProps) => {
    await authStore.register({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      avatar,
      password: "CakeWorkPass",
    });
  };

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url("/backgrounds/default/bg10.jpg")` }}
    >
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.card}>
        <Typography.Title level={3}>
          Hellooo. Tell about yourself a little bit more before jumping in
        </Typography.Title>

        <div className={styles.avatar}>
          <Avatar size={98} src={avatar}></Avatar>
          <Widget
            localeTranslations={{
              buttons: {
                choose: {
                  files: {
                    one: "Upload your avatar",
                  },
                },
              },
            }}
            clearable={true}
            // @ts-ignore
            onChange={(info: { cdnUrl: string }) => {
              setAvatar(info.cdnUrl);
            }}
            tabs={"file camera url"}
            publicKey="f4d9f840695d97e9cbb7"
          />
        </div>

        <FormInput
          type="email"
          rules={{
            required: true,
            minLength: 1,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          }}
          error={errors.email}
          control={control}
          label={"Email"}
          name={"email"}
        />
        <FormInput
          type="text"
          rules={{
            required: true,
            minLength: 1,
          }}
          error={errors.firstName}
          control={control}
          label={"First name"}
          name={"firstName"}
        />

        <FormInput
          type="text"
          rules={{
            required: true,
            minLength: 1,
          }}
          error={errors.lastName}
          control={control}
          label={"Last name"}
          name={"lastName"}
        />
        <br />
        <FormButton loading={isSubmitting} onClick={handleSubmit(login)}>
          Enter meeting
        </FormButton>
      </div>
      <div className={styles.note}>
        <span>
          {t("registerpage.already.have", {
            defaultValue: "Already have an account? Login",
          })}
          &nbsp;
          <Link to={"/login"}>
            {t("registerpage.here", { defaultValue: "here" })}
          </Link>
        </span>
      </div>
    </div>
  );
};

export default CreateAccountForm;
