import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import throttle from "lodash/throttle";
import {useEditor, EditorContent} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Highlight from '@tiptap/extension-highlight'
// import Placeholder from '@tiptap/extension-placeholder'
import MenuBar from './Menubar'
import styles from './text.module.scss'

interface Props {
    content: any;
    timestamp: number;
    onChangeText: (json: string, date: number) => void;
}

const Text = ({content, timestamp, onChangeText}: Props) => {
    const [lastChange, setLastChange] = useState<null | number>(null)
    const debouncedChangeHandler = throttle((...params) => {
        const date = Date.now()
        setLastChange(date + 1)
        onChangeText(params, date)
    }, 400);

    const editor = useEditor({
        onUpdate({editor}) {
            debouncedChangeHandler(JSON.stringify(editor.getJSON()));
        },
        extensions: [
            StarterKit.configure({
                history: false,
            }),
            Highlight,
            TaskList,
            TaskItem,
        ],
        content: `
      <h2>
        Hello there!
      </h2>
      <br/>
      <br/>
      <br/>
      <br/>
      <p>This is placeholder</p>
    `,
    });

    useEffect(() => {
        if (content) {
            if (lastChange) {
                if (timestamp < lastChange) {
                    return
                }
            }
            try {
                editor?.commands.setContent(JSON.parse(content));
            } catch (e) {
                editor?.commands.setContent(content);
            }
        }
    }, [content, timestamp, lastChange, editor]);

    if (!editor) {
        return null
    }
    return (
        <>
            <MenuBar editor={editor}/>
            <div className={styles.editor}>
                <EditorContent placeholder={'Enter your notes'} editor={editor}/>
            </div>
        </>
    );
};

export default observer(Text);
