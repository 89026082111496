import { action, makeObservable, observable } from "mobx";
import { io, Socket } from "socket.io-client";
import { getUserIdFromSession } from "../../../helpers/helper";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";

export type Message = {
  created_at: string;
  id: string;
  text: string;
  user: {
    id: number;
    avatar: string;
    firstName: string;
    lastName: string;
  };
};

export class ChatStore {
  ready: boolean = false;
  socket: Socket;
  @observable identity: string;
  @observable messages: Message[] = [];
  @observable currentRoomId: string;
  @observable unreadCounter = 0;
  @observable chatVisible: boolean = false;

  constructor() {
    makeObservable(this);
  }

  async disconnect() {
    if (this.ready) {
      this.socket.disconnect();
    }
  }

  async connectSocket(model: { roomId: string }) {
    this.socket.on("connect", () => {
      this.ready = true;

      this.socket.emit(
        "join",
        {
          roomId: model.roomId,
        },
        (res: Message[]) => {
          this.messages = res;
        }
      );
    });
  }

  async connect(roomId: string, identity: string) {
    this.ready = false;
    this.currentRoomId = roomId;
    this.identity = identity;
    this.socket = io(getEnv().envConfig.api_end_point, {
      path: "/chat/socket.io",
      transports: ["websocket"],
    });

    this.socket.io.on("open", async () => {
      await this.connectSocket({ roomId: this.currentRoomId });
    });

    this.socket.on("messageAdded", (data: Message) => {
      this.messages = [...this.messages, data];

      if (data.user.id !== getUserIdFromSession(this.identity)) {
        this.unreadCounter = this.unreadCounter + 1;
      }
    });
  }

  @action
  changeRoom = async (roomId: string) => {
    this.socket.emit(
      "changeRoom",
      {
        fromRoomId: this.currentRoomId,
        toRoomId: roomId,
      },
      (res: Message[]) => {
        this.unreadCounter = 0;
        this.messages = res;
      }
    );

    this.currentRoomId = roomId;
  };

  @action
  send = async (text: string) => {
    this.socket.emit(
      "addMessage",
      {
        roomId: this.currentRoomId,
        identityId: this.identity,
        text: text,
      },
      (res: any) => {}
    );
  };

  @action
  readMessages = () => {
    this.unreadCounter = 0;
  };

  @action
  show = () => {
    this.chatVisible = true;
    this.readMessages();
  };

  @action
  hide = () => {
    this.chatVisible = false;
  };
}
