import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import WorkspaceItem from "../item/WorkspaceItem";

const ElementsBase = () => {
  const {
    dataStore: { elementsStore },
  } = useStore();

  const elements = Array.from(elementsStore.elementsMap.values());

  if (elements.length === 0) {
    return null;
  }
  return (
    <>
      {elements.map((itm) => (
        <Fragment key={itm.id}>
          <WorkspaceItem
            key={itm.id}
            id={itm.id}
            pos={{
              x: itm.pos.x,
              y: itm.pos.y,
            }}
            size={{
              w: itm.size.w,
              h: itm.size.h,
            }}
            data={itm.data}
            type={itm.type}
          />
        </Fragment>
      ))}
    </>
  );
};

export default observer(ElementsBase);
