import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import FormInput from "../../../components/form/input";
import FormButton from "../../../components/form/button";
import styles from "./resetPassword.module.scss";
import { Link, useParams } from "react-router-dom";
import Logo from "../../../components/Logo";
import { useTranslation } from "react-i18next";

interface FormProps {
  password: string;
}

const ResetPassword = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const { hash } = useParams<{ hash: string }>();

  const [success, showSuccess] = useState(false);
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      password: "",
    });
  }, [reset]);

  const login = async (data: FormProps) => {
    const res = await authStore.resetPassword(data.password, hash);

    if (res.ok) {
      showSuccess(true);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.card}>
        <Typography.Title level={2}>
          {t("reset.header", { defaultValue: "Reset your password" })}
        </Typography.Title>

        {success ? (
          <span>
            {t("reset.success", {
              defaultValue: "Success! Now you can login with new password!",
            })}
          </span>
        ) : (
          <>
            <FormInput
              type="password"
              rules={{
                required: true,
              }}
              error={errors.password}
              control={control}
              label={t("reset.new.password", {
                defaultValue: "Enter new password",
              })}
              name={"password"}
            />

            <FormButton loading={isSubmitting} onClick={handleSubmit(login)}>
              {t("reset.change", { defaultValue: "Change password" })}
            </FormButton>
          </>
        )}
      </div>

      <div className={styles.note}>
        <span>
          <span>
            {t("reset.already.have", {
              defaultValue: "Already have an account? Login",
            })}
            &nbsp;
            <Link to={"/login"}>
              {t("reset.here", { defaultValue: "here" })}
            </Link>
          </span>
        </span>
      </div>
    </div>
  );
};

export default ResetPassword;
