import React, { useEffect } from "react";
import { Drawer, Form, Col, Row, Space } from "antd";
import { useForm } from "react-hook-form";
import Button from "../../button/Button";
import FormInput from "../../form/input";
import FormButton from "../../form/button";

import { Collapse } from "antd";

import styles from "./editSpaceBilling.module.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import { TARIFFS } from "../../../helpers/enums";
import { NOTIFICATION_SERVICE } from "../../notifications/NotificationService";
const { Panel } = Collapse;

interface Props {
  onClose: () => void;
  submit: (model: { code: string }) => void;
  visible: boolean;
}

interface FormProps {
  code: string;
}

const EditSpaceBillingDrawer = (props: Props) => {
  const { t } = useTranslation();

  const {
    dataStore: { spaceStore },
  } = useStore();

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      code: "",
    });
  }, [reset]);

  const submit = async (data: FormProps) => {
    await spaceStore.applyLifetimeCode({
      code: data.code,
      cb: (res) => {
        console.log(res);
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(res.error);
        } else {
          NOTIFICATION_SERVICE.successNotification(
            "Upgraded! Please refresh browser."
          );
          window.location.reload();
        }
      },
    });
  };

  return (
    <Drawer
      title={t("", {
        defaultValue: "Space billing",
      })}
      width={520}
      onClose={props.onClose}
      open={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={props.onClose}>
            {t("common.cancel", {
              defaultValue: "Cancel",
            })}
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            {" "}
            <div className={styles.section}>
              <span className={styles.title}>
                {t("", { defaultValue: "Current Plan:" })}
              </span>

              {spaceStore.plan === TARIFFS.FREE ? (
                <div className={styles.customPlan}>
                  <div>
                    <div>
                      <div className={styles.planPrice}>
                        <span>Cake is currently FREE ;)</span>
                      </div>
                      <div className={styles.planName}>
                        <span>
                          Benefit of all features for free as early adopter
                          while we are in beta. You can keep this plan even when
                          we come up with a proper pricing. During the launch
                          some features are limited.
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className={styles.planFeatures}>
                        <ul>
                          <li>Video call limit is 60 minutes. </li>
                          <li>You can create 5 rooms</li>
                          <li>Max. 5 members per room</li>
                          <li>Basic room customization</li>
                        </ul>

                        <a
                          className={styles.special}
                          href="https://buy.stripe.com/fZedR6aeUfizfGUcMM"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Become supporter and unlock all features forever with
                          a one time payment of 129€‎
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.customPlan}>
                  <div>
                    <div>
                      <div className={styles.planPrice}>
                        <span>Cake's Unlimited Plan</span>
                      </div>
                    </div>
                    <div>
                      <div className={styles.planFeatures}>
                        <ul>
                          <li>Unlimited meeting time. </li>
                          <li>Max. 50 users per space</li>
                          <li>Unlimited rooms</li>
                          <li>Apps like Figma, Google Docs, Miro</li>
                          <li>Advanced room customization</li>
                          <li>Unlimited Media uploads</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.section}>
              <span className={styles.title}>
                {t("", { defaultValue: "FAQ" })}
              </span>
              <Collapse defaultActiveKey={["0"]} ghost>
                {/* <Panel
                  header={t("", {
                    defaultValue: "I want to upgrade plan, how can i do this?",
                  })}
                  key="1"
                  className={styles.panelHeader}
                >
                  <p className={styles.panelText}>
                    {t("", {
                      defaultValue:
                        "No worries, contact us by the link below and we will send you a special subscription offer",
                    })}
                  </p>
                </Panel>
                <Panel
                  header={t("", {
                    defaultValue:
                      "I don't want to pay every month.",
                  })}
                  key="2"
                  className={styles.panelHeader}
                >
                  <p className={styles.panelText}>
                    {t("", {
                      defaultValue:
                        "Yep, we offer a lifetime deal. Pay here: https://buy.stripe.com/fZedR6aeUfizfGUcMM",
                    })}
                  </p>
                </Panel> */}
                <Panel
                  header={t("", {
                    defaultValue:
                      "I have code for lifetime deal. Where i can active activate it?",
                  })}
                  key="1"
                  className={styles.panelHeader}
                >
                  <p className={styles.panelText}>
                    {t("", {
                      defaultValue: "Right here:",
                    })}
                  </p>
                  <Form layout="vertical" hideRequiredMark>
                    <FormInput
                      type="text"
                      rules={{
                        required: true,
                        minLength: 1,
                      }}
                      error={errors.code}
                      control={control}
                      placeholder="Code"
                      name={"code"}
                    />

                    <FormButton
                      loading={isSubmitting}
                      onClick={handleSubmit(submit)}
                    >
                      {t("common.save", {
                        defaultValue: "Save",
                      })}
                    </FormButton>
                  </Form>
                </Panel>
              </Collapse>
            </div>
            <div className={styles.section}>
              <span className={styles.title}>
                {t("side.help", {
                  defaultValue: "Help and support",
                })}
              </span>

              {/* <a
                href="https://my.perkzilla.com/t91"
                target={"_blank"}
                rel="noreferrer"
              >
                <div className={styles.item}>
                  <div>
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="sc-dlfnuX bJgQKs"
                    >
                      <path
                        d="M12 7C12 5 9.5 2.5 6.5 2.5C3.5 2.5 1 5 1 8C1 12.7978 8.03364 18.8745 10.8522 21.117C11.5308 21.6569 12.4692 21.6569 13.1478 21.117C15.9664 18.8745 23 12.7978 23 8C23 5 20.5 2.5 17.5 2.5C14.5 2.5 12 5 12 7Z"
                        fill="#FFD5D5"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                        stroke-miterlimit="10"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <span>
                      {t("side.share", {
                        defaultValue: "Share Cake with friends",
                      })}
                    </span>
                    <span>
                      {t("side.help.us", {
                        defaultValue:
                          "Help us spread the word and get up to 100% off any Cake subscription plan!",
                      })}
                    </span>
                  </div>
                </div>
              </a> */}
              <a
                href="https://309sov53iwj.typeform.com/to/Ti5MBxu8"
                target={"_blank"}
                rel="noreferrer"
              >
                <div className={styles.item}>
                  <div>
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="sc-dlfnuX bJgQKs"
                    >
                      <path
                        d="M1.13999 9.24697C1.23505 8.38153 1.89784 7.73014 2.7638 7.64006C3.47749 7.56582 4.45552 7.5 5.68 7.5C7.29546 7.5 8.48198 7.61457 9.19841 7.71188C9.66814 7.77569 9.99353 8.18587 9.98784 8.65988C9.97953 9.35278 9.97 10.4785 9.97 12C9.97 13.3489 9.97749 14.3868 9.98498 15.0877C9.99162 15.7087 9.56459 16.2453 8.94814 16.3203C8.21893 16.409 7.11984 16.5 5.68 16.5C4.45552 16.5 3.47749 16.4342 2.7638 16.3599C1.89784 16.2699 1.23505 15.6185 1.13999 14.753C1.06514 14.0715 1 13.1485 1 12C1 10.8515 1.06514 9.92852 1.13999 9.24697Z"
                        fill="#BFF9EA"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                      ></path>
                      <path
                        d="M10 8.41433C10 7.91395 10.1876 7.43174 10.5257 7.06288L14.7099 2.49829C15.5897 1.53855 17.1644 2.07835 17.2403 3.37808C17.3685 5.57252 17.5 8.64276 17.5 12C17.5 15.3572 17.3685 18.4275 17.2403 20.6219C17.1644 21.9216 15.5897 22.4614 14.7099 21.5017L10.5257 16.9371C10.1876 16.5683 10 16.0861 10 15.5857V8.41433Z"
                        fill="#BFF9EA"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                      ></path>
                      <path
                        d="M20.4758 12.4157L22.9758 12.4013M19.9586 9.41862L22.4499 7.90427M19.9931 15.4185L22.5016 16.9041"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M3.89216 17.2353C3.71453 16.9022 3.95587 16.5 4.33333 16.5H6.5C6.86931 16.5 7.20856 16.7035 7.38235 17.0294L8.71569 19.5294C9.07094 20.1955 8.58825 21 7.83333 21H6.5C6.13069 21 5.79144 20.7965 5.61765 20.4706L3.89216 17.2353Z"
                        fill="#BFF9EA"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <span>
                      {t("side.contact.us", {
                        defaultValue: "Contact us",
                      })}
                    </span>
                    <span>
                      {t("side.chat", {
                        defaultValue: "Chat with a Cake team member",
                      })}
                    </span>
                  </div>
                </div>
              </a>

              <a
                href="https://feedback.cake.work/"
                target={"_blank"}
                rel="noreferrer"
              >
                <div className={styles.item}>
                  <div>
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="sc-dlfnuX bJgQKs"
                    >
                      <path
                        d="M3 6.5C3.82843 6.5 4.5 5.82843 4.5 5C4.5 4.17157 3.82843 3.5 3 3.5C2.17157 3.5 1.5 4.17157 1.5 5C1.5 5.82843 2.17157 6.5 3 6.5Z"
                        fill="#CEE8FE"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                      ></path>
                      <path
                        d="M19.8939 19.5L18.4797 20.9142L19.8939 22.3284L21.3081 20.9142L19.8939 19.5Z"
                        fill="#0A0B1E"
                      ></path>
                      <path
                        d="M21.8001 15.4C22.7001 14.5 20.6001 10.8 17.0001 7.2C13.4001 3.6 9.80006 1.5 8.80006 2.4C6.40006 4.9 2.70006 16.8 1.60006 21.3C1.30006 22.2 2.10006 23 3.00006 22.7C7.50006 21.6 19.4001 17.8 21.8001 15.4Z"
                        fill="#CEE8FE"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M21.7099 15.3726C22.6472 14.4353 20.5261 10.7947 16.9724 7.24092C13.4186 3.68718 9.77797 1.5661 8.84072 2.50335C7.90347 3.4406 10.0245 7.08126 13.5783 10.635C17.132 14.1887 20.7727 16.3098 21.7099 15.3726Z"
                        fill="#CEE8FE"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M6.30005 7C7.00005 8.6 8.70005 11 11 13.3C13.3 15.6 15.6 17.3 17.3 18"
                        fill="transparent"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M15.5 8.5C17 6.66667 20.5 3.5 23 3M12.5 6.5C13.5 5.66667 15 3 15 1M17.5 11C18.3333 10.1667 20.5 8.5 23 10"
                        fill="transparent"
                        stroke="#0A0B1E"
                        stroke-width="1px"
                        stroke-linecap="round"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <span>
                      {t("side.feature", {
                        defaultValue: "Have a feature request?",
                      })}
                    </span>
                    <span>
                      {t("side.submit", {
                        defaultValue:
                          "Submit it on feedback portal and track progress!",
                      })}
                    </span>
                  </div>
                </div>
              </a>
            </div>
            {/*           
            <FormInput
              type="text"
              rules={{
                required: true,
                minLength: 1,
              }}
              error={errors.name}
              control={control}
              label={t("edit.space.name", {
                defaultValue: "Space name",
              })}
              name={"name"}
            />

            <br />
            <FormButton loading={isSubmitting} onClick={handleSubmit(submit)}>
              {t("common.save", {
                defaultValue: "Save",
              })}
            </FormButton> */}
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default observer(EditSpaceBillingDrawer);
