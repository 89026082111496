import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import styles from "./preza.module.scss";
import PrezaPage from "./PrezaPage";
import { Avatar, Button } from "antd";
import { Fragment, useState } from "react";
import EditPlotDrawer from "./editPlotDrawer/EditPlotDrawer";
import { PLOT_MODE } from "../../../types";
import ParticipantAvatar from "./participantAvatar/ParticipantAvatar";
import Preview from "./preview/Preview";

// https://react-resizable-panels.vercel.app/examples/nested
const Preza = (props: { onCloseButtonClicked?: () => void }) => {
  const {
    dataStore: { plotStore },
  } = useStore();
  const participants = Array.from(plotStore.participantMap.values());

  const [editModalData, setEditModalData] = useState<{
    name: string;
    password: string;
    plotId: string;
    mode: PLOT_MODE;
  } | null>(null);
  const page = plotStore.activePageId;

  return (
    <div>
      <div className={styles.nameContainer}>
        <div className={styles.left}>
          {plotStore.logo ? (
            <img src={plotStore.logo} alt="logo" className={styles.logo} />
          ) : null}
          <span>{plotStore.name}</span>
        </div>
        <div className={styles.right}>
          <Avatar.Group maxCount={8}>
            {participants.map((rp) => (
              <Fragment key={rp.identityId}>
                <ParticipantAvatar
                  size={28}
                  username={rp.username}
                  identityId={rp.identityId}
                  avatar={rp.avatar}
                />
              </Fragment>
            ))}
          </Avatar.Group>
          {plotStore.readonly ? null : (
            <Button
              type="text"
              onClick={() => {
                setEditModalData({
                  name: plotStore.name,
                  password: plotStore.password,
                  plotId: plotStore.plotId,
                  mode: plotStore.mode,
                });
              }}
            >
              Share & Settings
            </Button>
          )}
          {props.onCloseButtonClicked ? (
            <Button type="text" onClick={props.onCloseButtonClicked}>
              Close Slide
            </Button>
          ) : null}

          <EditPlotDrawer
            visible={!!editModalData}
            onClose={() => {
              setEditModalData(null);
            }}
            initialData={{
              name: editModalData?.name!,
              password: editModalData?.password!,
              mode: editModalData?.mode!,
              plotId: editModalData?.plotId!,
            }}
            submit={(model) => {
              plotStore.changePlot({
                name: model.name,
                password: model.password,
                mode: model.mode,
              });
              setEditModalData(null);
            }}
          />
        </div>
      </div>
      <div className={styles.allContainer}>
        {plotStore.background ? (
          <div
            style={{ backgroundImage: `url('${plotStore.background}')` }}
            className={styles.backgroundImage}
          ></div>
        ) : null}
        {page ? (
          <PrezaPage
            layout={plotStore.pages.get(page)!}
            onChangeLayout={(lay) => {
              plotStore.updateLayout(lay);
            }}
          />
        ) : null}
        <Preview></Preview>
      </div>
    </div>
  );
};

export default observer(Preza);
