import React, { useEffect } from "react";
import { Layout } from "antd";
import RoomHeader from "../../components/roomHeader/roomHeader";

import { observer } from "mobx-react";
import { useStore } from "../../hooks/use-store";
import styles from "./space.module.scss";
import Room from "./livekitRoom/LivekitRoom";
import FullscreenLoader from "../space/fullscreenLoader/Loader";
import ReconnectIndicator from "../space/reconnectIndicator/ReconnectIndicator";
import { RTC_WD } from "../../helpers/enums";
import Plot from "../../pages/public/plot/Plot";
import Chat from "../../components/chat/Chat";
import { nanoid } from "nanoid";
import MeetingSidebar from "../../components/meetingSidebar/MeetingSidebar";
import { ROLE } from "../../types";
import Workspace from "./Workspace";
// import DyteRoom from "../../pages/experiment/DyteRoom";
const { Content, Sider } = Layout;

const Meeting = (model: { spaceId: string; roomId: string }) => {
  const {
    uiStore: { authStore },
    dataStore: { spaceStore, elementsStore, chatStore, plotStore },
  } = useStore();

  const identityId = authStore.currentUser
    ? authStore.currentUser.identityId
    : nanoid();
  const username = authStore.currentUser
    ? `${authStore.currentUser!.firstName} ${authStore.currentUser!.lastName}`
    : nanoid();
  const avatar = authStore.currentUser
    ? authStore.currentUser!.avatar
    : nanoid();

  useEffect(() => {
    if (spaceStore.role === ROLE.COLLABORATOR) {
      plotStore.setReadonlyMode(false);
    } else {
      plotStore.setReadonlyMode(true);
    }
  }, [plotStore, spaceStore.role]);

  useEffect(() => {
    spaceStore.connect(
      identityId,
      model.spaceId,
      username,
      avatar,
      model.roomId
    );
    elementsStore.connect(model.roomId, identityId);
    chatStore.connect(model.roomId, identityId);

    return () => {
      (async () => await spaceStore.disconnect())();
      (async () => await elementsStore.disconnect())();
      (async () => await chatStore.disconnect())();
    };
  }, [
    model.spaceId,
    spaceStore,
    authStore,
    elementsStore,
    chatStore,
    model.roomId,
    username,
    avatar,
    identityId,
  ]);

  console.log(spaceStore.currentRoom?.roomId);
  return (
    <Layout>
      <FullscreenLoader />
      <ReconnectIndicator />
      <Content>
        <Layout className="site-layout-background">
          <Sider
            collapsedWidth={20}
            collapsed={spaceStore.spaceSidebarCollapsed}
            width={320}
          >
            <MeetingSidebar />
          </Sider>

          <RoomHeader />
          <Content
            style={{ minHeight: 280, height: "100vh", position: "relative" }}
          >
            {spaceStore.ready ? (
              spaceStore.currentRoom?.receptionRoom ? null : (
                <>
                  {spaceStore.streamToken ? (
                    <Room url={RTC_WD} token={spaceStore.streamToken} />
                  ) : null}

                  {chatStore.chatVisible ? (
                    <div className={styles.chat}>
                      <Chat />
                    </div>
                  ) : null}
                  <div id={"rtc-controls"} />
                  {spaceStore.currentRoom?.plotId ? (
                    <>
                      <Plot
                        noConnect={false}
                        username={username}
                        avatar={avatar}
                        identityId={identityId}
                        plotId={spaceStore.currentRoom?.plotId}
                      />
                      <div
                        className={styles.shareScreenContainer}
                        id={"screenshare-slides-container"}
                      />
                    </>
                  ) : (
                    <Workspace />
                  )}
                </>
              )
            ) : null}
          </Content>
          <div id="slide-video-room"></div>
        </Layout>
      </Content>
    </Layout>
  );
};

export default observer(Meeting);
