import React, { useRef, useEffect } from "react";
import { useStore } from "../../../hooks/use-store";
// @ts-ignore
import NotificationSound from "./notification-sound.mp3";
import { observer } from "mobx-react";

const SoundNotification = () => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const audioPlayer = useRef(null);

  console.log(spaceStore.soundNotify);
  useEffect(() => {
    if (spaceStore.soundNotify) {
      if (audioPlayer && audioPlayer.current) {
        try {
          // @ts-ignore
          audioPlayer.current.play().catch((e) => {
            console.log(e);
          });
        } catch (e) {}
      }
      spaceStore.stopSoundNotification();
    }
  }, [spaceStore.soundNotify, spaceStore]);

  return (
    <audio
      // autoplay=""
      // // @ts-ignore
      // muted=""
      // playsinline=""
      style={{ display: "none" }}
      ref={audioPlayer}
      src={NotificationSound}
    />
  );
};

export default observer(SoundNotification);
