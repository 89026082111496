import React, { useEffect } from "react";

import { Typography } from "antd";
import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import styles from "./register.module.scss";
import Logo from "../../../components/Logo";
import FormInput from "../../../components/form/input";
import FormButton from "../../../components/form/button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface FormProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const Register = () => {
  const {
    uiStore: { authStore },
  } = useStore();

  const { t } = useTranslation();

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });
  }, [reset]);

  const register = async (data: FormProps) => {
    const res = await authStore.register({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    });
    if (res.ok) {
      // @ts-ignore
      window.location = "/account?survey=yep";
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.background}>
        {/*<a*/}
        {/*  href="https://my.perkzilla.com/t91"*/}
        {/*  target={"_blank"}*/}
        {/*  rel="noreferrer"*/}
        {/*  className={styles.text}*/}
        {/*>*/}
        {/*  <span className={styles.mainText}>*/}
        {/*    Share Cake with your friends & get rewards!*/}
        {/*  </span>*/}
        {/*  <br />*/}
        {/*  <span className={styles.description}>*/}
        {/*    Help us spread the word and get up to 100% Off any Cake subscription*/}
        {/*    plan!*/}
        {/*  </span>*/}
        {/*</a>*/}
        <div
          className={styles.cakeLogo}
          style={{ backgroundImage: `url("/public/cake.svg")` }}
        />
      </div>
      <div>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.card}>
          <Typography.Title level={2}>
            {t("registerpage.create.free", {
              defaultValue: "Create FREE account",
            })}
          </Typography.Title>

          <FormInput
            type="text"
            rules={{
              required: true,
            }}
            error={errors.firstName}
            control={control}
            label={t("registerpage.first.name", { defaultValue: "First name" })}
            name={"firstName"}
          />
          <FormInput
            type="text"
            rules={{
              required: true,
            }}
            error={errors.lastName}
            control={control}
            label={t("registerpage.last.name", { defaultValue: "Last name" })}
            name={"lastName"}
          />
          <FormInput
            type="email"
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            }}
            error={errors.email}
            control={control}
            label={t("registerpage.email", { defaultValue: "Email" })}
            name={"email"}
          />
          <FormInput
            type="password"
            rules={{
              required: true,
            }}
            error={errors.password}
            control={control}
            label={t("registerpage.password", { defaultValue: "Password" })}
            name={"password"}
          />
          <span>
            {t("registerpage.agree", {
              defaultValue: "By signing up, i have read and agreed",
            })}
            &nbsp;
            <a
              href={"https://www.cake.work/legal/terms-of-use"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {t("registerpage.terms", { defaultValue: "Terms" })}
            </a>
            &nbsp;
            {t("registerpage.and", { defaultValue: "and" })}
            &nbsp;
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href={"https://www.cake.work/legal/privacy"}
            >
              {t("registerpage.conditions", { defaultValue: "Conditions" })}
            </a>
          </span>
          <br />
          <br />
          <FormButton loading={isSubmitting} onClick={handleSubmit(register)}>
            {t("registerpage.create.button", {
              defaultValue: "Create free account",
            })}
          </FormButton>
        </div>

        <div className={styles.note}>
          <span>
            {t("registerpage.already.have", {
              defaultValue: "Already have an account? Login",
            })}
            &nbsp;
            <Link to={"/login"}>
              {t("registerpage.here", { defaultValue: "here" })}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Register;
