import CrudService from "../core/crud-service";

export default class BillingService extends CrudService {
  static route = "api/v1/billing";

  async getCheckoutLink(planId: string) {
    return await this.httpService.get<any>(`${this.path}/upgrade/${planId}`);
  }

  async getCustomerPortalLink() {
    return await this.httpService.get<any>(`${this.path}/portal`);
  }
}
