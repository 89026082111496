import React, { useEffect, useState } from "react";
import { Drawer, Form, Col, Row, Space, Segmented } from "antd";

import { useForm } from "react-hook-form";
import Button from "../../../../components/button/Button";
import FormInput from "../../../../components/form/input";
import FormButton from "../../../../components/form/button";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { NOTIFICATION_SERVICE } from "../../../../components/notifications/NotificationService";

import styles from "./editPlotDrawer.module.scss";
import { PLOT_MODE } from "../../../../types";

interface Props {
  onClose: () => void;
  initialData: {
    name: string;
    password: string;
    mode: PLOT_MODE;
    plotId: string;
  };
  submit: (model: { name: string; password: string; mode: PLOT_MODE }) => void;
  visible: boolean;
}

interface FormProps {
  name: string;
  password: string;
}

const EditPlotDrawer = (props: Props) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      name: "",
      password: "",
    });
  }, [reset]);

  const [mode, setMode] = useState<PLOT_MODE | null>(null);

  useEffect(() => {
    if (props.initialData) {
      setValue("name", props.initialData?.name);
      setValue("password", props.initialData?.password);
      setMode(props.initialData?.mode);
      // setValue("mode", props.initialData?.mode);
    }
  }, [props.initialData, setValue, reset]);

  const submit = async (data: FormProps) => {
    await props.submit({
      name: data.name,
      password: data.password,
      mode: mode || PLOT_MODE.NO_ACCESS,
    });
  };

  // const invitesColumns: ColumnsType<InviteDataType> = [
  //   {
  //     title: t("members.drawer.tab.invites.email", {
  //       defaultValue: "Email",
  //     }),
  //     dataIndex: "email",
  //     key: "email",
  //     render: (text, record) => (
  //       <span>
  //         <b>{text}</b>
  //       </span>
  //     ),
  //   },
  //   {
  //     title: t("members.drawer.tab.invites.role", {
  //       defaultValue: "Role",
  //     }),
  //     key: "role",
  //     dataIndex: "role",
  //     render: (_, { role }) => (
  //       <Tag color={"gold"}>
  //         <b> {role.toUpperCase()}</b>
  //       </Tag>
  //     ),
  //   },
  //   {
  //     title: t("members.drawer.tab.invites.action", {
  //       defaultValue: "Action",
  //     }),
  //     key: "action",
  //     render: (_, record) => (
  //       <Popconfirm
  //         title={t("common.are.you.sure", {
  //           defaultValue: "Are you sure?",
  //         })}
  //         onConfirm={record.revoke}
  //         okText="Confirm"
  //         cancelText="Abort"
  //       >
  //         <Space size="middle">
  //           <span className={styles.action}>
  //             {t("members.drawer.tab.invites.revoke", {
  //               defaultValue: "Revoke",
  //             })}
  //           </span>
  //         </Space>
  //       </Popconfirm>
  //     ),
  //   },
  // ];

  return (
    <Drawer
      title={"Settings"}
      width={720}
      onClose={props.onClose}
      open={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={props.onClose}>
            {t("common.cancel", {
              defaultValue: "Cancel",
            })}
          </Button>
        </Space>
      }
    >
      <div>
        <div>Anyone with the link:</div>
        <div>
          <Segmented
            className={styles.segment}
            onChange={(el) => {
              setMode(el as PLOT_MODE);
              // setRole(el as ROLE);
            }}
            value={mode!}
            options={[
              {
                label: (
                  <div className={styles.segmentItem}>
                    <span>Can View</span>
                    <p>Can view if you share the public link</p>
                  </div>
                ),
                value: PLOT_MODE.VIEW_ALL,
              },
              {
                label: (
                  <div className={styles.segmentItem}>
                    <span>No access</span>
                    <p>Forbidden to view the presentation</p>
                  </div>
                ),
                value: PLOT_MODE.NO_ACCESS,
              },
            ]}
          />
        </div>
      </div>
      <br />
      <div>Share your presentation by copying this link:</div>

      <CopyToClipboard
        text={`https://app.cake.work/p/${props.initialData.plotId}`}
        onCopy={() => {
          NOTIFICATION_SERVICE.successNotification("Copied!");
        }}
      >
        <div className={styles.inviteLink}>
          <svg
            className={styles.icon}
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="m12 1.875c-2.83046 0-5.125 2.29454-5.125 5.125v3h-.875c-2.20914 0-4 1.7909-4 4v6c0 2.2091 1.79086 4 4 4h12c2.2091 0 4-1.7909 4-4v-6c0-2.2091-1.7909-4-4-4h-.875v-3c0-2.83046-2.2945-5.125-5.125-5.125zm2.875 8.125v-3c0-1.58782-1.2872-2.875-2.875-2.875s-2.875 1.28718-2.875 2.875v3zm-3.875 5c0-.5523.4477-1 1-1s1 .4477 1 1v4c0 .5523-.4477 1-1 1s-1-.4477-1-1z"
            ></path>
          </svg>
          <span
            className={styles.link}
          >{`https://app.cake.work/p/${props.initialData.plotId}`}</span>
          <span className={styles.button}>Copy</span>
        </div>
      </CopyToClipboard>
      <br />
      <br />

      {/* <Table
        columns={invitesColumns}
        dataSource={[]}
        pagination={false}
      /> */}

      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <FormInput
              type="text"
              rules={{
                required: true,
                minLength: 1,
              }}
              placeholder={"type here..."}
              error={errors.name}
              control={control}
              label={"Slide name"}
              name={"name"}
            />

            {/* {mode === PLOT_MODE.NO_ACCESS ? (
              <FormInput
                type="password"
                rules={{
                  required: false,
                  minLength: 1,
                }}
                error={errors.password}
                control={control}
                label={"Password"}
                name={"password"}
              />
            ) : null} */}

            {/* <div>
              <span>
                {t("aa", {
                  defaultValue:
                    "Protect your presentation by setting up password.",
                })}
              </span>
            </div>
            <br /> */}
            <FormButton
              size={"middle"}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            >
              {t("common.save", {
                defaultValue: "Save",
              })}
            </FormButton>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditPlotDrawer;
