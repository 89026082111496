import React from "react";
import ReactDOM from "react-dom";
import {
  useParticipant,
  ControlButton,
  AudioSelectButton,
  VideoSelectButton,
} from "@livekit/react-components";
import styles from "./rtcControls.module.scss";
import { faDesktop } from "@fortawesome/free-solid-svg-icons/faDesktop";
import { faStop } from "@fortawesome/free-solid-svg-icons/faStop";
import ReactionControl from "./ReactionControl";
// import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
import { Tooltip } from "antd";
// import Countdown from "react-countdown";
import { observer } from "mobx-react";
// import { useStore } from "../../../hooks/use-store";

function StageControls({
  room,
  enableScreenShare,
  enableVideo,
  enableAudio,
}: any) {
  // const {
  //   // dataStore: { elementsStore },
  // } = useStore();

  // useEffect(() => {
  //   const a = async () => {
  //     try {
  //       navigator.permissions
  //         .query(
  //           // { name: 'camera' }
  //           { name: "microphone" }
  //           // { name: 'geolocation' }
  //           // { name: 'notifications' }
  //           // { name: 'midi', sysex: false }
  //           // { name: 'midi', sysex: true }
  //           // { name: 'push', userVisibleOnly: true }
  //           // { name: 'push' } // without userVisibleOnly isn't supported in chrome M45, yet
  //         )
  //         .then(function (permissionStatus) {
  //           console.log(permissionStatus.state); // granted, denied, prompt
  //           if (permissionStatus.state === "denied") {
  //             NOTIFICATION_SERVICE.errorNotification(
  //               "You don't grant access to microphone, please to to settings and fix it"
  //             );
  //           }
  //           permissionStatus.onchange = function () {
  //             if (this.state === "denied") {
  //               NOTIFICATION_SERVICE.errorNotification(
  //                 "You don't grant access to microphone, please to to settings and fix it"
  //               );
  //             }
  //             console.log("Permission changed to " + this.state);
  //           };
  //         });
  //       navigator.permissions
  //         .query(
  //           { name: "camera" }
  //           // { name: 'microphone' }
  //           // { name: 'geolocation' }
  //           // { name: 'notifications' }
  //           // { name: 'midi', sysex: false }
  //           // { name: 'midi', sysex: true }
  //           // { name: 'push', userVisibleOnly: true }
  //           // { name: 'push' } // without userVisibleOnly isn't supported in chrome M45, yet
  //         )
  //         .then(function (permissionStatus) {
  //           console.log(permissionStatus.state); // granted, denied, prompt
  //           if (permissionStatus.state === "denied") {
  //             NOTIFICATION_SERVICE.errorNotification(
  //               "You don't grant access to video, please to to settings and fix it"
  //             );
  //           }
  //           permissionStatus.onchange = function () {
  //             if (this.state === "denied") {
  //               NOTIFICATION_SERVICE.errorNotification(
  //                 "You don't grant access to video, please to to settings and fix it"
  //               );
  //             }
  //             console.log("Permission changed to " + this.state);
  //           };
  //         });
  //     } catch (e) {
  //       try {
  //         const devices = await navigator.mediaDevices.enumerateDevices();
  //         console.log(devices);
  //         // if (devices.find(itm => itm.kind === 'audioinput')?.label === '') {
  //         //   NOTIFICATION_SERVICE.errorNotification("You don't grant access to microphone, please to to settings and fix it")
  //         // }
  //         // if (devices.find(itm => itm.kind === 'videoinput')?.label === '') {
  //         //   NOTIFICATION_SERVICE.errorNotification("You don't grant access to video, please to to settings and fix it")
  //         // }
  //       } catch (e) {}
  //     }
  //   };
  //   a();
  // }, []);

  const { cameraPublication: camPub, microphonePublication: micPub } =
    useParticipant(room.localParticipant);

  if (enableScreenShare === undefined) {
    enableScreenShare = true;
  }
  if (enableVideo === undefined) {
    enableVideo = true;
  }
  if (enableAudio === undefined) {
    enableAudio = true;
  }

  let muteButton;
  if (enableAudio) {
    const enabled = room.localParticipant.isMicrophoneEnabled;

    const a = micPub?.track;
    console.log(a, enabled);
    muteButton = (
      <Tooltip
        open={!micPub?.kind && !enabled ? true : false}
        zIndex={100}
        placement="topRight"
        overlayClassName={styles.tooltip}
        title={
          "We can't find microphone devices or permissions doesn't granted."
        }
      >
        <AudioSelectButton
          className={`${styles.button} ${
            !micPub?.kind ? styles.redButton : ""
          }`}
          muteText={"Mic"}
          unmuteText={"Mic"}
          isMuted={!enabled}
          onClick={() => {
            if (window.localStorage) {
              const val = !enabled;
              if (val) {
                window.localStorage.setItem("enableMic", "true");
              } else {
                window.localStorage.setItem("enableMic", "false");
              }
            }
            return room.localParticipant.setMicrophoneEnabled(!enabled);
          }}
          onSourceSelected={(device) => {
            window.localStorage.setItem("audioDeviceId", device.deviceId);
            room.switchActiveDevice("audioinput", device.deviceId);
          }}
        />
      </Tooltip>
    );
  }

  let videoButton;
  if (enableVideo) {
    const enabled = !(camPub?.isMuted ?? true);
    videoButton = (
      <Tooltip
        open={!camPub?.kind ? true : false}
        zIndex={100}
        title={"We can't find video devices or permissions doesn't granted."}
        placement="topLeft"
        overlayClassName={styles.tooltip}
      >
        <VideoSelectButton
          className={`${styles.button} ${
            !camPub?.kind ? styles.redButton : ""
          }`}
          disableText={"Cam"}
          enableText={"Cam"}
          isEnabled={enabled}
          onClick={() => {
            if (window.localStorage) {
              const val = !enabled;
              if (val) {
                window.localStorage.setItem("enableCam", "true");
              } else {
                window.localStorage.setItem("enableCam", "false");
              }
            }
            return room.localParticipant.setCameraEnabled(!enabled);
          }}
          onSourceSelected={(device) => {
            window.localStorage.setItem("videoDeviceId", device.deviceId);
            room.switchActiveDevice("videoinput", device.deviceId);
          }}
        />
      </Tooltip>
    );
  }

  let screenButton;
  if (enableScreenShare) {
    const enabled = room.localParticipant.isScreenShareEnabled;
    screenButton = (
      <ControlButton
        className={`${styles.button}`}
        label={"Screen"}
        // @ts-ignore
        icon={enabled ? faStop : faDesktop}
        onClick={() => {
          if (enabled) {
            room.localParticipant.setScreenShareEnabled(false);
          } else {
            room.localParticipant.setScreenShareEnabled(true);
          }
        }}
      />
    );
  }

  return ReactDOM.createPortal(
    <div className={styles.roomControls}>
      {/* {elementsStore.roomMeetingTimeout ? (
        <Countdown
          date={
            new Date(
              new Date(elementsStore.roomMeetingTimeout).getTime() + 2400000 / 2
            )
          }
          renderer={({ days, hours, minutes, seconds, completed }: any) => {
            if (completed) {
              // Render a complete state
              window.location.href = "/end/";
              return <div>done</div>;
            } else {
              // Render a countdown
              return (
                <div className={styles.countdownText}>
                  <span className={styles.counter}>
                    {minutes}m : {seconds}s
                  </span>
                  <span className={styles.startingIn}>
                    Free meeting minutes
                  </span>
                </div>
              );
            }
          }}
        />
      ) : null} */}

      {muteButton}
      {videoButton}
      {screenButton}
      <ReactionControl />
    </div>,
    // @ts-ignore
    document.getElementById("rtc-controls")
  );
}

export default observer(StageControls);
