import { Input as AntInput } from "antd";
import styles from "./input.module.scss";

const Index = (props: any) => {
  return (
    <AntInput {...props} className={styles.container}>
      {props.children}
    </AntInput>
  );
};

export default Index;
