import { Fragment, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import styles from "./preza.module.scss";
import GridLayout from "react-grid-layout";
import Cell from "./Cell";
import { Avatar, Button, Tooltip } from "antd";
import { useWindowSize } from "../../../helpers/helper";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import PasteImage from "./image/PasteImage";
import debounce from "lodash.debounce";
import throttle from "lodash/throttle";
import isEqual from "lodash/isEqual";
import ParticipantAvatar from "./participantAvatar/ParticipantAvatar";
import { SLIDES_COLUMNS } from "../../../helpers/constants";
import AddElement from "./controls/addElement/AddElement";
import Theme from "./controls/theme/Theme";
import Grid from "./controls/grid/Grid";
import Background from "./controls/slidesControlBackground/SlidesControlBackground";
// import { Pos } from "../../../types";
// import SlidesMultiplayerCursors from "./slideCursor/SlidesMultiplayerCursors";

interface IPreza {
  layout?: GridLayout.Layout[];
  onChangeLayout: (layout: GridLayout.Layout[]) => void;
}

const PrezaPage = ({ layout, onChangeLayout }: IPreza) => {
  const {
    dataStore: {
      plotStore,
      spaceStore,
      // elementsStore
    },
  } = useStore();

  const participants = Array.from(plotStore.participantMap.values());

  const { width } = useWindowSize();
  const pageParticipants = participants.filter(
    (itm) => itm.activePageId === plotStore.activePageId
  );

  const [selectedCell, setSelectedCell] = useState<string>("");

  const keys = layout && layout.length > 0 ? layout.map((itm) => itm.i) : [];

  // 152px + 80px
  let spaceSidebarWidth = 0;
  if (spaceStore.ready) {
    spaceSidebarWidth = spaceStore.spaceSidebarCollapsed ? 18 : 420;
  }

  const w = (width || 800) - spaceSidebarWidth;

  let contentWidth = 400;
  if (w <= 840) {
    contentWidth = 400;
  } else if (w > 840 && w <= 1096) {
    contentWidth = 680;
  } else if (w > 1096 && w <= 1400) {
    contentWidth = 840;
  } else if (w > 1400 && w <= 2400) {
    contentWidth = 980;
  } else if (w > 2400) {
    contentWidth = 1200;
  }

  const h = +(contentWidth * 0.025).toFixed(2);

  const debouncedEnterElement = debounce((elementId: string | null) => {
    plotStore.enterElement(elementId);
  }, 300);

  const debouncedChangeLayout = throttle((l: GridLayout.Layout[]) => {
    onChangeLayout(l);
  }, 200);

  // const debouncedChangeCursorPosition = throttle((pos: Pos) => {
  //   elementsStore.changeCursorPosition(pos, spaceStore.identity);
  // }, 80);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container} style={{ width: `${contentWidth}px` }}>
        <div
          className={styles.containerAfter}
          style={{ opacity: plotStore.backgroundOpacity }}
        ></div>
        {plotStore.displayGrid ? (
          <div className={styles.grid}>
            {Array(SLIDES_COLUMNS)
              .fill(0)
              .map((itm, i) => (
                <div className={i % 2 ? styles.line1 : styles.line2}></div>
              ))}
          </div>
        ) : null}

        <GridLayout
          onLayoutChange={(l) => {
            const layoutModel = l.map((itm) => ({
              w: itm.w,
              h: itm.h,
              x: itm.x,
              y: itm.y,
              i: itm.i,
            }));

            if (!isEqual(layoutModel, layout)) {
              debouncedChangeLayout(layoutModel);
            }
          }}
          isDraggable={!selectedCell && !plotStore.readonly}
          isResizable={!selectedCell && !plotStore.readonly}
          className={styles.layout}
          layout={layout?.map((itm) => ({
            ...itm,
            resizeHandles: ["sw", "nw", "se", "ne"],
          }))}
          cols={SLIDES_COLUMNS}
          rowHeight={h}
          width={contentWidth}
          useCSSTransforms={true}
          allowOverlap={true}
          compactType={null}
        >
          {keys.map((itm) => {
            const selected = pageParticipants.filter(
              (p) => p.activeElementId === itm
            );
            const isActive = selectedCell === itm;

            return (
              <div
                key={itm}
                style={
                  isActive
                    ? { zIndex: 1, transition: "background-color 100ms linear" }
                    : { transition: "background-color 100ms linear" }
                }
                className={`${styles.cell} ${
                  !plotStore.readonly ? styles.editableCell : ""
                } ${isActive && styles.cellActive}`}
                onMouseEnter={() => {
                  debouncedEnterElement(itm);
                }}
                onMouseLeave={() => {
                  debouncedEnterElement(null);
                }}
                onClick={() => {
                  if (!isActive) {
                    setSelectedCell("");
                  }
                }}
                onDoubleClick={() => {
                  if (!plotStore.readonly) {
                    if (isActive) {
                      setSelectedCell("");
                    } else {
                      setSelectedCell(itm);
                    }
                  }
                }}
              >
                {!isActive && !plotStore.readonly ? (
                  <div className={styles.editControls}>
                    <div>
                      <Tooltip title="Edit Element">
                        <Button
                          type="text"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedCell(itm);
                          }}
                        >
                          <svg className="remix">
                            <use xlinkHref={`${remixiconUrl}#ri-edit-line`} />
                          </svg>
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                ) : null}
                {isActive && !plotStore.readonly ? (
                  <div className={styles.cellControls}>
                    <div>
                      <Tooltip title="Delete">
                        <Button
                          type="text"
                          onClick={() => {
                            plotStore.deletePrezaElement({ id: selectedCell });
                            setSelectedCell("");
                          }}
                        >
                          <svg className="remix">
                            <use
                              xlinkHref={`${remixiconUrl}#ri-delete-bin-line`}
                            />
                          </svg>
                        </Button>
                      </Tooltip>

                      <Tooltip title="Bring to front">
                        <Button
                          type="text"
                          onClick={(e) => {
                            plotStore.changeLayer(itm, false);
                            setSelectedCell("");
                          }}
                        >
                          <svg className="remix">
                            <use
                              xlinkHref={`${remixiconUrl}#ri-bring-to-front`}
                            />
                          </svg>
                        </Button>
                      </Tooltip>

                      <Tooltip title="Send to back">
                        <Button
                          type="text"
                          onClick={(e) => {
                            plotStore.changeLayer(itm, true);
                            setSelectedCell("");
                          }}
                        >
                          <svg className="remix">
                            <use
                              xlinkHref={`${remixiconUrl}#ri-send-to-back`}
                            />
                          </svg>
                        </Button>
                      </Tooltip>

                      <Tooltip title="Exit edit mode">
                        <Button
                          type="text"
                          onClick={(e) => {
                            setSelectedCell("");
                          }}
                        >
                          <svg className="remix">
                            <use xlinkHref={`${remixiconUrl}#ri-close-line`} />
                          </svg>
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                ) : null}

                <Cell selectedCell={selectedCell} itm={itm} />

                <div className={styles.cellParticpantContainer}>
                  <Avatar.Group maxCount={8}>
                    {selected.map((rp) => (
                      <Fragment key={rp.identityId}>
                        <ParticipantAvatar
                          size={28}
                          username={rp.username}
                          identityId={rp.identityId}
                          avatar={rp.avatar}
                        />
                      </Fragment>
                    ))}
                  </Avatar.Group>
                </div>
              </div>
            );
          })}
        </GridLayout>
        {/* <div
          style={{ position: 'absolute', zIndex: 11, width: `${contentWidth}px`, minHeight: '100%', background: 'transparent' }}
          onMouseMove={(e) => {
            debouncedChangeCursorPosition({
              x: e.nativeEvent.offsetX,
              y: e.nativeEvent.offsetY,
            });
          }}
        >
        </div> */}
        {/* {elementsStore.showParticipantCursors ? <SlidesMultiplayerCursors /> : null} */}
      </div>
      <div className={styles.control} id="text-controls"></div>

      {plotStore.readonly ? null : (
        <div className={styles.sidebar}>
          <div className={styles.controls} id="slides-controls"></div>
          {selectedCell ? null : (
            <>
              <AddElement />
              <Theme />
              <Grid />
              <Background />
            </>
          )}
        </div>
      )}

      <PasteImage />
    </div>
  );
};

export default observer(PrezaPage);
