import React, { useCallback, useEffect, useState } from "react";
import { Col, Modal, Row, Image, Input } from "antd";
import styles from "./pexelsImageModal.module.scss";
import { useStore } from "../../hooks/use-store";
import { observer } from "mobx-react";
import debounce from "lodash.debounce";

interface Props {
  visible: boolean;
  handleAdd: (image: string) => void;
  handleCancel: () => void;
}

const PexelsImageModal = ({ visible, handleAdd, handleCancel }: Props) => {
  const {
    dataStore: { appStore },
  } = useStore();

  const [backgrounds, setBackgrounds] = useState([]);
  const [text, setText] = useState("");

  const getCall = useCallback(
    async (t: string) => {
      const res = await appStore.listPexelsBackgrounds(t);
      if (res) {
        setBackgrounds(res.photos);
        console.log(res);
      }
    },
    [appStore]
  );

  useEffect(() => {
    getCall(text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCall]);

  const debouncedUpdateSearchResult = useCallback(
    (t) =>
      debounce((t) => {
        getCall(t);
        console.log("this");
      }, 1000),
    [getCall]
  );

  return (
    <Modal
      width={840}
      title="Pexels"
      open={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div className={styles.maxWidth}>
        <Input
          placeholder={"background"}
          size="middle"
          type={"text"}
          onChange={(val) => {
            setText(val.target.value);
            debouncedUpdateSearchResult(val.target.value);
          }}
          value={text}
        />
        <Row gutter={16}>
          {backgrounds.map(
            (itm: { src: { original: string; medium: string } }) => (
              <React.Fragment key={itm.src.original}>
                <Col
                  span={6}
                  className={styles.gridItem}
                  onClick={async () => {
                    handleAdd(itm.src.original);
                  }}
                >
                  <Image
                    preview={false}
                    height={"180px"}
                    width={"100%"}
                    className={styles.previewImage}
                    src={itm.src.medium}
                    alt={"a"}
                  />
                </Col>
              </React.Fragment>
            )
          )}
          {backgrounds.length === 0 ? <div>loading...</div> : null}
        </Row>
      </div>
    </Modal>
  );
};

export default observer(PexelsImageModal);
