import styles from "./index.module.scss";
import { Button as AntButton } from "antd";
import React from "react";

const FormButton = (props: any) => {
  return (
    <AntButton
      {...props}
      size={props.size || "large"}
      className={props.inverted ? styles.whiteContainer : styles.blackContainer}
    >
      {props.children}
    </AntButton>
  );
};

export default FormButton;
